<template>
	<div class="error-404">
		<v-container class="d-flex flex-column justify-center">
			<div class="display-3 mt-5">404. Страница не найдена.</div>
			<div class="grey--text lighten-5 mt-8">
				Страница, которую вы пытаетесь получить, никогда не существовала в этой
				реальности или мигрировала в параллельную вселенную.
			</div>
			<div class="paragraph-text mt-5">
				Попробуйте вернуться на главную страницу и повторить свое действие. Или
				обратитесь в службу поддержки для получения поддержки.
			</div>
			<div class="d-flex mt-5">
				<v-btn
					class="subtitle-2 text-capitalize"
					color="primary"
					depressed
					@click="$router.push('/')"
				>
					Главная
				</v-btn>
			</div>
		</v-container>
	</div>
</template>

<script>
export default {
	name: "Error404",
};
</script>

<style lang="scss" scoped>
.error-404 {
	height: 100vh;
}
::v-deep .container {
	height: 100% !important;
}
</style>
