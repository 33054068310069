<template>
<div>
  <v-container>
    <p class="font-weight-medium ma-0">При выборе категории услуг на данной странице Вы будете перенаправлены в сервис inDrive</p>
  </v-container>
  <v-divider style="border-width: initial;"></v-divider>
  <v-container>
    <v-row class="mt-2">
      <template v-for="(item, i) in services">
        <v-col cols="6" md="4" lg="3" :key="i">
          <v-card 
            class="service-card text-center pa-2"
            hover
            flat
            :href="item.src"
            :disabled="isDisabled"
            @click="disableButton"
          >
            <div>
              <div>
                <img class="pa-2" :src="getImgUrl(item.logo)" v-bind:alt="item.logo">
              </div>
              <span >{{ item.title }}</span>
            </div>
          </v-card>
        </v-col>
      </template>
    </v-row>
  </v-container>
</div>
</template>

<script>
export default {
  data() {
    return {
      isDisabled: false,
      services: [
         {
          logo: 'assets/services/Group.svg',
          title: 'Ремонт и строительство',
          src: 'https://services.indrive.com/ru/astana/services/repair-and-construction?utm_source=in_app&utm_medium=in_app&utm_campaign=masters_customer_aula_repair_construction_240423'
        },
        {
          logo: 'assets/services/Group1.svg',
          title: 'Уборка, помощь по дому',
          src: 'https://services.indrive.com/ru/astana/services/housekeeping-and-cleaning?utm_source=in_app&utm_medium=in_app&utm_campaign=masters_customer_aula_cleaning_240423'
        },
        {
          logo: 'assets/services/Group2.svg',
          title: 'Ремонт и установка техники',
          src: 'https://services.indrive.com/ru/astana/services/appliance-installation-and-repair?utm_source=in_app&utm_medium=in_app&utm_campaign=masters_customer_aula_appliances_repair_240423'
        },
        {
          logo: 'assets/services/Group3.svg',
          title: 'Грузоперевозки',
          src: 'https://services.indrive.com/ru/astana/services/loaders-handymen?utm_source=in_app&utm_medium=in_app&utm_campaign=masters_customer_aula_loaders_240423'
        },
        {
          logo: 'assets/services/Group4.svg',
          title: 'Все для детей',
          src: 'https://services.indrive.com/ru/astana/services/childrens-services?utm_source=in_app&utm_medium=in_app&utm_campaign=masters_customer_aula_children_240423'
        },
        {
          logo: 'assets/services/Group5.svg',
          title: 'Красота и здоровье',
          src: 'https://services.indrive.com/ru/astana/services/health-and-beauty?utm_source=in_app&utm_medium=in_app&utm_campaign=masters_customer_aula_beauty_240423'
        },
        {
          logo: 'assets/services/Group6.svg',
          title: 'Услуги спецтехники',
          src: 'https://services.indrive.com/ru/astana/services/special-machinery?utm_source=in_app&utm_medium=in_app&utm_campaign=masters_customer_aula_machinery_240423'
        },
        {
          logo: 'assets/services/Group7.svg',
          title: 'Разное',
          src: 'https://services.indrive.com/ru/astana/services/other?utm_source=in_app&utm_medium=in_app&utm_campaign=masters_customer_aula_misc_240423'
        },
      ]
    }
  },
  methods: {
    disableButton() {
      this.isDisabled = true
    setTimeout(() => { this.isDisabled = false }, 3000);
    },
    getImgUrl(pic) {
      return require('@/'+pic)
    }
  },
}
</script>

<style scoped>
.service-card {
  background: #EBFAF0;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
</style>