var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"faq"},[_c('div',{staticClass:"d-flex justify-space-between mb-6"},[_c('v-autocomplete',{staticClass:"border-all mr-5",attrs:{"placeholder":"Адрес","prepend-inner-icon":"mdi-map-marker","hide-details":"","dense":"","solo":"","flat":"","clearable":"","filter":_vm.addressFilter,"items":_vm.houses,"item-text":"apartment_address","item-value":"_id"},model:{value:(_vm.filter.house_id),callback:function ($$v) {_vm.$set(_vm.filter, "house_id", $$v)},expression:"filter.house_id"}}),_c('v-text-field',{staticClass:"border-all mr-5",attrs:{"placeholder":"Поиск по ключевым словам","prepend-inner-icon":"mdi-magnify","hide-details":"","dense":"","solo":"","flat":"","clearable":""},model:{value:(_vm.filter.name),callback:function ($$v) {_vm.$set(_vm.filter, "name", $$v)},expression:"filter.name"}}),_c('v-autocomplete',{staticClass:"border-all mr-5",attrs:{"placeholder":"Категория","prepend-inner-icon":"mdi-filter","hide-details":"","dense":"","solo":"","flat":"","clearable":"","items":_vm.catalog.request_categories,"item-text":"name","item-value":"id"},model:{value:(_vm.filter.category_id),callback:function ($$v) {_vm.$set(_vm.filter, "category_id", $$v)},expression:"filter.category_id"}}),_c('v-btn',{staticClass:"text-capitalize subtitle-2",attrs:{"color":"primary","depressed":""},on:{"click":function($event){_vm.showCreateModal = true}}},[_vm._v(" Добавить вопрос ")])],1),_c('v-data-table',{staticClass:"overflow-x elevation-0",attrs:{"height":"100%","item-key":"name","hide-default-footer":"","headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"page":_vm.options.page,"options":_vm.options,"server-items-length":_vm.options.itemsLength},on:{"update:page":function($event){return _vm.$set(_vm.options, "page", $event)},"click:row":_vm.handleRowClick},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{staticClass:"justify-end pa-3 border-bottom",attrs:{"pagination":pagination,"options":options,"items-per-page-text":"","items-per-page-options":[],"disable-items-per-page":"","disable-pagination":_vm.loading,"show-first-last-page":true},on:{"update:options":updateOptions}})]}},{key:"item.",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('############'),expression:"'############'"}],staticClass:"border-all",attrs:{"solo":"","dense":"","flat":"","hide-details":"","error":String(item.iin).length > 0 && String(item.iin).length < 12},on:{"change":function($event){return _vm.setApartmentIIN(item)}},model:{value:(item.iin),callback:function ($$v) {_vm.$set(item, "iin", $$v)},expression:"item.iin"}})],1)]}},{key:"item.category_id",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm._f("getCatalogValue")(item.category_id,"request_categories"))+" ")])]}},{key:"item.delete_question",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('v-btn',{staticClass:"subtitle-2 text-capitalize",attrs:{"color":"red white--text","small":"","depressed":"","loading":_vm.isDeleting === item._id},on:{"click":function($event){$event.stopPropagation();return _vm.deleteQuestion(item)}}},[_vm._v(" Удалить ")])],1)]}}],null,true)}),(_vm.showCreateModal)?_c('FaqModal',{attrs:{"to-edit":_vm.toEdit},on:{"close-modal":_vm.closeModal,"update-info":_vm.loadData}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }