<template>
	<div class="tenants-item-info d-flex flex-column">
		<!-- Loader -->
		<!-- <v-progress-linear
			v-if="loading"
			color="primary"
			height="5"
			indeterminate
		></v-progress-linear> -->

		<!-- Header -->
		<div class="border pa-5 py-6 d-flex justify-space-between align-center">
			<v-skeleton-loader
				v-if="loading"
				class="skeleton-header ma-0"
				width="130px"
				type="text"
			></v-skeleton-loader>
			<!-- ФИО -->
			<div v-else class="d-flex justify-space-between align-center">
				<span class="text-subtitle-1 font-weight-regular">
					Жители дома: {{ item.house.apartment_address }} {{ item.apartment_number }}
				</span>
			</div>

			<div class="d-flex">
				<!-- Заблокировать -->
				<!-- <v-btn
					class="text-subtitle-2 text-capitalize ma-0"
					color="warning"
					text
					small
					:loading="isBlocking"
					:disabled="loading"
					@click="toggleBlock"
				>
					{{ !loading && item.blocked ? "Разблокировать" : "Заблокировать" }}
				</v-btn> -->
				<!-- Удалить -->
				<!-- <v-btn
					class="text-subtitle-2 text-capitalize ma-0"
					color="red"
					text
					small
					:disabled="loading"
					@click="showConfirmModal = true"
				>
					Удалить
				</v-btn> -->
				<!-- Редактировать -->
				<!-- <v-btn
					class="text-subtitle-2 text-capitalize ma-0"
					color="primary"
					text
					small
					:disabled="loading"
					@click="showEditModal = true"
				>
					Редактировать
				</v-btn> -->
			</div>
		</div>

		<!-- Body -->
		<div>
			<!-- Левая часть -->
			<div>
				<!-- Информация -->
				<!-- v-if="!loading" -->
				<div v-if="item" class="pa-5 mt-3">
					<v-row class="mb-7 pa-2	">
						<v-col cols="4">
							<p class="subtitle-1 font-weight-bold">
								Адрес
							</p>
							<span class="subtitle-2 font-weight-light">
								{{ item.house ? item.house.apartment_address : '---' }}
							</span>
						</v-col>
						<v-col cols="4">
							<!-- Тип помещения -->
							<p class="subtitle-1 font-weight-bold">Тип помещения</p>
							<span class="subtitle-2 font-weight-light">
								{{ item.type ? item.type.name : '---' }}
							</span>
						</v-col>
						<v-col cols="3">
							<!-- Номер помещения -->
							<p class="subtitle-1 font-weight-bold">{{ isFitness && item.type.sys_name === 'non-residential-premises' ? "№ Договора" : "№ Помещения" }}</p>
							<span class="subtitle-2 font-weight-light">
								{{ item.apartment_number }}
							</span>
						</v-col>
						<v-col cols="4" v-if="item.type.pretty_id === 2 && !isFitness">
							<!-- Название НП -->
							<p class="subtitle-1 font-weight-bold">Название НП</p>
							<span class="subtitle-2 font-weight-light">
								<span v-if="item.name">
									{{ item.name }}
								</span>
								<span v-else>---</span>
							</span>
						</v-col>
						<v-col cols="4" v-if="item.type.pretty_id === 2 && !isFitness">
							<!-- Вид деятельности -->
							<p class="subtitle-1 font-weight-bold">Вид деятельности</p>
							<span class="subtitle-2 font-weight-light">
								<span v-if="item.activity">
									{{ item.activity }}
								</span>
								<span v-else>---</span>
							</span>
						</v-col>
						<v-col cols="4" v-if="item.type.pretty_id === 1">
							<!-- Подъезд -->
							<p class="subtitle-1 font-weight-bold">Подъезд</p>
							<span class="subtitle-2 font-weight-light">
								<span v-if="item.porch_number">
									{{ item.porch_number }}
								</span>
								<span v-else>---</span>
							</span>
						</v-col>
						<v-col cols="4" v-if="item.type.pretty_id === 1">
							<!-- Этаж -->
							<p class="subtitle-1 font-weight-bold">Этаж</p>
							<span class="subtitle-2 font-weight-light">
								<span v-if="item.floor_number">
									{{ item.floor_number }}
								</span>
								<span v-else>---</span>
							</span>
						</v-col>
						<v-col cols="3">
							<!-- Статус -->
							<p class="subtitle-1 font-weight-bold">Дата создания помещения</p>
							<span class="subtitle-2 font-weight-light">
								{{ item.created_at | timestamp(true)  }}
							</span>
						</v-col>
					</v-row>
					<!-- ПОЛЬЗОВАТЕЛИ -->
					<template v-for="(owner, index) in item.owners">
						<div class="border-all pa-4 br-6">
							<v-list-item :key="index" class="pa-0 pb-2">
							<!-- Аватарка -->
								<v-list-item-avatar color="grey darken-3" size="75">
									<v-img
										v-if="owner.user"
										class="elevation-6"
										 :src="owner.user.avatar ? owner.user.avatarUrl : 'https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light'"
									></v-img>
								</v-list-item-avatar>

								<!-- Аватарка, ФИО и роль пользователя -->
								<v-list-item-content>
									<!-- Отображение имени -->
									<v-list-item-title v-if="!editFullname.includes(index)">
										<span>
											{{ getFullname(owner.user) }}
										</span>
										<!-- Кнопка включения изменения ФИО -->
										<v-btn v-if="owner.user" color="primary" icon small @click="editFullname = []; editFullname.push(index); fio = getFullname(owner.user)">
											<v-icon small>mdi-pencil</v-icon>
										</v-btn>
									</v-list-item-title>
									<!-- Отображение поля для изменения ФИО -->
									<v-list-item-title v-else class="d-flex align-center mr-2">
										<v-text-field
											placeholder="Введите ФИО жителя"
											v-model.trim="fio"
											class="border-all"
											solo
											dense
											hide-details
											flat
										></v-text-field>
										<!-- Кнопка Сохранить -->
										<v-btn
											color="primary"
											class="mr-2"
											small
											icon
											@click="saveFullname(owner.user)"
										>
											<v-icon>mdi-check</v-icon>
										</v-btn>
										<!-- Кнопка Отменить -->
										<v-btn color="red" small icon @click="saveFullname(false)">
											<v-icon>mdi-close</v-icon>
										</v-btn>
									</v-list-item-title>
									<!-- Телефон -->
									<v-list-item-subtitle class="mt-1">
										{{ item.phone }}
									</v-list-item-subtitle>
										
								</v-list-item-content>
								<v-list-item-action v-if="item.blocked">
									<v-list-item-subtitle class="red--text">
										Заблокирован
									</v-list-item-subtitle>
									<v-list-item-subtitle>
										<span class="black--text">Причина:</span>  
										{{ item.reason }}
									</v-list-item-subtitle>
									<v-list-item-subtitle>
										<span class="black--text">Срок до:</span>
										{{ item.blocked_time | timestamp(true) }}
									</v-list-item-subtitle>
									<!-- <pre>{{item}}</pre> -->
								</v-list-item-action>
								<v-list-item-action v-if="isFitness && item.type.sys_name === 'non-residential-premises'">
									<v-btn text class="text-none" @click="editTenant(owner)" style="color: #8C9197">
										<v-icon color="grey lighten-1">mdi-pencil</v-icon>
										Редактировать
									</v-btn>
								</v-list-item-action>
							</v-list-item>
							<v-row>
								<v-col cols="3">
									<p class="subtitle-1 font-weight-bold">
										Житель
										<img v-if="owner.approwed" class="ml-2" src="@/assets/done.svg" />
										<img v-else class="ml-2" src="@/assets/unconfirmed.svg" />
									</p>
									<v-skeleton-loader v-if="loading" width="180px" type="text">
									</v-skeleton-loader>
									<span v-else-if="owner.user" class="subtitle-2 font-weight-light">
										{{ getFullname(owner.user) }}
									</span>
									<span v-else class="subtitle-2 red--text">Пользователь удален</span>
								</v-col>
								<v-col cols="4">
									<!-- Тип помещения -->
									<p class="subtitle-1 font-weight-bold">Номер телефона</p>
									<v-skeleton-loader v-if="loading" width="180px" type="text">
									</v-skeleton-loader>
									<span v-else-if="owner.user" class="subtitle-2 font-weight-light">
										{{ owner.user.phone || '---' }}
									</span>
								</v-col>
								<v-col cols="3">
									<!-- Статус -->
									<p class="subtitle-1 font-weight-bold">Статус</p>
									<v-skeleton-loader v-if="loading" width="180px" type="text">
									</v-skeleton-loader>
									<span v-else class="subtitle-2 font-weight-light">
										{{ owner.affilation.name }}
									</span>
								</v-col>
								<v-col cols="2">
									<v-btn @click="deleteTenant(owner.id)" color="customRed" class="float-right white--text mb-4" small>Удалить</v-btn><br>
									<v-btn v-if="owner.user" @click="toggleBlock(owner)" color="customWarning" class="float-right white--text" small :loading="isBlocking === owner.id">
										{{!owner.user.blocked ? 'Заблокировать' : 'Разблокировать' }}
									</v-btn>
								</v-col>
								<v-col v-if="!isFitness" cols="3">
									<p class="subtitle-1 font-weight-bold">Способ регистрации</p>
									<v-skeleton-loader v-if="loading" width="180px" type="text">
									</v-skeleton-loader>
									<span v-else-if="owner.user" class="subtitle-2 font-weight-light">
										<span v-if="owner.user.registration === 'self'">Самостоятельно</span>
										<span v-else-if="owner.user.registration === 'by_dispatcher'">Диспетчером</span>
										<span v-else-if="owner.user.registration === 'by_manager'">Менеджером</span>
										<span v-else>---</span>
									</span>
								</v-col>
								<v-col v-else cols="3">
									<p class="subtitle-1 font-weight-bold">Кем зарегистрирован</p>
									<v-skeleton-loader v-if="loading" width="180px" type="text">
									</v-skeleton-loader>
									<span v-else-if="owner.user" class="subtitle-2 font-weight-light">
										<span>{{ owner.created_by | fullname }}</span>
									</span>
								</v-col>
								<v-col cols="4">
									<p class="subtitle-1 font-weight-bold">Дата регистрации</p>
									<v-skeleton-loader v-if="loading" width="180px" type="text">
									</v-skeleton-loader>
									<span v-else class="subtitle-2 font-weight-light">
										{{ owner.created_at | timestamp(true) }}
									</span>
								</v-col>
								<v-col cols="3">
									<p class="subtitle-1 font-weight-bold">Последний вход</p>
									<v-skeleton-loader v-if="loading" width="180px" type="text">
									</v-skeleton-loader>
									<span v-else-if="owner.user" class="subtitle-2 font-weight-light">
										{{ owner.user.last_entrance | timestamp(true) }}
									</span>
								</v-col>
								<v-col cols="2" v-if="!owner.approwed && owner.user">
									<v-btn @click="toggleTenantRegistration(owner)" :loading="isConfirming === owner.id" color="success" class="float-right" small>Подтвердить</v-btn>
								</v-col>
								<v-col cols="2" v-else-if="owner.user">
									<v-btn @click="toggleTenantDeclineRegist(owner)" :loading="isConfirming === owner.id" color="customWarning" class="float-right white--text" small>Отменить подтверждение</v-btn>
								</v-col>
								<v-col v-if="isFitness && item.type.sys_name === 'non-residential-premises'" cols="10"><v-divider></v-divider></v-col>
								<v-col v-if="isFitness && item.type.sys_name === 'non-residential-premises'" cols="3">
									<p class="subtitle-1 font-weight-bold">Кем подтвержден</p>
									<v-skeleton-loader v-if="loading" width="180px" type="text">
									</v-skeleton-loader>
									<span v-else class="subtitle-2 font-weight-light">
										{{ owner.approwed_by | fullname }}
									</span>
								</v-col>
								<v-col v-if="isFitness && item.type.sys_name === 'non-residential-premises'" cols="4">
									<p class="subtitle-1 font-weight-bold">Период посещения</p>
									<v-skeleton-loader v-if="loading" width="180px" type="text">
									</v-skeleton-loader>
									<span class="subtitle-2 font-weight-light">
										{{ owner.start_date | timestamp }} - {{ owner.end_date | timestamp }}
									</span>
								</v-col>
								<v-col v-if="isFitness && item.type.sys_name === 'non-residential-premises'" cols="3">
									<p class="subtitle-1 font-weight-bold">Количество месяцев</p>
									<v-skeleton-loader v-if="loading" width="180px" type="text">
									</v-skeleton-loader>
									<span class="subtitle-2 font-weight-light">
										{{ owner.duration ? owner.duration : '-' }}
									</span>
								</v-col>
								<v-col v-if="isFitness && item.type.sys_name === 'non-residential-premises'" cols="3">
									<p class="subtitle-1 font-weight-bold">Период заморозки</p>
									<v-skeleton-loader v-if="loading" width="180px" type="text">
									</v-skeleton-loader>
									<p v-for="period in owner.freeze_periods" class="subtitle-2 ma-0 font-weight-light">
										{{ period.start_date | timestamp }} - {{ period.end_date | timestamp }}
										<v-btn icon small @click="showConfirmDeleteFreeze = period._id">
											<v-icon small>mdi-close</v-icon>
										</v-btn>
									</p>
								</v-col>
								<v-spacer></v-spacer>
								<!-- <v-menu
									offset-y
								>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											class="black--text menu-btn-req"
											dark
											icon
											v-bind="attrs"
											v-on="on"
										>
											<v-icon>mdi-dots-horizontal</v-icon>
										</v-btn>
									</template>

									<v-list> -->
										<!-- <v-list-item >
											<v-btn v-if="!item.ksk_confirm" @click.stop="toggleTenantRegistration(item)" text color="success" style="text-transform: initial;">
												Подтвердить
											</v-btn >
											<v-btn v-else text class="success--text disable-events" style="text-transform: initial;">Подтвержден
												<img class="ml-2" src="@/assets/done.svg" />
											</v-btn>
										</v-list-item>
											-->
										<!-- <v-list-item >
											<v-btn @click="editApartment(item)" text style="text-transform: initial;">
												Редактировать
											</v-btn>
										</v-list-item>
										<v-divider></v-divider>
										<v-list-item>
											<v-btn @click="deleteTenant(owner.id)" color="red" text style="text-transform: initial;">
												Удалить
											</v-btn>
										</v-list-item>
									</v-list>
								</v-menu> -->
								
							</v-row>
							
						</div>
					</template>
					
				</div>
				<!-- Alert component -->
				<div class="pa-5">
					<v-alert v-if="alert" :type="alert.color" dense outlined>
						{{ alert.message }}
					</v-alert>
				</div>
			</div>

			<!-- Правая часть -->
			<!-- <div class="body-right pa-5">
				<v-list class="analytic-list">
					<v-list-item class="border-all analytic-list__item d-flex">
						<v-list-item-avatar rounded="0">
							<v-icon x-large>mdi-file-document-multiple-outline</v-icon>
						</v-list-item-avatar>
						<v-list-item-content>
							<v-list-item-title class="text-h4">
								{{ 0 }}
							</v-list-item-title>
							<v-list-item-subtitle>
								Общее количество заявок
							</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</div> -->
		</div>
		<!-- Редактирование информации пользователя -->
		<TenantsTableModal
			v-if="showEditModal"
			:data-to-edit="currentItem"
			:dataItem="item"
			@close-modal="closeModal"
		/>
		<TenantsAddModal
			v-if="showEditTenantModal"
			:data-to-edit="currentItem"
			:dataItem="item"
			@close-modal="closeModal"
		/>
		<!-- Блокировка пользователя -->
		<TenantsTableModalBlock
			v-if="showBlockModal"
			:tenantInfo="item"
			:user="userBlock"
			@close-modal="closeModalBlock"
			@load-data="loadData"
		/>
		<!-- Подтверждение удаления пользователя -->
		<ModalConfirm
			v-if="showConfirmModal"
			:title="'Удаление сотрудника'"
			@close-modal="deleteTenant"
		>
			<template #body>
				<div class="d-flex flex-column justify-center text-center align-center">
					<span>Вы действительно хотите удалить пользователя?</span>
					<strong>{{ item | fullname }}?</strong>
				</div>
			</template>
		</ModalConfirm>
		<!-- Подтверждение удаления периода заморозки -->
		<ModalConfirm
			v-if="showConfirmDeleteFreeze"
			:title="'Удаление периода заморозки'"
			@close-modal="deleteFreezePeriod"
		>
			<template #body>
				<div class="d-flex flex-column justify-center text-center align-center">
					<span>Вы действительно хотите удалить период заморозки?</span>
				</div>
			</template>
		</ModalConfirm>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import { getFullname } from "@/helpers/helpers";

import ModalConfirm from "@/components/app/ModalConfirm";
import TenantsTableModal from "@/components/TenantsTableModal";
import TenantsAddModal from "@/components/TenantsAddModal";
import TenantsTableModalBlock from "@/components/TenantsTableModalBlock";

export default {
	name: "TenantsItemInfo",
	components: {
		ModalConfirm,
		TenantsAddModal,
		TenantsTableModal,
		TenantsTableModalBlock,
	},
	data() {
		return {
			alert: null,
			editFullname: [],
			id: this.$route.params.id,
			item: null,
			userBlock: null,
			currentItem: null,
			fio: "",
			loading: true,
			isBlocking: '',
			showConfirmModal: false,
			showConfirmDeleteFreeze: false,
			showEditModal: false,
			showEditTenantModal: false,
			showBlockModal: false,
			isConfirming: '',
		};
	},
	computed: {
		...mapGetters({
			ksk_info: "ksk/GET_KSK_INFO",
			houses: "ksk/GET_HOUSES",
			userId: "auth/GET_USER_ID",
			isFitness: "ksk/GET_KSK_IS_FITNESS",
		}),
	},
	watch: {
		// ? Ручное отслеживание маршрута, чтобы обновлять данные о сотрудниках
		"$route"() {
			this.id = this.$route.params.id;
			this.loadData();
		},
		alert(val) {
			if (val) {
				setTimeout(() => (this.alert = null), 5000);
			}
		},
	},
	methods: {
		editApartment(item) {
			this.currentItem = item
			this.showEditModal = true;
		},
		editTenant(owner) {
			this.currentItem = owner
			this.showEditTenantModal = true;
		},

		async toggleTenantRegistration(item) {
			try {
				this.isConfirming = item.id;
				const res = await this.$api.ksk.confirm_tenant(
					item.id, item.user.id
				);
				item.approwed = !item.approwed;
			} catch (error) {
				console.error(error);
			} finally {
				this.isConfirming = false;
			}
		},
		async toggleTenantDeclineRegist(item) {
			try {
				this.isConfirming = item.id;
				const res = await this.$api.ksk.decline_tenant(
					item.id, item.user.id
				);
				item.approwed = !item.approwed;
			} catch (error) {
				console.error(error);
			} finally {
				this.isConfirming = false;
			}
		},

		async closeModal(isConfirmed) {
			if (isConfirmed) {
				this.loadData();
			}
			this.showEditModal = false;
			this.showEditTenantModal = false;
		},

		async toggleBlock(owner) {
			if (!owner.user.blocked) {
				this.userBlock = owner.user
				this.showBlockModal = true;
				return;
			}
			if(owner.user.blocked_files !== null) {
				for(let i = 0; i < owner.user.blocked_files.length; i++) {
					await this.$api.ksk.delete_files(owner.user.blocked_files[i].id);
				}
			}
			try {
				this.isBlocking = owner.id;

				const data = {
					blocked_time: null,
					blocked_forever: null,
					blocked_reason: null,
					blocked_files: [],
					_method: "patch"
				}
				const res = await this.$api.ksk.unblock_tenant({
					id: owner.user.id,
					data: data,
				});
				this.loadData()
				this.$root.snackbar.show({ color: "success", message: 'Успешно' });
			} catch (error) {
				this.$root.snackbar.show({ color: "error", message: error.message });
			} finally {
				this.isBlocking = '';
			}
		},

		closeModalBlock(isConfirmed) {
			if (isConfirmed) {
				this.item.blocked = true;
			}
			this.showBlockModal = false;
		},
		async loadImage(index) {
			if(this.item.owners[index].user) {
      	if(this.item.owners[index].user.avatar) {
					const response = await this.$api.ksk.load_file(this.item.owners[index].user.avatar.id);
					const blob = new Blob([response], { type: 'application/octet-stream' });
					this.item.owners[index].user.avatarUrl = URL.createObjectURL(blob);
					console.log('this.item.owners[index].user', this.item.owners[index].user)
				}
			}
		},
		async deleteTenant(owner_id) {
			this.alert = null;
			this.showConfirmModal = false;
			if (!confirm) return;
			try {
				const id = owner_id;
				const res = await this.$api.ksk.delete_tenant(id);
				this.alert = {
					color: "success",
					message: "Житель удален",
				};
				this.$root.snackbar.show({ color: "success", message: res.message });
				this.loadData();
				// setTimeout(() => this.$router.push(`/tenants/${this.item._id}`), 500);
			} catch (error) {
				const message = "Не удалось удалить жителя. Попробуйте еще раз";
				this.alert = {
					color: "error",
					message: error.message || message,
				};
			}
		},
		async deleteFreezePeriod(confirm) {
			this.alert = null;
			if (!confirm) return this.showConfirmDeleteFreeze = null;
			try {
				const res = await this.$api.ksk.delete_freeze_period(this.showConfirmDeleteFreeze);
				this.$root.snackbar.show({ color: "success", message: res.message });
				this.showConfirmDeleteFreeze = null;
				this.loadData();
			} catch (error) {
				const message = "Не удалось удалить период заморозки. Попробуйте еще раз";
				this.alert = {
					color: "error",
					message: error.message || message,
				};
			}
		},

		getFullname,

		async saveFullname(user) {
			this.editFullname = [];
			console.log('user', user)
			if (!user) {
				return;
			}

			try {
				// Заменить все двойные пробелы на одиночные
				this.fio = this.fio.replace(/\s\s+/g, " ");

				const fio = this.fio.split(" ");

				if (!fio) {
					throw { message: "Поле не должно быть пустым" };
				}

				const data = { surname: fio[0], name: "", patronymic: "", _method: "patch" };

				if (fio.length > 1) {
					data.name = fio[1];
				}
				if (fio.length > 2) {
					data.patronymic = fio[2];
				}
				const res = await this.$api.ksk.edit_tenant_info({ id: user.id, data });

				this.item = { ...this.item, ...data };
				console.log(this.item);

				this.alert = {
					color: "success",
					message: res.message || "ФИО изменено",
				};
				this.loadData()
			} catch (error) {
				console.log(error);
			}
		},

		async loadData() {
			this.loading = true;
			this.item = await this.$api.ksk.load_rooms_info_by_id(this.id);

			for (let index = 0; index < this.item.owners.length; index++) {
				await this.loadImage(index);
			}
			this.item = this.item
			this.loading = false;
		},
	},

	async mounted() {
		try {
			await this.loadData();
			this.fio = this.getFullname(this.item, true);
		} catch (error) {
			console.log(error);
		}
	},
};
</script>

<style lang="scss" scoped>
.body {
	display: grid;
	grid-template-columns: 4fr 1fr;
	height: 100% !important;
}
.body-info {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	row-gap: 20px;
}
.analytic-list {
	display: grid;
	grid-template-rows: 1fr 1fr;
	row-gap: 20px;
}
.analytic-list__item-success {
	border-left: 4px solid #2eb85c;
}
.analytic-list__item-fail {
	border-left: 4px solid red;
}

// Skeleton loader
::v-deep .v-skeleton-loader__heading,
::v-deep .skeleton-header .v-skeleton-loader__text {
	margin: 0 !important;
}
::v-deep .v-skeleton-loader__list-item {
	padding: 0 !important;
}

.menu-btn-req {
	top: -90px;
	position: relative;
}
.disable-events {
  pointer-events: none
}
.br-6 {
	border-radius: 6px;
}
</style>
