<template>
<div>
	<v-hover>
    <template v-slot:default="{ hover }">
      <v-card
				class="requisitions-item-right-comments-item"
				elevation="0"
				:class="{ 'grey lighten-4 ml-1 mt-2': !isUser }"
			>
				<v-card-title class="pa-3 pb-1">
					<v-list-item class="grow pa-0">
						<v-list-item-avatar color="grey darken-3">
							<v-img
								class="elevation-1"
								alt=""
								:src="imageSrc"
							></v-img>
						</v-list-item-avatar>
						<div style="position: absolute; right: 0;">
							<v-icon v-if="isUser && hover" class="mr-2" @click="$emit('editing-comment', comment)">mdi-pencil</v-icon>
							<v-icon v-if="isUser && hover" @click="$emit('delete-comment', comment.id)">mdi-delete</v-icon>
						</div>
						<v-list-item-content>
							<v-list-item-title
								class="text-caption font-weight-medium overflow-wrap"
							>
								{{ comment.user | fullname }}
							</v-list-item-title>
							<v-list-item-subtitle v-if="comment.user.employee_position" class="text-caption font-weight-light" >
								{{ comment.user.employee_position.name }}
							</v-list-item-subtitle>

							<!-- <v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-list-item-subtitle
										class="text-caption font-weight-light"
										v-bind="attrs"
										v-on="on"
									>
										{{ comment.user.employee_position.name }}
									</v-list-item-subtitle>
								</template>
								<span class="text-caption font-weight-light">
									{{ comment.user.employee_position.name }}
								</span>
							</v-tooltip> -->
						</v-list-item-content>
					</v-list-item>
				</v-card-title>

				<v-card-text class="text-caption font-weight-normal pb-0 black--text">
					<p class="mb-1">{{ comment.comment }}</p>
					<ImagesList v-if="comment.files.length" :images="comment.files" :is_comment="true" />
					<!-- Parent comment -->
					<v-card
						v-if="comment.parent_id"
						class="parent-comment pa-1"
						color="white black--text"
						elevation="0"
						@click="$emit('scroll-to-parent', comment.parent_id)"
					>
						<v-card-text class="pa-0 caption font-weight-normal">
							{{ comment.parent_comment }}
						</v-card-text>
					</v-card>
				</v-card-text>

				<v-card-actions>
					<v-btn @click="$emit('reply-comment', comment)" text color="primary" style="text-transform: unset;">Ответить</v-btn>
					<div class="flex-grow-1"></div>
					<div>{{ comment.created_at | timestamp(true) }}</div>
				</v-card-actions>
				<v-divider></v-divider>
      </v-card>
    </template>
  </v-hover>
	<span v-if="comment.childrens.length" class="ml-5" style="display: block;">
		<RequisitionsItemRightCommentsItem
			v-for="children in comment.childrens"
			:comment="children"
			:user_id="user_id"
			:key="children._id"
			:id="children._id"
			:ref="children._id"
			:children="true"
			@delete-comment="deleteComment($event)"
			@editing-comment="editingComment($event)"
			@reply-comment="replyComment($event)"
		/>
		</span>
</div>
</template>

<script>
import ImagesList from "@/components/app/ImagesList";
import RequisitionsItemRightCommentsItem from "@/components/RequisitionsItemRightCommentsItem";
export default {
	name: "RequisitionsItemRightCommentsItem",
	props: {
		comment: {
			type: Object,
			required: true,
		},
		user_id: {
			type: String,
			required: true,
		},
		children: {
			type: Boolean,
			default: false,
		}
	},
	data() {
		return {
			imageSrc: ''
		}
	},
	computed: {
		// ? Пользователь или нет
		isUser() {
			return this.comment.user.id == this.user_id;
		},
		// ? Позиция сотрудника либо адрес пользователя
		commentSubtitle() {
			console.log(this.comment);
			let subtitle;
			if (this.comment.is_manager) subtitle = "Менеджер";
			else if (this.comment.is_employee) subtitle = "Сотрудник";
			else
				subtitle =
					this.comment.apartment?.house?.apartment_address ||
					this.comment.user.social_status;
			return subtitle;
		},
	},
	methods: {
		async loadServerImage(avatar, showDefault = true) {
      if (!avatar && showDefault) {
        this.imageSrc = require("@/assets/user.svg");
        return; 
      }

      if (avatar?.id) {
        try {
          const response = await this.$api.ksk.load_file(avatar.id);
          const blob = new Blob([response], { type: 'application/octet-stream' });
          const blobUrl = URL.createObjectURL(blob);
          this.imageSrc = blobUrl;
        } catch (error) {
          console.error('Ошибка при загрузке изображения:', error);
          this.imageSrc = require("@/assets/user.svg");
        }
      } else {
        this.imageSrc = require("@/assets/user.svg");
      }
    },
		async deleteComment(id) {
			this.$emit('delete-comment', id)
		},
		editingComment(comment) {
			this.$emit('editing-comment', comment)
		},
		replyComment(childrenComment) {
			let comment = childrenComment
			comment.id = this.comment.id
			this.$emit('reply-comment', comment)
		},
	},
	created() {
		if(this.comment.files === null) {
			console.log('this.comment.files', this.comment.files)
			this.comment.files = []
		}
    this.loadServerImage(this.comment.user.avatar);
  },
	components: {
		ImagesList,
		RequisitionsItemRightCommentsItem
	}
};
</script>

<style lang="scss" scoped>
.overflow-wrap {
	overflow-wrap: break-word;
}
.v-card.v-sheet.theme--light.parent-comment {
	border-left: 3px solid green !important;
	cursor: pointer;
}
::v-deep .v-list-item,
.v-list-item__title {
	width: 100% !important;
}
::v-deep .v-list-item__subtitle {
	overflow: hidden !important;
	white-space: nowrap !important; /* Don't forget this one */
	text-overflow: ellipsis !important;
}
</style>
