<template>
	<v-dialog v-model="dialog" persistent max-width="600px">
		<v-card>
			<!-- Header -->
			<v-card-title class="d-flex justify-space-between border-bottom">
				<span class="subtitle-1">{{ modalTitle }}</span>
				<v-btn color="dark" @click="closeModal" icon>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>

			<!-- Progress bar -->
			<v-progress-linear
				v-if="loading"
				color="primary"
				height="5"
				indeterminate
			></v-progress-linear>

			<!-- Body -->
			<v-card-text v-else>
				<v-container>
					<v-form ref="form">
						<v-row>
							<v-col v-if="qrType != 'gate'" cols="12">
								<Label>Въезд</Label>
								<Autocomplete
									v-model="data.entry"
									class="mt-2"
									dense
									:items="cEntries"
									item-text="name"
									return-object
									required
								></Autocomplete>
							</v-col>
							<v-col v-else cols="12">
								<Label>Калитка</Label>
								<Autocomplete
									v-model="data.entry"
									class="mt-2"
									dense
									:items="gates"
									item-text="name"
									return-object
									required
								></Autocomplete>
							</v-col>
							<!-- Показать QR, если он сгенерирован -->
							<v-col cols="6" v-if="qr">
								<span class="subtitle-2 font-weight light">Въезд</span>
								<v-img :src="qr.in" width="200px" alt=""></v-img>
								<v-btn
									color="primary"
									small
									depressed
									target="_blank"
									:href="qr.in"
									:download="`QR Въезд ${qr.name}.png`"
								>
									Скачать
								</v-btn>
							</v-col>
							<!-- Показать QR, если он сгенерирован -->
							<v-col cols="6" v-if="qr">
								<span class="subtitle-2 font-weight light">Выезд</span>
								<v-img :src="qr.out" width="200px" alt=""></v-img>
								<v-btn
									color="primary"
									small
									depressed
									target="_blank"
									:href="qr.out"
									:download="`QR Выезд ${qr.name}.png`"
								>
									Скачать
								</v-btn>
							</v-col>
							<!-- Alert -->
							<v-col cols="12" class="py-0">
								<v-alert
									v-if="alert"
									class="mb-0"
									:type="alert.color"
									:color="alert.color"
									outlined
									dense
								>
									{{ alert.message }}
								</v-alert>
							</v-col>
						</v-row>
					</v-form>
				</v-container>
			</v-card-text>
			<v-card-actions class="pt-0 pb-5 px-8 d-flex justify-end">
				<v-btn
					class="subtitle-2 text-capitalize"
					color="primary"
					depressed
					:loading="isAdding"
					@click="generateQr"
				>
					Сгенерировать
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "QrTableBarrierModal",
	data() {
		return {
			alert: null,
			data: {
				entry: "",
			},
			qr: null,
			dialog: true,
			loading: true,
			isAdding: false,
		};
	},
	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
			entries: "qr/GET_ENTRIES",
			gates: "qr/GET_GATES",
			role: "auth/GET_USER_ROLE_VUEX",
		}),

		qrType() {
			return this.$route.meta.qrType;
		},

		cEntries() {
			const { id } = this.catalog.entrances_types.find(
				e => e.sys_name === this.qrType
			);
			return this.entries.filter(e => e.entry_type_id === id);
		},

		modalTitle() {
			const type =
				this.qrType === "parking"
					? "паркингов"
					: this.qrType === "gate"
					? "калиток"
					: "шлагбаумов";
			return `Генерация QR для ${type}`;
		},
	},
	watch: {
		alert(val) {
			if (!val) return;
			setTimeout(() => {
				this.alert = null;
			}, 5000);
		},
	},
	methods: {
		async generateQr() {
			this.alert = null;
			this.isAdding = true;
			try {
				const type = this.qrType != "gate" ? "entry" : "gate";
				const params = { [`${type}_id`]: this.data.entry._id };
				this.qr = {};
				this.qr = await this.$api[this.role][`generate_qr_${type}`](params);
				this.qr.name = this.data.entry.name;
				console.log('qr', this.qr)
				// Удалить слэши вначале
				this.qr.in = this.loadImageUrl(this.qr.in.substring(1));
				this.qr.out = this.loadImageUrl(this.qr.out.substring(1));

				this.alert = {
					color: "success",
					message: "QR сгенерированы",
				};

				this.$refs.form.resetValidation();
				// this.$emit("update-info");
			} catch (error) {
				console.log(error);
				this.alert = {
					color: "error",
					message: error.message || "Произошла ошибка. Повторите еще раз.",
				};
			} finally {
				this.isAdding = false;
			}
		},

		closeModal() {
			this.dialog = false;
			this.$emit("close-modal");
		},
	},

	async created() {
		try {
			this.loading = true;
			await this.$store.dispatch("qr/load_entries");
		} catch (error) {
			console.error(error);
		} finally {
			this.loading = false;
		}
	},
};
</script>

<style lang="scss" scoped></style>
