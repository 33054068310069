<template>
	<v-dialog v-model="dialog" persistent width="850">
		<v-card :loading="loading">
			<v-card-title
				class="d-flex justify-space-between border-bottom primary white--text"
			>
				<span class="body-1">Безопасность</span>
				<v-btn color="white" @click="closeModal" icon>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text class="border-bottom py-5">
				<v-alert v-if="alert" dense :type="alert.color" :timer="2000">
					{{ alert.message }}
				</v-alert>
        <v-row v-if="data.records.length">
          <v-col cols="4" v-for="(log, index) in data.records" :key="index">
            <v-card>
              <v-card-text>
                <strong v-if="log.type">Время:</strong> {{ log.created_at | timestampToDdmmyyyy({ showTime: true }) }}<br>
                <strong v-if="log.type">Type:</strong> {{ log.type }}<br>
                <strong v-if="log.source.device">Device:</strong> {{ log.source.device }}<br>
                <strong v-if="log.source.browser">Browser:</strong> {{ log.source.browser }}<br>
                <strong v-if="log.source.platform">Platform:</strong> {{ log.source.platform }}<br>
                <strong v-if="log.source.ip">IP:</strong> {{ log.source.ip }}<br>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	name: "ProfileLogsModal",
	data() {
		return {
			alert: null,
			dialog: true,
			data: {
        records: []
      },
			loading: false,
		};
	},
	computed: {
		...mapGetters({
			userId: "auth/GET_USER_ID",
		}),
	},
	methods: {
    async getLogs() {
      try {
        this.loading = true
        const params = {
          user_ids: [this.userId],
          types: ['auth', 'logout'],
          page: 1
        }
        const response = await this.$api.user.load_logs(params)
        this.data = response.data
        this.loading = false
      } catch (error) {
        console.log('err', error)
        this.alert = error
      }
    },
		closeModal(confirm = false) {
			this.dialog = false;
			this.$emit("close-modal", confirm);
		},
	},
  created() {
    this.getLogs()
  },
};
</script>

<style lang="scss" scoped></style>
