<template>
	<div class="whitelist-table">
		<v-data-table
			class="overflow-x elevation-1"
			height="100%"
			item-key="name"
			hide-default-footer
			:headers="headers"
			:items="items"
			:loading="loading"
			:page.sync="options.page"
			:options="options"
			:server-items-length="options.itemsLength"
			calculate-widths
			@click:row="handleRowClick"
		>
			<!--  ХЭДЕР ТАБЛИЦЫ -->
			<template v-slot:top="{ pagination, options, updateOptions }">
				<v-data-footer
					class="justify-end pa-3 border-bottom"
					:pagination="pagination"
					:options="options"
					@update:options="updateOptions"
					items-per-page-text=""
					:items-per-page-options="[]"
					disable-items-per-page
					:disable-pagination="loading"
					:show-first-last-page="true"
				>
					<!-- <template v-slot:page-text>
						{{ options.page || "-" }} из {{ options.pageCount || "-" }}
					</template> -->
					<template v-slot:prepend>
						<div class="d-flex flex-sm-grow-1 align-center justify-start">
							<!-- Кнопка создания -->
							<v-btn
								class="mr-5"
								color="primary"
								@click="showModalCreate = true"
								small
								depressed
							>
								<v-icon class="mr-1" small>mdi-plus</v-icon>
								<span class="body-2 font-weight-light">Создать</span>
							</v-btn>
							<!-- Дата создания -->
							<div class="d-flex align-center mr-3">
								<span class="subtitle-2 grey--text mr-3">от</span>
								<DatePickerPlain
									:date.sync="filter.created_at_first"
									@change="updateQuery"
								/>
								<span class="mx-3 subtitle-2 grey--text">до</span>
								<DatePickerPlain
									:date.sync="filter.created_at_second"
									@change="updateQuery"
								/>
								<v-btn class="ml-3" icon>
									<v-icon>mdi-magnify</v-icon>
								</v-btn>
							</div>
							<!-- Сброс фильтров -->
							<v-btn
								class="mr-5 text-lowercase"
								color="primary"
								small
								outlined
								@click="resetFilters"
							>
								<span class="body-2 font-weight-light">
									<span class="text-capitalize">Сбросить</span> фильтры
								</span>
							</v-btn>
							<!-- Вернуться к списку въездов -->
							<v-btn
								class="mr-5 text-capitalize"
								color="primary"
								text
								small
								depressed
								@click="$router.push('/qr/entries')"
							>
								<v-icon small>mdi-arrow-left</v-icon>
								<span class="body-2 font-weight-light">
									Въезды
								</span>
							</v-btn>
						</div>
					</template>
				</v-data-footer>
			</template>

			<!-- ФИЛЬТРЫ ТАБЛИЦЫ -->
			<template v-slot:[`body.prepend`]>
				<tr>
					<!-- Phone -->
					<td>
						<v-text-field
							v-model="filter.phone"
							:placeholder="phoneCode+'-'"
							v-mask="phoneCode == '+7' ? phoneCode+'-###-###-##-##' : phoneCode+'-###-###-####'"
							class="border-all"
							solo
							dense
							flat
							hide-details
							@change="updateQuery"
						>
						</v-text-field>
					</td>
					<!-- Гос.номер -->
					<td>
						<v-text-field
							v-model="filter.car_number"
							class="border-all"
							solo
							dense
							flat
							hide-details
							@change="updateQuery"
						>
						</v-text-field>
					</td>
					<!-- Адрес-->
					<td>
						<v-select
							v-model="filter.house_id"
							class="border-all"
							solo
							dense
							flat
							hide-details
							clearable
							:items="houses"
							item-text="apartment_address"
							item-value="_id"
							@change="updateQuery"
						>
						</v-select>
					</td>
					<!-- Номер въезда -->
					<td>
						<v-autocomplete
							v-model="filter.entry_id"
							class="border-all"
							solo
							dense
							hide-details
							flat
							clearable
							:items="entries"
							item-text="name"
							item-value="_id"
							@change="updateQuery"
						></v-autocomplete>
					</td>
					<!-- Паркоместо -->
					<td>
						<v-text-field
							v-model="filter.parking_place"
							class="border-all"
							solo
							dense
							flat
							hide-details
							@change="updateQuery"
						>
						</v-text-field>
					</td>
					<!-- Квартира -->
					<td>
						<v-text-field
							v-model="filter.apartment_address"
							class="border-all"
							solo
							dense
							flat
							hide-details
							@change="updateQuery"
						>
						</v-text-field>
					</td>
					<!-- Блокировка -->
					<td></td>
				</tr>
			</template>

			<template v-slot:[`item.house`]="{ item }">
				<td>
					{{ item.house.apartment_address }}
				</td>
			</template>
			<template v-slot:[`item.entry`]="{ item }">
				<td>
					{{ item.entry.name }}
				</td>
			</template>
			<template v-slot:[`item.block`]="{ item }">
				<td>
					<v-btn
						class="text-capitalize text-caption"
						:color="!item.blocked ? 'primary' : 'secondary'"
						small
						depressed
						:loading="
							toBlock.phone === item.phone &&
								item.entry.name === toBlock.entry.name
						"
						@click.stop="handleBlockClick(item)"
					>
						<span v-if="!item.blocked">Заблокировать</span>
						<span v-else>Разблокировать</span>
					</v-btn>
				</td>
			</template>
		</v-data-table>
		<!-- ОКНО ДОБАВЛЕНИЯ -->
		<QrTableWhitelistModal
			v-if="showModalCreate"
			@update-info="updateQuery"
			@close-modal="showModalCreate = false"
		/>
		<!-- ОКНО РЕДАКТИРОВАНИЯ -->
		<QrTableWhitelistModal
			v-if="showModalEdit"
			:toEdit="toEdit"
			@update-info="updateQuery"
			@close-modal="showModalEdit = false"
		/>
		<!-- ОКНО БЛОКИРОВКИ -->
		<ModalReject
			v-if="showModalReject"
			@close-modal="closeModalReject"
			title="Блокировка пользователя для въезда"
			btn-text="Заблокировать"
		>
			<template #text>
				<span
					>Подтверждение блокировки пользователя с гос.номером
					<b>{{ toBlock.car_number }}</b> для въезда
					<b>{{ toBlock.entry.name }}</b></span
				>
			</template>
		</ModalReject>
	</div>
</template>

<script>
import { timestampToDdmmyyyy } from "@/helpers/helpers";
import { mapGetters } from "vuex";

import DatePickerPlain from "@/components/app/DatePickerPlain";
import ModalReject from "@/components/app/ModalReject";
import QrTableWhitelistModal from "@/components/QrTableWhitelistModal";

export default {
	name: "QrTableWhitelist",
	components: {
		DatePickerPlain,
		ModalReject,
		QrTableWhitelistModal,
	},
	watch: {
		options: {
			handler() {
				const query = { ...this.$route.query, page: this.options.page };
				this.$router.replace({ query });
				this.loadData(query);
			},
			deep: true,
		},
	},
	data() {
		return {
			toEdit: null,
			toBlock: {},
			showModalReject: false,
			items: [],
			filter: {
				created_at_first: this.$route.query.created_at_first || "",
				created_at_second: this.$route.query.created_at_second || "",
				phone: this.$route.query.phone || "",
				car_number: this.$route.query.car_number || "",
				house_id: this.$route.query.house_id || "",
				entry_id: this.$route.query.entry_id || "",
				parking_place: this.$route.query.parking_place || "",
				apartment_address: this.$route.query.apartment_address || "",
			},
			filtersQuery: {
				"created_at_first": "created_at_first",
				"created_at_second": "created_at_second",
				"phone": "phone",
				"car_number": "car_number",
				"house_id": "house_id",
				"entry_id": "entry_id",
				"parking_place": "parking_place",
				"page": "page",
				"apartment_address": "apartment_address",
			},
			headers: [
				{ text: "Телефон", value: "phone", width: "200px" },
				{ text: "Гос. номер", value: "car_number", width: "200px" },
				{ text: "Адрес", value: "house", width: "300px" },
				{ text: "Номер въезда", value: "entry", width: "180px" },
				{ text: "Паркоместо", value: "parking_place", width: "180px" },
				{ text: "Квартира", value: "apartment_address", width: "180px" },
				{ text: "Блокировка", value: "block", width: "180px" },
			],
			options: {
				page: +this.$route.query.page || 1,
				itemsPerPage: 10,
				pageStart: 1,
				pageCount: 0,
				itemsLength: 0,
			},
			loading: true,
			showModalCreate: false,
			showModalEdit: false,
		};
	},
	computed: {
		...mapGetters({
			entries: "qr/GET_ENTRIES",
			catalog: "catalog/GET_CATALOG",
			houses: "ksk/GET_HOUSES",
			role: "auth/GET_USER_ROLE_VUEX",
			phoneCode: "auth/GET_USER_PHONE_CODE",
		}),
	},
	methods: {
		async blockRequest(reason = "") {
			try {
				console.log(this.toBlock.entry._id);
				const data = {
					reason,
					phone: this.toBlock.phone,
					entry_id: this.toBlock.entry._id,
				};

				const res = await this.$api.ksk.qr_block_user(data);

				if (res.error) throw { message: res.error };

				this.$root.snackbar.show({ message: res.success });

				this.loadData();
			} catch (error) {
				console.error(error);
				this.$root.snackbar.show({
					message: error.message,
					color: "red darken-1",
				});
			} finally {
				this.toBlock = {};
			}
		},

		closeModalReject(isConfirmed, reason = "") {
			this.showModalReject = false;
			if (!isConfirmed) {
				this.toBlock = {};
				return;
			}
			this.blockRequest(reason);
		},

		handleBlockClick(item) {
			this.toBlock = item;

			if (!item.blocked) {
				this.showModalReject = true;
				return;
			}

			this.blockRequest();
		},

		timestampToDdmmyyyy,

		// ? Методы таблицы
		handleRowClick(e) {
			this.toEdit = e;
			this.showModalEdit = true;
		},

		async resetFilters() {
			for (const key in this.filter) {
				// * Не очищать entry_id
				if (key === "entry_id") {
					continue;
				}
				this.filter[key] = "";
			}
			this.options.page = 1
			this.$router.replace({ query: { entry_id: this.filter.entry_id } });
			await this.loadData({ ...this.$route.query });
		},

		async loadData(params) {
			this.loading = true;
			return this.$api[this.role]
				.load_whitelist(params)
				.then(res => {
					// this.options.page = res.page;
					this.options.pageCount = res.pages;
					this.options.itemsLength = res.pages * 10;
					this.items = res.records;
				})
				.catch(error => {
					throw error;
				})
				.finally(() => {
					this.loading = false;
				});
		},

		async updateQuery(getQuery = false) {
			const query = Object.entries(this.filter).reduce((acc, [key, val]) => {
				if (!val) return acc;
				return { ...acc, [this.filtersQuery[key]]: val };
			}, {});
			this.options.page = 1
			if (getQuery === true) {
				return query;
			}

			this.$router.replace({ query: { ...query, page: this.options.page } });
			await this.loadData(query);
		},
		// ? Конец методов таблицы
	},

	async created() {
		this.$store.dispatch("ksk/load_houses");
		this.$store.dispatch("ksk/load_employee");
		this.$store.dispatch("qr/load_entries");
		this.filter.entry_id = this.$route.query.entry_id;
	},

	async mounted() {
		try {
			const query = { ...this.$route.query };
			await this.loadData(query);
			this.loading = false;
		} catch (error) {
			console.error(error);
		}
	},
};
</script>

<style lang="scss" scoped>
.whitelist-table {
	height: 100vh;
}
</style>
