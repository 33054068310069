<template>
	<v-row v-if="step === 1">
		<v-col cols="12" sm="6" md="4">
			<span class="subtitle-2 font-weight-light">
				Адрес
			</span>
			<v-autocomplete
				v-model="data.house_id"
				class="border-all mt-2"
				solo
				dense
				hide-details
				flat
				item-text="apartment_address"
				item-value="_id"
				:filter="addressFilter"
				:items="houses"
				required
				:rules="rules.required"
				@change="findHouse"
			></v-autocomplete>
		</v-col>
		<!-- Дополнительный телефон -->
		<v-col cols="12" sm="6" md="4">
			<span class="subtitle-2 font-weight-light">
				РКА
			</span>
			<v-text-field
				v-model="data.rca"
				v-mask="'################'"
				:disabled="foundRca"
				class="border-all mt-2"
				solo
				dense
				hide-details="auto"
				flat
				:rules="rules.rca"
			></v-text-field>
		</v-col>
		<!-- Дата завершения -->
		<v-col class="d-flex flex-column" cols="12" sm="6" md="4">
			<span class="subtitle-2 font-weight-light mb-2">
				Дата завершения
			</span>
			<DatePickerPlain :date.sync="data.time_vote" :allowPastDates="false" />
		</v-col>
		<!-- Ответственные -->
		<v-col>
			<span class="subtitle-2 font-weight-light mb-2">
				Ответственные лица
			</span>
			<v-combobox
				v-model="data.responsible_persons"
				class="text-caption border-all"
				chips
				small-chips
				multiple
				solo
				dense
				hide-details="auto"
				flat
				:allow-overflow="false"
				placeholder="Введите ФИО и нажмите Enter для добавления"
				:rules="rules.responsible_persons"
			>
				<template v-slot:selection="{ attrs, item, select, selected }">
					<v-chip
						v-bind="attrs"
						close
						small
						:input-value="selected"
						@click="select"
						@click:close="removeChip(item)"
					>
						{{ item }}
					</v-chip>
				</template>
			</v-combobox>
		</v-col>
		<!-- Alert -->
		<v-col cols="12" class="py-0">
			<v-alert
				v-if="alert"
				class="mb-0"
				:type="alert.color"
				:color="alert.color"
				outlined
				dense
			>
				{{ alert.message }}
			</v-alert>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: "VoteTableModalFirst",
	data() {
		return {
			alert: null,
			data: {
				house_id: null,
				time_vote: "",
				responsible_persons: [],
			},
			vote_id: null,
			foundRca: false,
			rules: {
				required: [v => !!v || ""],
				rca: [v => !!v || "", v => (v && v.length === 16) || "Введите 16 цифр"],
				responsible_persons: [v => !!v.length || ""],
			},
		};
	},
	methods: {
		// Получение дома по ID
		async findHouse() {
			this.alert = null;
			this.foundRca = false;
			try {
				const res = await this.$api.ksk.load_house_by_id(this.data.house_id);
				if (res?.rca) {
					this.data.rca = res.rca;
					this.foundRca = true;
				}
			} catch (error) {
				this.alert = {
					color: "error",
					message: "Произошла ошибка",
				};
				console.error(error);
			}
		},
	},
};
</script>
