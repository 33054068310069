<template>
	<v-dialog v-model="dialog" persistent max-width="600px">
		<v-card>
			<v-card-title
				class="d-flex justify-space-between border-bottom primary pt-2 pb-1"
			>
				<span class="subtitle-1 white--text">Блокировка пользователя</span>
				<v-btn color="dark" @click="closeModal(false)" icon>
					<v-icon color="white">mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text>
				<v-container>
					<v-form ref="form" lazy-validation>
						<!-- Данные о пользователе -->
						<v-row>
							<v-col cols="12">
								<span class="subtitle-2 black--text font-weight-light px-0">
									Заблокировать пользователя:
								</span>
							</v-col>
							<v-col cols="12" class="d-flex justify-space-between">
								<div class="d-flex flex-column">
									<span class="subtitle-1 font-weight-bold">
										{{ user | fullname }}
									</span>
									<span class="subtitle-1 font-weight-bold">
										{{ user.phone }}
									</span>
								</div>
								<!-- <span class="subtitle-2 font-weight-light">
									{{
										`${tenantInfo.house.apartment_address}, кв.${tenantInfo.apartment_number}`
									}}
								</span> -->
							</v-col>
						</v-row>

						<v-row>
							<v-col cols="12">
								<span class="subtitle-1 black--text px-0">
									Выберите срок
								</span>
							</v-col>
							<v-col cols="12" class="d-flex justify-space-between">
								<v-btn
									small
									depressed
									:outlined="period !== 1"
									:color="period !== 1 ? 'grey' : 'primary'"
									@click="period = 1"
									>3 дня</v-btn
								>
								<v-btn
									small
									depressed
									:outlined="period !== 2"
									:color="period !== 2 ? 'grey' : 'primary'"
									@click="period = 2"
									>7 дней</v-btn
								>
								<v-menu
									ref="menu"
									:close-on-content-click="false"
									transition="scale-transition"
									offset-y
									min-width="auto"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											depressed
											small
											v-bind="attrs"
											v-on="on"
											:outlined="period !== 3"
											:color="period !== 3 ? 'grey' : 'primary'"
											@click="period = 3"
										>
											{{ until ? `До ${until}` : "Выберите срок" }}
										</v-btn>
									</template>

									<v-date-picker
										v-model="until"
										:allowed-dates="disablePastDates"
									>
										<v-spacer></v-spacer>
										<v-btn text color="primary" @click="menu = false">
											Отмена
										</v-btn>
										<v-btn text color="primary" @click="$refs.menu.save(date)">
											ОК
										</v-btn>
									</v-date-picker>
								</v-menu>
								<v-btn
									small
									depressed
									:outlined="period !== 4"
									:color="period !== 4 ? 'grey' : 'primary'"
									@click="period = 4"
								>
									Навсегда
								</v-btn>
							</v-col>
							<v-col cols="12">
								<v-textarea
									v-model.trim="data.blocked_reason"
									placeholder="Укажите причину блокировки, описание будет передано пользователю"
									class="border-all"
									solo
									dense
									hide-details
									flat
								></v-textarea>
							</v-col>
							<v-col cols="12">
								<v-file-input
									v-model="selectedFiles"
									@change="handleFileChange"
									chips
									class="border-all"
									solo
									dense
									hide-details
									flat
									multiple
									color="primary"
									label="Прикрепить файлы"
								></v-file-input>
							</v-col>
						</v-row>
						<!-- Alert -->
						<v-row v-if="alert">
							<v-col cols="12">
								<v-alert class="mb-0" dense text :type="alert.color">
									{{ alert.message }}
								</v-alert>
							</v-col>
						</v-row>
					</v-form>
				</v-container>
			</v-card-text>

			<v-card-actions class="pt-0 pb-5 d-flex justify-center">
				<v-btn
					class="subtitle-2"
					color="warning"
					depressed
					:loading="isBlocking"
					@click="blockUser"
				>
					Заблокировать
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

import addressFilterMixin from "@/mixins/addressFilterMixin";

export default {
	name: "TenantsTableModalBlock",
	mixins: [addressFilterMixin],
	props: {
		tenantInfo: {
			type: Object,
			required: true,
		},
		user: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			alert: null,
			date: new Date().toISOString().substr(0, 10),
			period: 0,
			until: null,
			data: {
				block_forever: false,
				blocked_time: null,
				blocked_reason: "",
				_method: "patch"
			},
			selectedFiles: [],
			user_files: [],
			files: [],
			dialog: true,
			loading: true,
			isBlocking: false,
		};
	},
	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
		}),
	},
	methods: {
		closeModal(isConfirmed) {
			this.dialog = false;
			this.isBlocking = false
			this.$emit("close-modal", isConfirmed);
			this.$emit("load-data");
		},

		customValidation() {
			let message;

			if (!this.period) {
				message = "Укажите срок блокировки пользователя";
			} else if (!this.data.blocked_reason) {
				message = "Укажите причину блокировки пользователя";
			}

			if (!message) {
				return true;
			}

			this.alert = {
				message,
				color: "error",
			};

			return false;
		},
		handleFileChange(event) {
			if(!event.length) {
				this.user_files = []
				return this.selectedFiles = []
			}
      const newFiles = Array.from(event);
			const filteredFiles = newFiles.filter((file) => {
        return !this.user_files.some((image) => image.name === file.name && image.size === file.size);
      });
      this.user_files.push(...filteredFiles);
      this.selectedFiles = this.user_files.map((file) => new File([file], file.name));
		},
		disablePastDates(val) {
			return val >= new Date().toISOString().substr(0, 10);
		},

		getBlockedTime() {
			if (this.period === 4) {
				this.data.blocked_time = null;
				this.data.block_forever = true;
				return;
			}

			if (this.period === 3) {
				this.data.blocked_time = this.until + " 23:59:59";
				return;
			}

			let numberOfDaysToAdd = 3;

			if (this.period === 2) {
				numberOfDaysToAdd = 7;
			}

			const date = new Date();
			date.setDate(date.getDate() + numberOfDaysToAdd);
			this.data.blocked_time = date.toISOString().substr(0, 10) + " 23:59:59";
		},

		async blockUser() {
			try {
				if (!this.customValidation()) {
					return;
				}
				this.isBlocking = true
				if(this.selectedFiles.length) {
					this.files = []
					for(let i = 0; i < this.selectedFiles.length; i++) {
						await this.uploadFiles(this.selectedFiles[i])
					}
				}

				this.getBlockedTime();
				if(this.files.length) {
					this.data.blocked_files = this.files
				}
				const res = await this.$api.ksk.block_tenant({
					id: this.user.id,
					data: this.data,
				});

				this.alert = {
					color: "success",
					message: 'Успешно',
				};

				setTimeout(() => this.closeModal(true), 500);
			} catch (error) {
				this.alert = {
					color: "error",
					message: error.message || "Ошибка. Повторите еще раз",
				};
			}
		},
		async uploadFiles(file) {
			try {
				let data = {
					file: file
				}
				const formData = data.toFormData();
				const res = await this.$api.ksk.load_files(formData);
				this.files.push(res.id)
				console.log('this.files push', this.files)
			} catch (error) {
				console.log('err', error)
				this.alert = {
					color: "error",
					message: error.message.response.data.message || "Неподдерживаемый файл",
				};
				throw error;
			}

		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep .col {
	padding-bottom: 0 !important;
}
.required::after {
	content: "*";
	position: absolute;
	color: red;
	margin-left: 3px;
}
</style>
