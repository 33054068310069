<template>
	<v-hover v-slot="{ hover }">
		<!-- min-width="280" -->
		<v-card
			width="100%"
			:elevation="hover ? 5 : 0"
			:class="{ 'on-hover': hover }"
			class="mb-3 transition-fast-in-fast-out"
			active-class="card-active"
			outlined
			exact-active-class="card-active"
			link
			:to="`/employee/${item.id}`"
		>
			<v-card-title
				class="border-bottom subtitle-2 d-flex justify-space-between align-center pa-3"
			>
				<span>{{ item | fullname }}</span>
			</v-card-title>

			<v-card-text class="border-bottom pa-3 d-flex flex-column">
				<span class="caption font-weight-medium">
					{{
						item.employee_positions.length
							? item.employee_positions[0].employee.name
							: "Должность не указана"
					}}
				</span>
				<span class="caption font-weight-light">
					{{ item.phone }}
				</span>
			</v-card-text>
		</v-card>
	</v-hover>
</template>

<script>
export default {
	name: "EmployeeItemListCard",
	props: {
		item: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {};
	},
	created() {},
};
</script>

<style lang="scss" scoped>
.v-card {
	width: 100% !important;
	&.card-active {
		border: thin solid #2eb85c !important;
		background: rgba(46, 184, 92, 0.09);
	}
}
</style>
