<template>
	<v-dialog v-model="dialog" persistent max-width="900px" scrollable>
		<v-card class="relative">
			<!-- Header -->
			<v-card-title
				class="d-flex justify-space-between border-bottom primary pt-2 pb-1"
			>
				<span class="subtitle-1 white--text">
					<span>Добавление нового дома</span>
				</span>
				<v-btn color="dark" @click="closeModal(false)" icon>
					<v-icon color="white">mdi-close</v-icon>
				</v-btn>
			</v-card-title>

			<!-- Progress bar -->
			<v-progress-linear
				v-if="loading"
				color="primary"
				height="5"
				indeterminate
			></v-progress-linear>

			<!-- Body MAP -->
			<v-card-text class="body pa-0">
				<!-- Map container -->
				<div id="map-container"></div>
				<!-- Sidebar -->
				<v-card flat class="map-sidebar">
					<!-- Поиск по адресу -->
					<v-card-title class="primary">
						<v-autocomplete
							v-model="city"
							class="border-all mb-2"
							hide-details
							flat
							dense
							solo
							clearable
							:items="[
								{ name: 'Весь Казахстан', id: null },
								...catalog.cities,
							]"
							:item-text="item => item.text || item.name"
							return-object
							:filter="addressFilter"
							placeholder="Выберите город"
							@change="changeCity"
						>
						</v-autocomplete>
						<v-autocomplete
							:items="addressesList"
							:loading="isLoadingAddresses"
							:search-input.sync="searchAddress"
							class="border-all"
							hide-details
							flat
							dense
							solo
							clearable
							return-object
							no-filter
							placeholder="Введите адрес для поиска"
							@change="changeAddress"
							@click:clear="clearAddress"
						></v-autocomplete>
					</v-card-title>
					<!-- Sidebar body -->
					<transition name="slide">
						<v-card-text v-if="showDropdown" class="sidebar-list white pa-4">
							<v-text-field
								v-model="name"
								class="border-all mb-2"
								solo
								dense
								flat
								hide-details
								placeholder="Введите название ЖК"
							>
							</v-text-field>
							<v-text-field
								v-mask="'################'"
								v-model="rca"
								class="border-all"
								solo
								dense
								flat
								hide-details
								placeholder="РКА (регистрационный код адреса)"
							>
							</v-text-field>
							<!-- :disabled="rca.length !== 16" -->
							<v-btn
								class="mt-3"
								color="primary"
								depressed
								block
								@click="nextModal"
							>
								Добавить дом
							</v-btn>
						</v-card-text>
					</transition>
				</v-card>
			</v-card-text>
		</v-card>
		<HousesTableModalNext
			v-if="showModalNext"
			:house-data="houseData"
			@close-modal="closeNextModal"
		/>
	</v-dialog>
</template>

<script>
import DG from "2gis-maps";
import { mapGetters } from "vuex";

import HousesTableModalNext from "@/components/HousesTableModalNext";

export default {
	name: "HousesTableModal",
	components: { HousesTableModalNext },
	data() {
		return {
			alert: null,
			rca: "",
			name: "",
			houseData: {
				name: "",
				rca: "",
			},
			dialog: true,
			loading: false,
			rules: {
				required: [v => !!v || ""],
			},
			showModalNext: false,
			// Map variables
			map: [],
			markers: DG.featureGroup(),
			dbGisOpt: {
				locale: "ru_KZ",
				// key: "rurbbn3446",
				key: "rubjyc7298",
				viewpoint1: "60.24572539459018,85.26767725684829",
				viewpoint2: "85.4527333396855,19.801065367633214",
				fields: "items.point",
				type: "building",
			},
			timer: null,
			// Address input
			addressesList: [],
			city: null,
			isLoadingAddresses: false,
			searchAddress: "",
			showDropdown: false,
			addressPicked: false,
			addressPickedPrev: false,
			selectedAddress: null,
		};
	},
	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
		}),
		computedAdressesList() {
			return this.addressesList;
		},
	},
	watch: {
		alert(val) {
			if (!val) return;
			setTimeout(() => {
				this.alert = null;
			}, 3000);
		},

		searchAddress(val) {
			// console.log("w", val);

			clearTimeout(this.timer);

			this.timer = setTimeout(() => {
				if (!this.addressPicked && !this.addressPickedPrev) {
					this.searchFromInput(val);
				} else {
					this.addressPickedPrev = this.addressPicked;
					this.addressPicked = false;
				}
			}, 500);
		},
	},
	methods: {
		nextModal() {
			this.houseData.rca = this.rca;
			this.houseData.name = this.name;
			this.houseData.city_id = this.city.id;
			this.showModalNext = true;
		},

		clearMap() {
			this.markers.clearLayers();
		},

		clickMap(e) {
			this.clearMap();
			DG.marker(e.latlng).addTo(this.markers);
			this.markers.addTo(this.map);
			// console.log("map clicked");
			this.searchByItems({ value: e.latlng.lat + "," + e.latlng.lng }, true);
		},

		initMap() {
			const mapContainer = document.getElementById("map-container");
			const mapSelf = document.createElement("div");

			mapContainer.appendChild(mapSelf);
			mapSelf.setAttribute("id", "map");
			mapSelf.style.minHeight = "600px";
			mapSelf.style.width = "100%";

			this.map = DG.map("map", {
				center: [51.144866, 71.436564],
				zoom: 14,
			});

			this.map.on("click", this.clickMap);
		},

		queryApi(url, data_type) {
			return new Promise(function(resolve, rejected) {
				DG.ajax(url, {
					type: data_type.type || "get",
					data: data_type.data || [],
					success: data => resolve(data),
					error: error => rejected(error),
				});
			});
		},

		showGroupMarker() {
			this.markers.addTo(this.map);
			this.map.fitBounds(this.markers.getBounds());
		},

		changeAddress(e) {
			if (!e) return;
			console.log("changed", e, this.searchAddress);
			this.searchByItems({ value: e.value });
		},

		changeCity() {
			this.clearAddress();
			this.searchFromInput();
		},

		clearAddress() {
			// console.log("clearing");
			this.houseData = null;
			this.rca = this.name = "";
			this.showDropdown = false;
			this.searchAddress = "";
			this.addressesList = [];
		},

		searchByItems(selected) {
			// console.log("by items", selected);
			let self = this;

			this.queryApi("https://catalog.api.2gis.ru/3.0/items", {
				data: { ...self.dbGisOpt, q: selected.value },
			})
				.then(data => {
					const res = data.result?.items[0] || null;

					if (!res) return;

					// console.log("item res", res);

					const coords = [res.point.lat, res.point.lon];

					this.addressPicked = true;
					if (!this.city) {
						const chosen = res.full_name.split(",")[0];
						this.city = this.catalog.cities.find(e => e.name === chosen);
					}
					this.searchAddress = res.address_name;
					this.houseData = {};
					this.rca = "";
					console.log(res);
					this.name = this.houseData.name = res.building_name || "";
					console.log(this.houseData.name);
					this.showDropdown = true;
					this.houseData.address_name = res.address_name;
					this.houseData.name = res.name || res.full_name || res.address_name;
					this.houseData.id = res.id;
					this.houseData.latitude = coords[0];
					this.houseData.longitude = coords[1];
					this.houseData.name = "";
					this.clearMap();
					DG.marker(res.point).addTo(this.markers);
					this.houseData.coords = coords;
					this.showGroupMarker();
					// console.log("from item", this.houseData);
				})
				.catch(error => console.error("items error takoi", error));
		},

		async searchFromInput(address) {
			this.isLoadingAddresses = true;

			const url = "https://catalog.api.2gis.ru/3.0/items";
			const city = this.city?.name ? this.city.name : "";

			console.log(address, city);

			let finalAddress;

			if (!address && city) {
				finalAddress = city;
			} else {
				finalAddress = city + ", " + address;
			}

			const params = {
				data: {
					...this.dbGisOpt,
					q: finalAddress,
				},
			};

			try {
				const data = await this.queryApi(url, params);

				// console.log("search input res", data);

				this.clearMap();

				this.addressesList = data.result?.items
					.filter(item => {
						if (item.type === "building") {
							DG.marker(item.point)
								.addTo(this.markers)
								.bindPopup(item.full_name);
							return true;
						}
					})
					.map(item => {
						return {
							text: item.address_name || item.full_name || item.name,
							value: `${item.point.lat},${item.point.lon}`,
						};
					});

				console.log("from search", this.addressesList);
				this.showGroupMarker();
			} catch (error) {
				// console.log(error);
			} finally {
				this.isLoadingAddresses = false;
			}
		},

		closeNextModal(isConfirmed = false) {
			this.showModalNext = false;
			if (isConfirmed) {
				this.$emit("update-info");
				// this.closeModal()
			}
		},

		closeModal() {
			this.dialog = false;
			this.$emit("close-modal");
		},
	},

	async mounted() {
		this.initMap();
		this.loading = false;
		setTimeout(() => (this.showSidebarList = true), 1000);
	},
};
</script>

<style lang="scss" scoped>
.body {
	position: relative !important;
}
.map-sidebar {
	position: absolute;
	z-index: 1;
	top: 0;
	right: 0;
	margin: 20px 20px 0 0;
	min-width: 350px;
	max-width: 400px;
}
.sidebar-list {
	position: absolute !important;
	margin: 0;
	padding: 0;
	list-style-type: none;
	transform-origin: top;
	transition: transform 0.4s ease-in-out;
	overflow: hidden;
}
// Slide animation
.slide-enter,
.slide-leave-to {
	transform: scaleY(0);
}

.relative {
	position: relative;
}

.absolute {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
}
</style>
