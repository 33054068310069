<template>
	<v-card class="requisitions-item-right-history list">
		<!-- <div v-if="!loading" style="right: 0; bottom: 0; position: absolute; top: 50%; left: 50%;">
			<v-progress-circular indeterminate color="primary"></v-progress-circular>
		</div> -->
		<v-card-text>
			<!-- Редактирована -->
			<template v-for="(item, i) in history">
				<template v-if="item.display">
					<RequisitionsItemRightHistoryItem
						:key="i"
						v-if="item.type === 'edit'"
						type-sys-name="edit"
						title="Редактирование"
						:time="item.created_at"
						:user="item.user"
					/>
					<!-- Отклонено -->
					<RequisitionsItemRightHistoryItem
						:key="i"
						v-if="item.type === 'rating'"
						title="Оценка"
						type-sys-name="rating"
						:comment="item.created_at"
						:rating="item.user"
					/>
					<!-- Отклонено -->
					<RequisitionsItemRightHistoryItem
						:key="i"
						v-if="item.type === 'rejected'"
						title="Отклонено"
						type-sys-name="rejected"
						:time="item.created_at"
						:user="item.user"
					/>
					<!-- Отменено юзером -->
					<RequisitionsItemRightHistoryItem
						:key="i"
						v-if="item.type === 'canceled_by_user'"
						type-sys-name="canceled_by_user"
						title="Отменено"
						:time="item.created_at"
						:user="item.user"
					/>
					<!-- Не Исполнено -->
					<RequisitionsItemRightHistoryItem
						:key="i"
						v-if="item.type === 'not_done'"
						type-sys-name="not_done"
						title="Не исполнено"
						:comment="item.user_comment"
						:time="item.created_at"
						:user="item.user"
					/>
					<!-- Исполнено -->
					<RequisitionsItemRightHistoryItem
						:key="i"
						v-if="item.type === 'done'"
						type-sys-name="done"
						title="Исполнено"
						:comment="item.employee_description"
						:time="item.created_at"
						:user="item.user"
					/>
					<!-- В работе -->
					<RequisitionsItemRightHistoryItem
						:key="i"
						v-if="item.type === 'in_work'"
						title="В работе"
						type-sys-name="in_work"
						:time="item.created_at"
						:user="item.user"
					/>
					<!-- Просрочена -->
					<RequisitionsItemRightHistoryItem
						:key="i"
						v-if="item.type === 'overdue'"
						type-sys-name="overdue"
						title="Просрочено"
						:time="item.created_at"
					/>
					<!-- Принято -->
					<RequisitionsItemRightHistoryItem
						:key="i"
						v-if="item.type === 'accepted'"
						type-sys-name="accepted"
						title="Принято"
						:time="item.created_at"
						:user="item.user"
					/>
					<!-- Новая -->
					<RequisitionsItemRightHistoryItem
						:key="i"
						v-if="item.type === 'new'"
						type-sys-name="new"
						title="Новая"
						:time="item.created_at"
						:user="item.user"
					/>
				</template>
			</template>
		</v-card-text>
		<v-card-text v-if="loading && !history.length" class="d-flex justify-center align-center">
			История заявки пуста
		</v-card-text>
	</v-card>
</template>

<script>
import RequisitionsItemRightHistoryItem from "@/components/RequisitionsItemRightHistoryItem";

export default {
	name: "RequisitionsItemRightHistory",
	components: {
		RequisitionsItemRightHistoryItem,
	},
	props: {
		history: {
			type: Array,
			required: true,
		},
		loading: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			items: [
				{
					title: "Отклонено",
					time: "rejected_time",
					type: "rejected",
					comment: "request_type_rejected_message",
				},
				{
					title: "Исполнено",
					time: "done_time",
					type: "done",
					comment: "employee_description",
				},
				{ title: "В работе", time: "in_work_time", type: "in_work" },
				{ title: "", time: "" },
				{ title: "Принято", time: "accepted_time", type: "accepted" },
				{ title: "Новая", time: "created_at", type: "new" },
			],
		};
	},
	computed: {
		request_type_rejected_message() {
			return history.request_type_rejected_message || "";
		},
	},
	created() {
		console.log("history", this.history);
	},
};
</script>

<style lang="scss" scoped>
.list {
	flex: 1;
}
.requisitions-item-right-history {
	height: 100% !important;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	// padding-bottom: 72px;
}
.v-card__text {
	flex-grow: 1;
	padding: 8px;
	overflow-y: scroll;
}
</style>
