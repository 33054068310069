<template>
	<div class="requsitions-table">
		<div class="d-flex tabs mb-3">
			<Button
				v-for="link of links"
				:key="link.to"
				color="white primary--text"
				class="mr-5"
				:to="link.to"
				link
				exact
			>
				<v-icon class="mr-3" small>{{ link.icon }}</v-icon>
				<span>{{ link.text }}</span>
			</Button>
		</div>
		<router-view></router-view>
	</div>
</template>

<script>
export default {
	name: "Analytic",

	data() {
		return {
			links: [
				{
					to: "/analytic/",
					text: "Сводный отчет",
					icon: "mdi-file-document-multiple-outline",
				},
				{
					to: "/analytic/requisitions",
					text: "Заявки",
					icon: "mdi-file-document-multiple-outline",
				},
				{
					to: "/analytic/employee",
					text: "Исполнители",
					icon: "mdi-account-multiple-outline",
				},
				{ to: "/analytic/tenants", text: "Жители", icon: "mdi-account" },
			],
		};
	},
};
</script>

<style lang="scss" scoped>
.analytic-report {
	height: 90vh;
	&-grid {
		display: grid;
		grid-template-columns: 4fr minmax(0, 1fr);
		column-gap: 20px;
	}
}
</style>
