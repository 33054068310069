<template>
	<div class="vote-item-info d-flex flex-column">
		<!-- Loader -->
		<v-progress-linear
			v-if="loading"
			color="primary"
			height="5"
			indeterminate
		></v-progress-linear>

		<!-- Header -->
		<div
			v-if="!loading"
			class="border pa-5 py-6 d-flex justify-space-between align-center"
		>
			<v-skeleton-loader
				v-if="loading"
				class="skeleton-header ma-0"
				width="130px"
				type="text"
			></v-skeleton-loader>

			<div v-else class="d-flex justify-space-between align-center">
				<span class="text-subtitle-1 font-weight-regular">
					{{ `Голосование №${item.id}` }}
				</span>
				<span
					:class="[
						item.publish ? 'primary--text' : '',
						'ml-2 font-weight-light text-subtitle-2',
					]"
				>
					{{ item.publish ? "Опубликовано" : "Неопубликовано" }}
				</span>
			</div>

			<div class="d-flex">
				<v-btn
					v-if="!item.publish"
					class="text-subtitle-2 text-capitalize ma-0"
					color="warning"
					text
					small
					:loading="isSavingChanges"
					:disabled="!toSaveChanges"
					@click="saveChanges"
				>
					<span class="mr-1">Сохранить</span>
					<span class="text-lowercase">изменения</span>
				</v-btn>
				<!-- Удалить -->
				<v-btn
					class="text-subtitle-2 text-capitalize ma-0"
					color="red"
					text
					small
					@click="showConfirmModal = true"
				>
					Удалить
				</v-btn>
				<!-- Редактировать -->
				<v-btn
					class="text-subtitle-2 text-capitalize ma-0"
					color="primary"
					text
					small
					:loading="isPublishing"
					:disabled="item.publish"
					@click="publishVote"
				>
					Опубликовать
				</v-btn>
			</div>
		</div>

		<!-- Body -->
		<div v-if="!loading" class="body">
			<!-- Левая часть -->
			<div class="body-left border-right">
				<div class="body-info pa-5 border-bottom">
					<!-- Адрес -->
					<div class="d-flex flex-column">
						<span class="subtitle-1 font-weight-bold">Адрес</span>
						<v-skeleton-loader v-if="loading" width="180px" type="text">
						</v-skeleton-loader>
						<span v-else class="subtitle-2 font-weight-light">
							{{ item.house.apartment_address }}
						</span>
					</div>
					<!-- Дата создания -->
					<div class="d-flex flex-column">
						<span class="subtitle-1 font-weight-bold">Дата создания</span>
						<v-skeleton-loader v-if="loading" width="180px" type="text">
						</v-skeleton-loader>
						<span v-else class="subtitle-2 font-weight-light">
							{{ item.created_at | timestampToDdmmyyyy }}
						</span>
					</div>
					<!-- Дата завершения -->
					<div class="d-flex flex-column">
						<span class="subtitle-1 font-weight-bold">Дата завершения</span>
						<v-skeleton-loader v-if="loading" width="180px" type="text">
						</v-skeleton-loader>
						<span v-else class="subtitle-2 font-weight-light">
							{{ item.time_vote | timestampToDdmmyyyy }}
						</span>
					</div>
				</div>
				<div class="body-responsibles pa-5 border-bottom chips-placeholder">
					<!-- Ответственные -->
					<span class="subtitle-1 mb-2">
						Ответственные лица
					</span>
					<v-combobox
						v-model="item.responsible_persons"
						class="text-caption border-all"
						chips
						small-chips
						multiple
						solo	
						dense
						hide-details="auto"
						flat
						:disabled="item.publish"
						:allow-overflow="false"
						placeholder="Введите ФИО и нажмите Enter для добавления"
					>
						<template v-slot:selection="{ attrs, item, select, selected }">
							<v-chip
								v-bind="attrs"
								:close="!item.publish"
								small
								:input-value="selected"
								@click="select"
								@click:close="removeChip(item)"
							>
								{{ item }}
							</v-chip>
						</template>
					</v-combobox>
				</div>
				<div v-if="!loading" class="body-questions pa-5 border-bottom">
					<span class="subtitle-1">
						Все вопросы голосования
					</span>
					<VoteTableModalSecond
						ref="second"
						:prop-questions.sync="item.questions"
						:vote-id="this.$route.params.id"
						:disabled="item.publish"
						@load-data="loadData"
					/>
				</div>
				<div v-if="!loading" class="body-apartments pa-5">
					<span class="subtitle-1">
						Квартиры, участвующие в голосовании
					</span>
					<VoteTableModalThird
						:houseId="item.house._id"
						:disabled="item.publish"
					/>
				</div>
				<!-- Alert component -->
				<div v-if="alert" class="pa-5" ref="alert">
					<v-alert :type="alert.color" dense outlined>
						{{ alert.message }}
					</v-alert>
				</div>
			</div>

			<!-- Правая часть -->
			<VoteItemInfoRight :publish="item.publish" :questions="item.questions" :id="item._id" :voteId="item.id" />
		</div>
		<!-- Редактирование информации пользователя -->
		<TenantsTableModal
			v-if="showEditModal"
			:data-to-edit="item"
			@close-modal="closeModal"
		/>
		<!-- Подтверждение удаления пользователя -->
		<ModalConfirm
			v-if="showConfirmModal"
			:title="'Удаление голосования'"
			@close-modal="deleteVote"
		>
			<template #body>
				<div class="d-flex flex-column justify-center text-center align-center">
					<span>Вы действительно хотите удалить голосование</span>
					<strong>{{ `Голосование ${item.id}` }}?</strong>
				</div>
			</template>
		</ModalConfirm>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import DatePickerPlain from "@/components/app/DatePickerPlain";
import ModalConfirm from "@/components/app/ModalConfirm";
import TenantsTableModal from "@/components/TenantsTableModal";
import VoteTableModalSecond from "@/components/VoteTableModalSecond";
import VoteTableModalThird from "@/components/VoteTableModalThird";
import VoteItemInfoRight from "@/components/VoteItemInfoRight";

export default {
	name: "TenantsItemInfo",
	components: {
		DatePickerPlain,
		ModalConfirm,
		TenantsTableModal,
		VoteTableModalSecond,
		VoteTableModalThird,
		VoteItemInfoRight,
	},
	data() {
		return {
			alert: null,
			id: this.$route.params.id,
			item: null,
			fio: "",
			loading: true,
			toSaveChanges: false,
			isSavingChanges: false,
			showConfirmModal: false,
			showEditModal: false,
			showBlockModal: false,
			isPublishing: false,
		};
	},
	computed: {
		...mapGetters({
			ksk_info: "ksk/GET_KSK_INFO",
			houses: "ksk/GET_HOUSES",
		}),
	},
	watch: {
		// ? Ручное отслеживание маршрута, чтобы обновлять данные о сотрудниках
		"$route"() {
			this.id = this.$route.params.id;
			this.loadData();
		},
		alert(val) {
			if (val) {
				this.$nextTick(() =>
					this.$refs.alert.scrollIntoView({ behavior: "smooth" })
				);
				setTimeout(() => (this.alert = null), 5000);
			}
		},
		item: {
			handler(newVal, oldVal) {
				this.toSaveChanges = true;
			},
			deep: true,
		},
	},
	methods: {
		async closeModal(isConfirmed) {
			if (isConfirmed) {
				this.loadData();
			}
			this.showEditModal = false;
		},

		closeModalBlock() {
			this.showBlockModal = false;
		},

		async saveChanges() {
			try {
				this.isSavingChanges = true;
				// if (!this.$refs.second.checkValidation()) {
				// 	throw { message: "Заполните поля вопроса" };
				// }
				const res = await this.$refs.second.saveQuestions();
				console.log(res);
				this.alert = {
					color: "success",
					message: res.success || "Ошибка",
				};
			} catch (error) {
				this.alert = {
					color: "error",
					message: error.message || "Ошибка",
				};
				console.error(error);
			} finally {
				this.isSavingChanges = false;
			}
		},

		async publishVote() {
      const voteDateStart = new Date().toLocaleString('en-GB', {
        timeZone: 'Asia/Almaty',
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      }).replace(/\//g, '-').replace(',', '');
			try {
				this.isPublishing = true;
				await this.$api.ksk.publish_vote(this.item._id, {
					publish: true,
					vote_date_start: voteDateStart
				});
				this.alert = {
					color: "success",
					message: "Голосование опубликовано",
				};
				this.$emit("update-list");
				setTimeout(() => this.loadData(), 500);
			} catch (error) {
				console.error(error);
			} finally {
				this.isPublishing = false;
			}
		},

		removeChip(item) {
			const index = this.item.responsible_persons.indexOf(item);
			this.item.responsible_persons.splice(index, 1);
		},

		async deleteVote(confirm) {
			this.alert = null;
			this.showConfirmModal = false;
			if (!confirm) return;
			try {
				const res = await this.$api.ksk.delete_vote(this.$route.params.id);
				console.log(res);
				this.alert = {
					color: "error",
					message: "Голосование удалено",
				};
				this.$router.push("/vote");
			} catch (error) {
				const message = "Не удалось удалить голосование. Попробуйте еще раз";
				this.alert = {
					color: "error",
					message: error.message || message,
				};
				console.error(error);
			}
		},

		async loadData() {
			this.loading = true;
			console.log('this.id', this.id)
			this.item = await this.$api.ksk.load_vote_info(this.id);
			console.log("item", this.item);
			this.loading = false;
		},
	},

	async mounted() {
		try {
			await this.loadData();
		} catch (error) {
			console.log(error);
		}
	},
};
</script>

<style lang="scss" scoped>
.vote-item-info {
	height: 100vh;
	overflow: hidden;
}
.body {
	display: grid;
	grid-template-columns: 3fr 1fr;
	height: 100% !important;
	overflow: hidden;
}
.body-left {
	overflow: scroll;
}
.body-info {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	row-gap: 20px;
}

::v-deep .v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
	min-height: 30px !important;
	font-size: 14px !important;
}

// Skeleton loader
::v-deep .v-skeleton-loader__heading,
::v-deep .skeleton-header .v-skeleton-loader__text {
	margin: 0 !important;
}
::v-deep .v-skeleton-loader__list-item {
	padding: 0 !important;
}

</style>

<style>
.chips-placeholder .v-autocomplete:not(.v-input--is-focused).v-select--chips input {
	max-height: 25px !important;
}
</style>