<template>
	<div class="requisitions-item" ref="item">
		<RequisitionsItemList ref="list" />
		<RequisitionsItemInfo @update-list="updateList" @update-data="updateData" :itemInfo="item" />
		<RequisitionsItemRight ref="right" :historyInfo="item.history" :isPause="isPause" />
	</div>
</template>

<script>
import RequisitionsItemList from "@/components/RequisitionsItemList";
import RequisitionsItemInfo from "@/components/RequisitionsItemInfo";
import RequisitionsItemRight from "@/components/RequisitionsItemRight";

export default {
	name: "RequisitionsItem",
	components: {
		RequisitionsItemList,
		RequisitionsItemInfo,
		RequisitionsItemRight,
	},
	data() {
		return {
			item: {
				history: []
			},
			isPause: false
		};
	},
	methods: {
		scrollToElement(refName) {
			const element = this.$refs[refName];
			const top = element.offsetTop;
			window.scrollTo(0, top);
		},
		updateList() {
			this.$refs.list.loadData(undefined, true);
		},
		updateData(item) {
			this.isPause = item.is_pause
			this.$refs.right.loadHistory(item.history);
		},
		async loadData() {
			if(this.$route.query.is_pause == 'true') return
			try {
				this.item = await this.$api.ksk.load_request_detail(
					this.$route.params.id
				);
				this.isPause = this.item.is_pause
				// * Изменить null на допустимые типы данных
				if (this.item.employee_period_of_execution === null) {
					this.item.employee_period_of_execution = "";
				}
				if (this.item.is_price === null) {
					this.item.is_price = false;
				}
				if (this.item.employee_workers === null) {
					this.item.employee_workers = [];
				}
				if (this.item.images === false) {
					this.item.images = [];
				}
			} catch (error) {
				throw error;
			}
		},
	},
	mounted() {
		// * Проскроллить к информации о заявке при загрузке страницы
		// setTimeout(() => {
		// 	this.scrollToElement("item");
		// }, 500);
		this.loadData();
	},
};
</script>

<style lang="scss" scoped>
.requisitions-item {
	height: 100%;
	background: #ffffff;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
	display: grid;
	grid-template-columns: minmax(0, 1fr) minmax(0, 3fr) minmax(0, 1fr);
}
.requisitions-item-info,
.requisitions-item-list,
.requisitions-item-right {
	height: 100%;
}
</style>
