<template>
	<div class="employee-item-info d-flex flex-column">
		<!-- Loader -->
		<v-progress-linear
			v-if="loading"
			color="primary"
			height="5"
			indeterminate
		></v-progress-linear>

		<!-- Header -->
		<div
			class="border pa-5 py-6 d-flex justify-space-between align-center"
			v-if="!loading"
		>
			<div class="d-flex justify-space-between align-center">
				<span class="text-subtitle-1 mr-2 font-weight-regular">
					{{ item | fullname }}
				</span>
			</div>

			<div class="d-flex">
				<!-- Удалить -->
				<v-btn
					v-if="base_role !== 'administrator'"
					class="text-subtitle-2 text-capitalize ma-0"
					color="red"
					text
					small
					@click="showConfirmModal = true"
					:disabled="base_role === 'ksk_manager' && item.role.name_system !== 'ksk_employee' && item.role.name_system !== 'ksk_dispatcher'"
				>
					Удалить
				</v-btn>
				<!-- Редактировать -->
				<v-btn
					class="text-subtitle-2 text-capitalize ma-0"
					color="primary"
					text
					small
					@click="showEditModal = true"
					:disabled="base_role === 'ksk_manager' && item.role.name_system !== 'ksk_employee' && item.role.name_system !== 'ksk_dispatcher'"
				>
					Редактировать
				</v-btn>
			</div>
		</div>

		<!-- Body -->
		<div class="body" v-if="!loading">
			<!-- Левая часть -->
			<div class="body-left border-right">
				<!-- Аватар и имя -->
				<v-list-item class="border-bottom pa-5">
					<!-- Аватарка -->
					<v-list-item-avatar color="grey darken-3" size="75">
						
						<!-- https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light -->
						<v-img
							class="elevation-6"
							lazy-src="@/assets/user.svg"
							:src="item.avatar ? item.avatarUrl : 'https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light'"
						></v-img>
					</v-list-item-avatar>

					<v-list-item-content>
						<v-list-item-title>{{ item | fullname }}</v-list-item-title>
						<v-list-item-subtitle>
							{{ item.role.name == 'Кск' ? 'Председатель' : item.role.name }}
						</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>

				<!-- Информация -->
				<div class="body-info border-bottom pa-5 d-flex justify-space-between">
					<div class="d-flex flex-column">
						<!-- Телефон сотрудника -->
						<span class="subtitle-1 font-weight-bold">Телефон</span>
						<span class="subtitle-2 font-weight-light">
							{{ item.phone }}
						</span>
					</div>
					<!-- Должность сотрудника -->
					<div class="d-flex flex-column">
						<span class="subtitle-1 font-weight-bold">Должность</span>
						<span class="subtitle-2 font-weight-light">
							<span v-if="item.employee_positions.length">
								<template v-for="(position, i) in item.employee_positions">
									<p :key="i" class="ma-0">{{position.employee.name}}</p>
								</template>
							</span>
							<span v-else>Должность не указана</span>
						</span>
					</div>
					<!-- ИИН -->
					<div class="d-flex flex-column">
						<span class="subtitle-1 font-weight-bold">ИИН</span>
						<span class="subtitle-2 font-weight-light">
							{{ item.iin || "Не указан" }}
						</span>
					</div>
				</div>

				<!-- Аналитика -->
				<!-- <EmployeeItemInfoAnalytic /> -->

				<!-- Alert component -->
				<div class="pa-5">
					<v-alert v-if="alert" dense outlined :type="alert.color">
						{{ alert.message }}
					</v-alert>
				</div>
			</div>

			<!-- Правая часть -->
			<div class="body-right pa-5">
				<!-- Название управляющей компании -->
				<div class="d-flex flex-column mb-3">
					<span class="subtitle-2">Управляющая компания</span>
					<span class="subtitle-2 font-weight-light" v-if="item.ksk">{{ item.ksk[0].name }}</span>
					<span class="subtitle-2 font-weight-light" v-else>---</span>
				</div>
				<!-- Список прикрепленных домов сотрудника -->
				<div class="d-flex flex-column">
					<span class="subtitle-2">Обслуживает дома</span>
					<v-chip-group v-if="item.ksk_houses.length" column>
						<v-chip x-small v-for="house of item.ksk_houses" :key="house.id">
							{{ house.house.apartment_address }}
						</v-chip>
					</v-chip-group>
					<!-- Если у сотрудника нет прикрепленных домов -->
					<span v-else class="subtitle-2 font-weight-light">
						Дома не прикреплены
					</span>
				</div>
			</div>
		</div>
		<!-- Редактирование информации о сотруднике -->
		<EmployeeTableModal
			v-if="showEditModal"
			:data-to-edit="item"
			@close-modal="closeModal"
			@update-info="loadData"
		/>
		<!-- Подтверждение удаления сотрудника -->
		<ModalConfirm
			v-if="showConfirmModal"
			:title="'Удаление сотрудника'"
			@close-modal="deleteEmployee"
		>
			<template #body>
				<div class="d-flex flex-column justify-center text-center align-center">
					<span>Вы действительно хотите удалить сотрудника</span>
					<strong>{{ item | fullname }}?</strong>
				</div>
			</template>
		</ModalConfirm>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

import ModalConfirm from "@/components/app/ModalConfirm";
import EmployeeItemInfoAnalytic from "@/components/EmployeeItemInfoAnalytic";
import EmployeeTableModal from "@/components/EmployeeTableModal";

export default {
	name: "EmployeeItemInfo",
	components: {
		EmployeeItemInfoAnalytic,
		EmployeeTableModal,
		ModalConfirm,
	},

	data() {
		return {
			alert: null,
			chips: [],
			id: this.$route.params.id,
			item: null,
			loading: true,
			showConfirmModal: false,
			showEditModal: false,
		};
	},

	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
			ksk_info: "ksk/GET_KSK_INFO",
			houses: "ksk/GET_HOUSES",
			kskId: "auth/GET_KSK_ID",
			base_role: "auth/GET_USER_ROLE",
		}),
	},

	watch: {
		// ? Ручное отслеживание маршрута, чтобы обновлять данные о сотрудниках
		"$route"() {
			this.id = this.$route.params.id;
			this.loadData();
		},
	},

	methods: {
		getHouses(item) {
			const res = [];
			item.houses_ids.forEach(h => {
				const found = this.houses.find(e => e._id === h);
				if (!found) return;
				res.push(found.apartment_address);
			});
			return res;
		},

		async closeModal(isConfirmed) {
			if (isConfirmed === true) {
				await this.loadData();
			}
			this.showEditModal = false;
		},

		async deleteEmployee(confirm) {
			this.alert = null;
			if (!confirm) return;
			try {
				const data = {
					ksk_ids: [],
					_method: 'patch',
				}
				if(this.item.ksk.length <= 1) {
					data.ksk_ids = null,
					data.ksk_houses_ids = [],
					data.ksk_category_ids = [],
					data.employee_position_ids = [],
					data.role_id = this.catalog.roles.find(e => e.name_system === 'user').id
				} else {
					data.ksk_ids.push(
						...this.item.ksk.filter(item => item.id !== this.kskId).map(item => item.id)
					);
				}
				const res = await this.$api.ksk.save_user(data, this.id, this.kskId);
				this.alert = {
					color: "success",
					message: "Сотрудник удален",
				};
				setTimeout(() => this.$router.push("/employee"), 1000);
			} catch (error) {
				const message = "Не удалось удалить сотрудника. Попробуйте еще раз";
				this.alert = {
					color: "error",
					message: error.message || message,
				};
			} finally {
				this.showConfirmModal = false;
			}
		},

		async loadData() {
			this.loading = true;
			this.item = await this.$api.ksk.load_employee_by_id(this.id, this.kskId);
			if(this.item.avatar) {
				this.loadImage()
			}
			this.item = this.item
			this.loading = false;
		},
		async loadImage() {
			const response = await this.$api.ksk.load_file(this.item.avatar._id);
			const blob = new Blob([response], { type: 'application/octet-stream' });
			this.item.avatarUrl = URL.createObjectURL(blob);
		},
	},
	async mounted() {
		try {
			// this.$store.dispatch("ksk/load_ksk_info");
			this.loadData();
		} catch (error) {
			console.log(error);
		}
	},
};
</script>

<style lang="scss" scoped>
.body {
	display: grid;
	grid-template-columns: 4fr 1fr;
	height: 100% !important;
}
.analytic-list {
	display: grid;
	grid-template-columns: 2fr 1fr 1fr;
	column-gap: 20px;
}
@media screen and (max-width: 1200px) {
}
.analytic-list__item-success {
	border-left: 4px solid #2eb85c;
}
.analytic-list__item-fail {
	border-left: 4px solid red;
}
</style>
