<template>
	<v-dialog v-model="dialog" persistent max-width="600">
		<v-card>
			<v-card-title class="d-flex justify-space-between border-bottom mb-3">
				<div class="d-flex">
					<span class="font-weight-light subtitle-2 mr-2">
						Оценивание работы
					</span>
				</div>
				<v-btn color="dark" @click="closeModal(false)" icon>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text>
				<div class="d-flex justify-center">
					<v-rating
						v-model="data.user_rating"
						background-color="grey lighten-1"
						color="warning"
						hover
						length="5"
						size="45"
					></v-rating>
				</div>
				<span class="subtitle-2 font-weight-light mb-3">
					Комментарий жителя
				</span>
				<v-textarea
					v-model="data.user_comment"
					:placeholder="placeholder"
					ref="comment"
					class="border-all"
					height="100px"
					no-resize
					solo
					flat
					dense
					validate-on-blur
					:rules="commentRule"
				></v-textarea>
			</v-card-text>
			<v-card-actions class="d-flex justify-end pb-4">
				<v-btn
					class="text-capitalize subtitle-2"
					color="primary"
					depressed
					:disabled="disabled"
					:loading="loading"
					@click="closeModal(true)"
				>
					Сохранить
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: "RequisitionsItemModalRating",

	data() {
		return {
			data: {
				user_rating: 0,
				user_comment: "",
			},
			commentRule: [
				v =>
					this.data.user_rating > 3 ||
					!!this.data.user_comment ||
					"Комментарий обязателен для заполнения",
			],
			dialog: true,
			loading: false,
		};
	},

	computed: {
		disabled() {
			return (
				(this.data.user_rating <= 3 && !this.data.user_comment.length) ||
				!this.data.user_rating
			);
		},

		placeholder() {
			return !this.data.user_rating
				? ""
				: this.data.user_rating > 3
				? "Необязательное поле"
				: "Обязательное поле";
		},
	},

	watch: {
		"data.user_rating"(val) {
			if (val > 3) {
				this.$refs.comment.resetValidation();
			}
		},
	},

	methods: {
		/**
		 * Вернуть true, если дейтсвие подвтерждено
		 */
		closeModal(close) {
			this.dialog = false;
			setTimeout(() => this.$emit("close-modal", close, this.data), 200);
		},
	},
};
</script>

<style lang="scss" scoped></style>
