<template>
	<v-dialog v-model="dialog" persistent max-width="600">
		<v-card>
			<v-card-title class="d-flex justify-space-between border-bottom mb-3">
				<div class="d-flex">
					<span class="font-weight-light subtitle-2 mr-2">
						Закрыть индивидуальную заявку
					</span>
				</div>
				<v-btn color="dark" @click="closeModal(false)" icon>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text>
				<span v-if="!item.is_price" class="subtitle-2 font-weight-light mb-4">
					Заявка бесплатная
				</span>
				<div v-else class="d-flex justify-space-between align-center mb-4">
					<span class="subtitle-2 font-weight-light">
						Заявка платная, введите стоимость выполненных работ в ₸
					</span>
					<v-text-field
						v-model="data.price"
						type="number"
						min="0"
						placeholder="Стоимость"
						class="border-all max-width-130"
						solo
						flat
						dense
						hide-details
					></v-text-field>
				</div>
				<v-textarea
					v-model="data.comment"
					placeholder="Комментарий по выполненной работе"
					class="border-all mb-4"
					height="140px"
					no-resize
					solo
					flat
					dense
					hide-details
				></v-textarea>

				<span class="subtitle-2 font-weight-light mb-3">
					Фотоотчет по выполненной работе
				</span>
				<v-file-input
					v-model="data.images"
					label="Прикрепить"
					class="border-none d-flex"
					hide-details
					small-chips
					flat
					chips
					multiple
					solo
					dense
				></v-file-input>
			</v-card-text>
			<v-card-actions class="d-flex justify-end">
				<v-btn
					class="text-capitalize subtitle-2"
					color="primary"
					depressed
					:disabled="disabled"
					:loading="loading"
					@click="closeModal(true)"
				>
					Завершить
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	name: "RequisitionsItemFinishModal",
	props: {
		item: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			data: {
				comment: "",
				images: [],
				price: 0,
			},
			files: [],
			dialog: true,
			loading: false,
		};
	},
	computed: {
		...mapGetters({
			tokenDevice: "notifications/GET_DEVICE_TOKEN",
		}),
		disabled() {
			// Disabled пока не введены причина и стоимость
			return (
				!this.data.comment.length || (this.item.is_price && !this.data.price)
			)
		},
	},
	methods: {
		/**
		 * Вернуть true, если дейтсвие подвтерждено
		 */
		async closeModal(close = false) {
			this.dialog = false;

			if (close) {
				// const data = {
				// 	employee_description: this.data.comment,
				// 	employee_files: this.data.images,
				// 	price: this.data.price,
				// 	_method: 'patch'
				// };
				// const formData = data.toFormData();
				// this.loading = true;
				// await this.$api.ksk.save_request(
				// 	formData,
				// 	this.$route.params.id
				// );
				// this.loading = false;
				if(this.data.images.length) {
					this.files = []
					for(let i = 0; i < this.data.images.length; i++) {
						await this.uploadFiles(this.data.images[i])
					}
				}
				const data = {
					firebase_token: this.tokenDevice,
					employee_comment: this.data.comment,
					employee_files: this.files,
					source: 2,
					_method: 'patch'
				};
				if(this.data.price) {
					data.price = this.data.price
				}
				// const formData = data.toFormData();
				this.loading = true;
				if(this.item.is_resumed && this.item.request_type.sys_name === 'not_done') {
					await this.$api.ksk.done_request_event(
						data,
						this.$route.params.id
					);
				} else {
					await this.$api.ksk.close_request(
						data,
						this.$route.params.id
					);
				}
				
				this.loading = false;
			}

			setTimeout(() => this.$emit("close-modal", close), 200);
		},
		async uploadFiles(file) {
			try {
				let data = {
					file: file
				}
				const formData = data.toFormData();
				const res = await this.$api.ksk.load_files(formData);
				this.files.push(res.id)
			} catch (error) {
				console.log('err', error)
				this.alert = {
					color: "error",
					message: error.message.response.data.message || "Неподдерживаемый файл",
				};
				throw error;
			}

		},
	},
};
</script>

<style lang="scss" scoped>
.max-width-130 {
	max-width: 130px !important;
}
::v-deep .v-text-field {
	&:focus-within {
		border-color: #77dc99 !important;
		box-shadow: 0px 0px 4px #81e9a3;
	}
}
::v-deep .v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
	min-height: 28px !important;
	font-size: 14px !important;
}
::v-deep .v-text-field__details {
	display: none !important;
}
</style>
