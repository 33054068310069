<template>
	<v-dialog v-model="dialog" persistent max-width="350">
		<v-card>
			<v-card-title class="subtitle-1 font-weight-light border-bottom">
				Назначьте исполнителя и срок
			</v-card-title>
			<v-card-text class="dark--text border-bottom py-3">
				Необходимо назначить <b>исполнителя</b> и <b>срок исполнения</b>, чтобы
				принять заявку
			</v-card-text>
			<v-card-actions class="d-flex justify-center">
				<v-btn
					class="subtitle-2 text-capitalize"
					color="primary"
					depressed
					@click="closeModal"
				>
					Ок
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: "RequisitionsItemWarningModal",
	data() {
		return {
			dialog: true,
		};
	},
	methods: {
		closeModal() {
			this.dialog = false;
			this.$emit("close-modal");
		},
	},
};
</script>
