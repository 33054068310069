<template>
	<div class="register" ref="login">
		<v-main>
			<v-container fluid fill-height>
				<v-layout align-center justify-center>
					<v-flex xs12 sm8 md5>
						<v-card class="elevation-12">
							<!-- Хэдер -->
							<v-toolbar dark flat color="primary">
								<v-toolbar-title>
									Регистрация
								</v-toolbar-title>
							</v-toolbar>

							<!-- Поиск по БИН -->
							<!-- <v-card-text v-if="!showManualForm" class="px-6 pb-0">
								<v-alert
									v-if="alert"
									dense
									text
									:type="alert.type || 'warning'"
								>
									{{ alert.message }}
								</v-alert>

								<v-form class="mt-2" lazy-validation>
									<v-text-field
										v-model="bin"
										v-mask="'############'"
										label="БИН"
										placeholder="000000000000"
										counter
										persistent-placeholder
										name="bin"
										append-icon="mdi-magnify"
										type="text"
										:rules="rules.bin"
										:disabled="loading"
										:loading="loading"
										@click:append="findKskByBin"
										required
									></v-text-field>
								</v-form>
							</v-card-text> -->

							<!-- Ручной ввод -->
							<v-card-text class="px-6 pb-0">
								<v-alert
									v-if="alert"
									dense
									text
									:type="alert.type || 'warning'"
								>
									{{ alert.message }}
								</v-alert>

								<v-form ref="form" class="mt-2" lazy-validation>
									<v-row>
										<!-- БИН -->
										<v-col cols="12" sm="6">
											<span class="subtitle-2 grey--text font-weight-light mb-2"
												>БИН</span
											>
											<v-text-field
												v-model="data.bin"
												v-mask="'############'"
												class="border-all"
												hide-details
												flat
												solo
												dense
												type="text"
												:rules="rules.bin"
												required
											></v-text-field>
										</v-col>
										<!-- Наименование -->
										<v-col cols="12" sm="6">
											<span
												class="subtitle-2 grey--text mb-2  font-weight-light"
											>
												Наименование
											</span>
											<v-text-field
												v-model="data.name"
												class="border-all"
												hide-details
												flat
												solo
												dense
												type="text"
												:rules="rules.required"
												required
											></v-text-field>
										</v-col>
										<!-- Имя -->
										<v-col cols="12" sm="6">
											<span
												class="subtitle-2 grey--text mb-2  font-weight-light"
											>
												Имя
											</span>
											<v-text-field
												v-model="data.director_name"
												class="border-all"
												hide-details
												flat
												solo
												dense
												type="text"
												:rules="rules.required"
												required
											></v-text-field>
										</v-col>
										<!-- Фамилия -->
										<v-col cols="12" sm="6">
											<span
												class="subtitle-2 grey--text mb-2  font-weight-light"
											>
												Фамилия
											</span>
											<v-text-field
												v-model="data.director_surname"
												class="border-all"
												hide-details
												flat
												solo
												dense
												type="text"
												:rules="rules.required"
												required
											></v-text-field>
										</v-col>
										<!-- Отчество -->
										<v-col cols="12" sm="6">
											<span
												class="subtitle-2 grey--text mb-2  font-weight-light"
											>
												Отчество
											</span>
											<v-text-field
												v-model="data.director_patronymic"
												class="border-all"
												hide-details
												flat
												solo
												dense
												type="text"
											></v-text-field>
										</v-col>
										<!-- Город -->
										<v-col cols="12" sm="6">
											<span
												class="subtitle-2 grey--text mb-2  font-weight-light"
											>
												Город
											</span>
											<v-select
												v-model="data.city_id"
												class="border-all"
												hide-details
												flat
												solo
												dense
												:items="catalog.cities"
												item-text="name"
												item-value="id"
												:rules="rules.required"
												required
											></v-select>
										</v-col>
										<!-- Адрес -->
										<v-col cols="12" sm="6">
											<span
												class="subtitle-2 grey--text mb-2  font-weight-light"
											>
												Адрес
											</span>
											<v-text-field
												v-model="data.address_street"
												class="border-all"
												hide-details
												flat
												solo
												dense
												:rules="rules.required"
												required
											></v-text-field>
										</v-col>
										<!-- Тип -->
										<v-col cols="12" sm="6">
											<span
												class="subtitle-2 grey--text mb-2  font-weight-light"
											>
												Тип
											</span>
											<v-select
												v-model="data.organization_type"
												class="border-all"
												hide-details
												flat
												solo
												dense
												:items="catalog.organization_type"
												item-text="name"
												item-value="id"
												:rules="rules.required"
												required
											></v-select>
										</v-col>
										<!-- Телефон -->
										<v-col cols="12" sm="6">
											<span
												class="subtitle-2 grey--text mb-2  font-weight-light"
											>
												Телефон
											</span>
											<v-text-field
												v-model="data.phone"
												v-mask="'+7-###-###-##-##'"
												placeholder="+7-"
												class="border-all"
												hide-details
												flat
												solo
												dense
												:rules="rules.required"
												required
											></v-text-field>
										</v-col>
										<!-- Email -->
										<v-col cols="12" sm="6">
											<span
												class="subtitle-2 grey--text mb-2  font-weight-light"
											>
												Email
											</span>
											<v-text-field
												v-model="data.email"
												class="border-all"
												hide-details
												flat
												solo
												dense
												:rules="rules.required"
												required
											></v-text-field>
										</v-col>
									</v-row>
								</v-form>
							</v-card-text>

							<!-- Футер -->
							<v-card-actions class="pa-6 pt-4 d-flex flex-start">
								<v-btn
									v-if="showManualBtn"
									class="mr-4 subtitle-2 text-capitalize"
									color="primary"
									depressed
								>
									Ввести вручную
								</v-btn>
								<v-btn
									class="mr-4 subtitle-2 text-capitalize"
									color="primary"
									depressed
									:loading="loading"
									@click="registerKsk"
								>
									Создать аккаунт
								</v-btn>
								<div class="d-flex flex-column">
									<router-link
										class="href subtitle-2 font-weight-regular"
										to="/login"
									>
										Страница входа
									</router-link>
								</div>
							</v-card-actions>
						</v-card>
					</v-flex>
				</v-layout>
			</v-container>
		</v-main>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "Register",
	data() {
		return {
			alert: null,
			bin: "",
			data: {
				bin: "",
				name: "",
				email: "",
				director_phone: "",
				director_name: "",
				director_surname: "",
				director_patronymic: "",
				address_street: "",
				city_id: "",
				organization_type: "",
				phone: "",
			},
			loading: false,
			timer: null,
			showManualBtn: false,
			showManualForm: false,
			rules: {
				bin: [v => (!!v && v.length === 12) || "БИН состоит из 12 цифр"],
				required: [v => !!v || ""],
			},
		};
	},
	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
			organization_types: "ksk/GET_ORGANIZATION_TYPES",
		}),
	},
	// watch: {
	// 	bin(newVal) {
	// 		clearTimeout(this.timer);
	// 		this.timer = null;
	// 		if (newVal.length === 12) {
	// 			this.timer = setTimeout(() => this.findKskByBin(), 500);
	// 		}
	// 	},
	// },
	methods: {
		async findKskByBin() {
			this.alert = null;
			this.showManualBtn = false;

			try {
				this.loading = true;
				const res = await this.$api.ksk.load_ksk_info_bin(this.bin);
				this.data = { ...this.data, ...res };
				// this.toggleManualForm();
			} catch (error) {
				this.showManualBtn = true;
				this.alert = {
					type: "error",
					message: error.message || "Не удалось найти БИН в базе данных",
				};
			} finally {
				this.loading = false;
			}
		},

		async registerKsk() {
			this.alert = null;

			try {
				this.loading = true;

				if (!this.$refs.form.validate()) return;

				const res = await this.$api.ksk.register_ksk(this.data);

				this.alert = {
					type: "success",
					message: res.success,
				};

				setTimeout(() => this.$router.push("/login"), 500);

				this.$refs.form.reset();
			} catch (error) {
				this.alert = {
					color: "error",
					message: error.message || "Ошибка",
				};
			} finally {
				this.loading = false;
			}
		},
		// toggleManualForm() {
		// 	this.showManualBtn = false;
		// 	this.alert = null;
		// 	this.showManualForm = true;
		// 	this.data.bin = this.bin;
		// },
		resetValidation() {
			this.$refs.form.resetValidation();
		},
	},
	created() {
		// this.$store.dispatch("ksk/load_organization_types");
	},
};
</script>

<style lang="scss" scoped>
.register {
	height: 100vh;
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #f5f5f5;
}

::v-deep .v-text-field.v-text-field--solo.v-input--dense {
	&:focus-within {
		border-color: #77dc99 !important;
		box-shadow: 0px 0px 4px #81e9a3;
	}
	&.error--text {
		position: relative;
		border-color: red !important;
		&:focus-within {
			box-shadow: 0px 0px 4px rgb(255, 97, 97);
		}
		.v-text-field__details {
			display: block !important;
			position: absolute;
			bottom: -25px;
			left: -10px;
		}
		.v-input__slot {
			margin-bottom: 0px !important;
		}
	}
}
::v-deep .v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
	min-height: 33px !important;
	font-size: 14px !important;
}
::v-deep .v-text-field__details {
	display: none !important;
}
</style>
