<template>
	<div class="employee-item" ref="item">
		<EmployeeItemList />
		<EmployeeItemInfo />
	</div>
</template>

<script>
import EmployeeItemList from "@/components/EmployeeItemList";
import EmployeeItemInfo from "@/components/EmployeeItemInfo";

export default {
	name: "EmployeeItem",
	components: {
		EmployeeItemList,
		EmployeeItemInfo,
	},
	methods: {
		scrollToElement(refName) {
			const element = this.$refs[refName];
			const top = element.offsetTop;
			window.scrollTo(0, top);
		},
	},
	mounted() {
		// * Проскроллить к информации при загрузке страницы
		setTimeout(() => {
			this.scrollToElement("item");
		}, 500);
	},
};
</script>

<style lang="scss" scoped>
.employee-item {
	height: 100%;
	background: #ffffff;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
	display: grid;
	grid-template-columns: 1fr 4fr;
}
.employee-item-info,
.employee-item-list {
	height: 100% !important;
}
</style>
