<template>
	<v-data-table
		class="overflow-x elevation-1"
		:headers="headers"
		:items="items"
		:loading="loading"
		height="100%"
		item-key="name"
		hide-default-footer
		@click:row="handleRowClick"
	>
		<template v-slot:top="{ pagination, options, updateOptions }">
			<v-data-footer
				class="justify-end pa-3 border-bottom"
				:pagination="pagination"
				:options="options"
				@update:options="updateOptions"
				items-per-page-text=""
				:items-per-page-options="[]"
				disable-items-per-page
			>
				<template v-slot:prepend>
					<div class="d-flex flex-sm-grow-1 align-center justify-start">
						<v-btn
							class="mr-5"
							color="primary"
							@click="$emit('modal-create')"
							small
							depressed
						>
							<v-icon class="mr-1" small>mdi-plus</v-icon>
							<span class="body-2 font-weight-light">Создать</span>
						</v-btn>
						<div class="d-flex align-center mr-3">
							<span class="subtitle-2 grey--text mr-3">от</span>
							<DatePickerPlain :date.sync="dateFrom" />
							<span class="mx-3 subtitle-2 grey--text">от</span>
							<DatePickerPlain :date.sync="dateTo" />
							<v-btn class="ml-3" icon>
								<v-icon>mdi-magnify</v-icon>
							</v-btn>
						</div>
						<v-btn class="mr-5 text-lowercase" color="primary" small outlined>
							<span class="body-2 font-weight-light">
								<span class="text-capitalize">Сбросить</span> фильтры
							</span>
						</v-btn>
						<v-btn class="mr-5" color="primary" small depressed>
							<v-icon class="mr-1" small>mdi-microsoft-excel</v-icon>
							<span class="body-2 font-weight-light text-capitalize">
								Экспорт
							</span>
						</v-btn>
					</div>
				</template>
			</v-data-footer>
		</template>
		<template v-slot:[`body.prepend`]>
			<slot name="filters"></slot>
		</template>
		<template v-for="header in customHeaders" v-slot:[`item.${header}`]>
			<slot :name="header"></slot>
		</template>
	</v-data-table>
</template>

<script>
import DatePickerPlain from "@/components/app/DatePickerPlain";

export default {
	name: "DataTable",
	components: { DatePickerPlain },
	props: {
		customHeaders: {
			type: Array,
			default: [],
			required: false,
		},
		items: {
			type: Array,
			required: true,
		},
		loading: {
			type: Boolean,
			defualt: false,
		},
		headers: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			dateFrom: new Date().toISOString().substr(0, 10),
			dateTo: new Date().toISOString().substr(0, 10),
			showModalCreate: false,
		};
	},

	computed: {},
	methods: {
		handleRowClick(e) {
			console.log(e);
		},
	},
};
</script>

<style scoped>
::v-deep .v-data-table {
	overflow-x: auto !important;
}

::v-deep tbody td,
tbody th {
	height: 58px !important;
}

::v-deep tbody tr:nth-of-type(even):not(:nth-child(1)) {
	background-color: rgba(0, 0, 0, 0.05) !important;
}
</style>
