<template>
  <v-app>
    <v-main>
      <v-container>
        <v-row>
          <v-col cols="3" v-for="(item, index) in menuItems" :key="index" class="text-center">
            <v-btn icon @click="navigateTo(item.route)" color="red">
              <v-icon>{{ item.icon }}</v-icon>
            </v-btn>
            <p class="text-caption">{{ item.label }}</p>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      menuItems: [
        { icon: 'mdi-qrcode-scan', label: 'Kaspi QR', route: '/kaspi-qr' },
        { icon: 'mdi-bank', label: 'Мой Банк', route: '/my-bank' },
        { icon: 'mdi-cash-multiple', label: 'Платежи', route: '/payments' },
        { icon: 'mdi-transfer', label: 'Переводы', route: '/transfers' },
        { icon: 'mdi-cart', label: 'Магазин', route: '/shop' },
        { icon: 'mdi-airplane', label: 'Travel', route: '/travel' },
        { icon: 'mdi-file-document-edit', label: 'Госуслуги', route: '/gov-services' },
        { icon: 'mdi-bullhorn', label: 'Объявления', route: '/announcements' },
      ],
    };
  },
  methods: {
    navigateTo(route) {
      // qwerty
    },
  },
};
</script>
