<template>
	<div class="employee-item-list d-flex flex-column">
		<div class="border d-flex justify-space-between align-center py-5 px-3">
			<v-btn
				class="pa-0"
				link
				depressed
				plain
				@click="$router.push({ path: '/employee', query: $route.query })"
			>
				<v-icon color="grey darken-1" small>mdi-chevron-left</v-icon>
				<span class="caption">Список</span>
			</v-btn>
		</div>

		<!-- Спиннер для загрузки новых записей  -->
		<div
			v-if="loadingMore || loading"
			class="d-flex justify-center"
			:class="{
				'h-100 border-right flex-sm-grow-1 overflow-y-auto': loading,
			}"
		>
			<v-progress-linear
				absolute
				indeterminate
				color="green"
			></v-progress-linear>
		</div>

		<div class="d-flex px-3 pt-3 border-right">
			<!-- Filter -->
			<v-text-field
				v-model="phone"
				v-mask="'+7-###-###-##-##'"
				class="border-all"
				solo
				dense
				hide-details
				flat
				clearable
				append-icon="mdi-magnify"
				placeholder="+7-"
			></v-text-field>
		</div>
		<!-- Записи -->
		<div
			class="list border-right d-flex flex-column align-center pa-3 flex-sm-grow-1 overflow-y-auto"
			@scroll="onScroll"
		>
			<EmployeeItemListCard
				v-for="item of items"
				:key="item.id"
				:item="item"
				:id="item._id"
				@change-page="changePage(item)"
			/>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import EmployeeItemListCard from "@/components/EmployeeItemListCard";

export default {
	name: "EmployeeItemList",
	components: {
		EmployeeItemListCard,
	},
	data() {
		return {
			items: [],
			roles: [],
			lastPage: null,
			loading: false,
			loadingMore: false,
			scrolledPages: [],
			phone: null,
			timer: null,
			page: +this.$route.query.page || 1,
		};
	},

	watch: {
		phone(val) {
			console.log('val', val)
			if (val === "+7-") this.phone = null;
			clearTimeout(this.timer);
			this.timer = setTimeout(
				() => this.loadData(undefined, undefined, true),
				500
			);
		},
	},
	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
			userData: "auth/GET_INDIVIDUAL_DATA",
			houses: "ksk/GET_HOUSES",
			base_role: "auth/GET_USER_ROLE",
			kskId: "auth/GET_KSK_ID",
		}),
	},
	methods: {
		changePage(item) {
			this.$router.replace({ query: { page: item.page } });
		},

		onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
			const firstPage = this.items[0].page;
			const lastPage = this.items[this.items.length - 1].page;

			if (
				scrollTop + clientHeight >= scrollHeight &&
				lastPage <= this.lastPage
			) {
				this.loadData();
			} else if (scrollTop === 0 && firstPage - 1 >= 1) {
				this.loadData(false);
			}
		},

		scrollIntoView(id) {
			document.getElementById(id).scrollIntoView({
				behavior: "smooth",
			});
		},

		resetFilters() {
			this.page = 1;
			this.scrolledPages = [];
			this.$router.replace({ query: { page: 1 } });
			this.loadData(undefined, true);
		},

		// https://stackoverflow.com/questions/59603315/how-to-detect-when-a-user-scrolls-to-the-bottom-of-a-div-vue

		async loadData(toDown = true, update = false, filter = false) {
			try {
				let newPage;

				if (filter) newPage = 1;
				else if (update) newPage = this.page;
				else if (toDown) newPage = this.items[this.items.length - 1].page + 1;
				else newPage = this.items[0].page - 1;

				if (!filter && this.scrolledPages.includes(newPage)) {
					return;
				}

				if (update) {
					this.loading = true;
				} else {
					this.loadingMore = true;
				}

				const params = {
					...this.$route.query,
					paginate: true,
					page_count: 10,
					page: newPage,
				};
				if(filter) params.phone = this.phone
				if(this.base_role == 'ksk_manager') {
					if(!params.hasOwnProperty('ksk_houses_ids')) {
						params.ksk_houses_ids = this.userData.ksk_houses.map(item => item.house.id);
					}
					params.company_ids = [this.kskId]
				}
				if(this.base_role == 'ksk') {
					params.company_ids = [this.kskId]
				}
				if(!params.hasOwnProperty('role_ids') || !params.role_ids.length) {
					params.role_ids = []
					this.roles.forEach(role => {
						params.role_ids.push(role.id);
					});
				}
				if(!params.hasOwnProperty('page')) params.page = 1
				params.page_count = 10
				if(!params.hasOwnProperty('role_ids') || !params.role_ids.length) {
					if(this.base_role === 'administrator') {
						params.role_ids = [
							"61fba08f69d79635b83c1d27",
							"5f4cda2f0796c90b114f5537",
							"5f4cda2f0796c90b114f5536",
							"5f4cda2f0796c90b114f5535"
						]
					}
				}
				if(this.base_role !== 'administrator' && this.base_role !== 'dispatcher') {
					params.selected_ksk_id = this.kskId
				}
				const res = await this.$api.ksk.load_employee(params);
				res.records = res.records.map(e => ({ ...e, page: newPage }));
				this.lastPage = res.pages;
				this.page = res.currentPage

				if (update || filter) {
					this.items = [...res.records];
				} else if (toDown) {
					this.scrolledPages.push(newPage);
					this.items = [...this.items, ...res.records];
				} else {
					this.scrolledPages.unshift(newPage);
					this.items = [...res.records, ...this.items];
				}
			} catch (error) {
				console.log(error);
			} finally {
				this.loading = this.loadingMore = false;
			}
		},
	},

	async created() {
		if(this.base_role == 'ksk_manager') {
			this.roles = this.catalog.roles.filter(role => {
				return role.name_system === 'ksk_employee' || role.name_system === 'ksk_dispatcher';
			});
		}
		if(this.base_role == 'ksk') {
			this.roles = this.catalog.roles.filter(role => {
				return role.name_system === 'ksk_employee' || role.name_system === 'ksk_dispatcher' || role.name_system === 'ksk_manager';
			});
		}
		await this.loadData(undefined, true);

		if (this.items.length < 10) {
			if (this.page - 1 >= 1) {
				this.page--;
				await this.loadData(false);
			}
		}
		// const { _id } = this.items.find(e => e._id === this.$route.params.id);
		// setTimeout(() => {
		// 	this.scrollIntoView(_id);
		// }, 100);
	},
};
</script>

<style lang="scss" scoped>
.employee-item-list {
	height: 100%;
	position: relative;
}
.h-100 {
	height: 100vh;
}
.list {
	flex: 1;
	max-height: 95vh;
}
::v-deep .v-text-field {
	&:focus-within {
		border-color: #77dc99 !important;
		box-shadow: 0px 0px 4px #81e9a3;
	}
	&.error--text {
		position: relative;
		border-color: red !important;
		&:focus-within {
			box-shadow: 0px 0px 4px rgb(255, 97, 97);
		}
		.v-text-field__details {
			display: block !important;
			position: absolute;
			bottom: -25px;
			left: -10px;
		}
		.v-input__slot {
			margin-bottom: 0px !important;
		}
	}
}
::v-deep .v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
	min-height: 33px !important;
	font-size: 14px !important;
}
::v-deep .v-text-field__details {
	display: none !important;
}
</style>
