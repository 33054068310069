<template>
	<div class="faq">
		<!-- Верхняя -->
		<div class="d-flex justify-space-between mb-6">
			<!-- Поиск по адресу -->
			<v-autocomplete
				v-model="filter.house_id"
				class="border-all mr-5"
				placeholder="Адрес"
				prepend-inner-icon="mdi-map-marker"
				hide-details
				dense
				solo
				flat
				clearable
				:filter="addressFilter"
				:items="houses"
				item-text="apartment_address"
				item-value="_id"
			>
			</v-autocomplete>

			<!-- Поиск по ключевым словам -->
			<v-text-field
				v-model="filter.name"
				class="border-all mr-5"
				placeholder="Поиск по ключевым словам"
				prepend-inner-icon="mdi-magnify"
				hide-details
				dense
				solo
				flat
				clearable
			>
			</v-text-field>

			<!-- Категория -->
			<v-autocomplete
				v-model="filter.category_id"
				class="border-all mr-5"
				placeholder="Категория"
				prepend-inner-icon="mdi-filter"
				hide-details
				dense
				solo
				flat
				clearable
				:items="catalog.request_categories"
				item-text="name"
				item-value="id"
			>
			</v-autocomplete>

			<!-- Кнопка -->
			<v-btn
				color="primary"
				depressed
				class="text-capitalize subtitle-2"
				@click="showCreateModal = true"
			>
				Добавить вопрос
			</v-btn>
		</div>

		<v-data-table
			class="overflow-x elevation-0"
			height="100%"
			item-key="name"
			hide-default-footer
			:headers="headers"
			:items="items"
			:loading="loading"
			:page.sync="options.page"
			:options="options"
			:server-items-length="options.itemsLength"
			@click:row="handleRowClick"
		>
			<!--  ХЭДЕР ТАБЛИЦЫ -->
			<template v-slot:top="{ pagination, options, updateOptions }">
				<v-data-footer
					class="justify-end pa-3 border-bottom"
					:pagination="pagination"
					:options="options"
					@update:options="updateOptions"
					items-per-page-text=""
					:items-per-page-options="[]"
					disable-items-per-page
					:disable-pagination="loading"
					:show-first-last-page="true"
				>
				</v-data-footer>
			</template>
			<!-- ПОЛЯ ТАБЛИЦЫ -->
			<template v-slot:[`item.`]="{ item }">
				<td>
					<v-text-field
						v-model="item.iin"
						v-mask="'############'"
						class="border-all"
						solo
						dense
						flat
						hide-details
						:error="String(item.iin).length > 0 && String(item.iin).length < 12"
						@change="setApartmentIIN(item)"
					>
					</v-text-field>
				</td>
			</template>
			<template v-slot:[`item.category_id`]="{ item }">
				<td>
					{{ item.category_id | getCatalogValue("request_categories") }}
				</td>
			</template>
			<template v-slot:[`item.delete_question`]="{ item }">
				<td>
					<v-btn
						color="red white--text"
						class="subtitle-2 text-capitalize"
						small
						depressed
						:loading="isDeleting === item._id"
						@click.stop="deleteQuestion(item)"
					>
						Удалить
					</v-btn>
				</td>
			</template>
		</v-data-table>

		<FaqModal
			v-if="showCreateModal"
			:to-edit="toEdit"
			@close-modal="closeModal"
			@update-info="loadData"
		/>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import FaqModal from "@/components/FaqModal";

export default {
	name: "Home",

	components: { FaqModal },

	data() {
		return {
			left: null,
			right: null,
			loading: false,
			showCreateModal: false,
			filter: {
				category_id: null,
				house_id: null,
				name: null,
			},
			isDeleting: -1,
			items: [],
			toEdit: null,
			query: null,
			headers: [
				{
					text: "Вопрос",
					value: "name",
					sortable: false,
				},
				{
					text: "Категория",
					value: "category_id",
					sortable: false,
				},
				{
					text: "Удаление вопроса",
					value: "delete_question",
					sortable: false,
				},
			],
			options: {
				page: +this.$route.query.page || 1,
				itemsPerPage: 10,
				pageStart: 0,
				pageCount: 0,
				itemsLength: 0,
			},
		};
	},

	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
			houses: "ksk/GET_HOUSES",
		}),
	},

	watch: {
		filter: {
			handler() {
				this.loadData();
			},
			deep: true,
		},
		options: {
			handler() {
				const query = { ...this.query, page: this.options.page };
				this.loadData(query);
			},
			deep: true,
		},
	},

	methods: {
		handleRowClick(item) {
			if (this.isDeleting !== -1) return;
			this.showCreateModal = true;
			this.toEdit = item;
		},

		async loadData() {
			try {
				this.loading = true;
				const res = await this.$api.administrator.load_questions({
					...this.filter,
					page: this.options.page,
				});
				this.options.page = res.page;
				this.options.pageCount = res.pages;
				this.options.itemsLength = res.pages * 10;
				this.items = res.records;
				console.log(this.items);
			} catch (error) {
				console.error(error);
			} finally {
				this.loading = false;
			}
		},

		async deleteQuestion(item) {
			try {
				this.isDeleting = item._id;
				const res = await this.$api.administrator.delete_question(item._id);
				this.$root.snackbar.show({ message: res.success });
				this.loadData();
			} catch (error) {
				this.$root.snackbar.show({ message: error.message, color: "error" });
			} finally {
				this.isDeleting = -1;
			}
		},

		async closeModal() {
			this.showCreateModal = false;
			this.toEdit = null;
		},
	},

	created() {
		this.$store.dispatch("ksk/load_houses");
	},

	mounted() {
		this.loadData();
	},
};
</script>

<style lang="scss" scoped>
.faq {
	padding: 20px;
	background: #fff;
	min-height: 90vh;
}
.custom-input.v-input {
	border: 1px solid #d8dbe0;
	border-radius: 4px;
	&:focus-within {
		border-color: #77dc99 !important;
		box-shadow: 0px 0px 4px #81e9a3;
	}
	&.error--text {
		position: relative;
		border-color: red !important;
		&:focus-within {
			box-shadow: 0px 0px 4px rgb(255, 97, 97);
		}
		.v-text-field__details {
			display: block !important;
			position: absolute;
			bottom: -25px;
			left: -10px;
		}
		.v-input__slot {
			margin-bottom: 0px !important;
		}
	}
}
::v-deep .v-input > .v-input__control {
	min-height: 33px !important;
	font-size: 14px !important;
	padding: 0;
}
::v-deep .v-text-field__details {
	display: none !important;
}
</style>
