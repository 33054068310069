<template>
	<v-dialog v-model="dialog" persistent width="450">
		<v-card>
			<v-card-title
				class="d-flex justify-space-between border-bottom primary white--text"
			>
				<span class="body-1">Изменение пароля</span>
				<v-btn color="white" @click="closeModal" icon>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text class="border-bottom py-5">
				<v-row>
					<v-col v-if="alert" cols="12">
						<v-alert :type="alert.color" dense outlined>
							{{ alert.message }}
						</v-alert>
					</v-col>
					<v-col cols="12">
						<span class="subtitle-2 grey--text mb-2 font-weight-light">
							Новый пароль
						</span>
						<v-text-field
							v-model.trim="data.auth_password"
							class="border-all"
							hide-details="auto"
							flat
							solo
							dense
							autocomplete="off"
							:rules="rules.password"
							:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
							@click:append="showPassword = !showPassword"
							:type="showPassword ? 'text' : 'password'"
						></v-text-field>
					</v-col>

					<v-col cols="12">
						<span class="subtitle-2 grey--text mb-2 font-weight-light">
							Подтверждение пароля
						</span>
						<v-text-field
							v-model.trim="confirm"
							class="border-all"
							hide-details="auto"
							flat
							solo
							dense
							:rules="rules.confirm"
							:append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
							@click:append="showConfirmPassword = !showConfirmPassword"
							:type="showConfirmPassword ? 'text' : 'password'"
						></v-text-field>
					</v-col>

				</v-row>
				<p class="text-caption mb-0 mt-3 grey--text">Требование к паролю : одна заглавная буква , одна маленькая буква , одна цифра , один спецсимвол, минимальная длина пароля 8 символов* </p>
				<p class="text-caption mb-0 mt-3 grey--text">Примечание: постоянный пароль будет действовать 90 дней, со дня его создания.</p>
			</v-card-text>
			<v-card-actions class="d-flex justify-center">
				<v-btn
					class="subtitle-2 text-capitalize"
					color="primary white--text"
					depressed
					@click="changePassword"
				>
					Подтвердить
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	name: "ProfilePasswordModal",
	data() {
		return {
			alert: null,
			dialog: true,
			confirm: '',
			data: {
				auth_password: "",
				_method: 'patch'
			},
			loading: false,
			showPassword: false,
			showConfirmPassword: false,
			rules: {
				password: [
					v => !!v || "Введите новый пароль",
					v =>
						(!!v && v.length >= 8) ||
						"Пароль должен состоять минимум из 8 символов",
				],
				confirm: [
					v => !!v || "Подтвердите пароль",
					v => (!!v && v === this.data.auth_password) || "Пароли не совпадают",
				],
			},
		};
	},
	computed: {
		...mapGetters({
			userId: "auth/GET_USER_ID",
		}),
	},
	methods: {
		async changePassword() {
			if(this.data.auth_password !== this.confirm) return
			try {
				this.loading = true;

				const res = await this.$api.user.change_password(this.data, this.userId)
				this.alert = {
					color: "success",
					message: res.success || "Пароль изменен",
				};
				setTimeout(() => this.closeModal(), 500);
			} catch (error) {
				console.log('err', error)
				this.alert = {
					color: "error",
					message: error.message || "Не удалось изменить пароль",
				};
			} finally {
				this.loading = false;
			}
		},

		closeModal(confirm = false) {
			this.dialog = false;
			this.$emit("close-modal", confirm);
		},
	},
};
</script>

<style lang="scss" scoped></style>
