<template>
	<div class="houses-table">
		<v-data-table
			class="overflow-x elevation-1"
			height="100%"
			item-key="_id"
			hide-default-footer
			:headers="base_role == 'administrator' ? headersAdmin : headers"
			:items="items"
			:loading="loading"
			:page.sync="options.page"
			:options="options"
			:server-items-length="options.itemsLength"
			@click:row="handleRowClick"
		>
			<!--  ХЭДЕР ТАБЛИЦЫ -->
			<template v-slot:top="{ pagination, options, updateOptions }">
				<v-data-footer
					class="justify-end pa-3 border-bottom"
					:pagination="pagination"
					:options="options"
					@update:options="updateOptions"
					items-per-page-text=""
					:items-per-page-options="[]"
					disable-items-per-page
					:disable-pagination="loading"
					:show-first-last-page="true"
				>
					<!-- <template v-slot:page-text>
						{{ options.page || "-" }} из {{ options.pageCount || "-" }}
					</template> -->
					<template v-slot:prepend>
						<div class="d-flex flex-sm-grow-1 align-center justify-start">
							<!-- Кнопка создания -->
							<v-btn
								v-if="companyType !== 'sc'"
								class="mr-5"
								color="primary"
								@click="isEditing = false; showModalCreate = true"
								small
								depressed
							>
								<v-icon class="mr-1" small>mdi-plus</v-icon>
								<span class="body-2 font-weight-light">Создать</span>
							</v-btn>
							<!-- Дата создания -->
							<div class="d-flex align-center mr-3">
								<span class="subtitle-2 grey--text mr-3">от</span>
								<DatePickerPlain
									:date.sync="filter.created_at_first_search"
									@change="updateQuery"
								/>
								<span class="mx-3 subtitle-2 grey--text">до</span>
								<DatePickerPlain
									:date.sync="filter.created_at_second_search"
									@change="updateQuery"
								/>
								<v-btn class="ml-3" icon>
									<v-icon>mdi-magnify</v-icon>
								</v-btn>
							</div>
							<!-- Сброс фильтров -->
							<v-btn
								class="mr-5 text-lowercase"
								color="primary"
								small
								outlined
								@click="resetFilters"
							>
								<span class="body-2 font-weight-light">
									<span class="text-capitalize">Сбросить</span> фильтры
								</span>
							</v-btn>
							<!-- <v-btn
								color="primary"
								small
								depressed
								:loading="isExportingExcel"
								@click="exportExcel"
							>
								<v-icon class="mr-1" small>mdi-microsoft-excel</v-icon>
								<span class="body-2 font-weight-light text-capitalize">
									Экспорт
								</span>
							</v-btn> -->
						</div>
					</template>
				</v-data-footer>
			</template>
			<!-- ФИЛЬТРЫ ТАБЛИЦЫ -->
			<template v-slot:[`body.prepend`]>
				<tr>
					<!-- Адрес -->
					<td>
						<v-autocomplete
							v-model="filter.apartment_address"
							class="border-all"
							placeholder="Адрес"
							solo
							dense
							flat
							hide-details
							clearable
							item-text="apartment_address"
							item-value="apartment_address"
							:items="houses"
							:filter="addressFilter"
							@change="updateQuery"
						>
						</v-autocomplete>
					</td>
					<!-- Название ЖК -->
					<!-- <td>
						<v-text-field
							v-model="filter.name"
							class="border-all"
							solo
							dense
							flat
							hide-details
							@change="updateQuery"
						>
						</v-text-field>
					</td> -->
					<!-- РКА -->
					<td v-if="base_role == 'administrator'">
						<v-text-field
							v-model="filter.name"
							placeholder="Наименование"
							class="border-all"
							solo
							dense
							flat
							hide-details
							@change="updateQuery"
						>
						</v-text-field>
					</td>
					<td>
						<v-text-field
							v-model="filter.rca"
							v-mask="'################'"
							class="border-all"
							placeholder="РКА"
							solo
							dense
							flat
							hide-details
							@change="updateQuery"
						>
						</v-text-field>
					</td>
					<td v-if="base_role == 'administrator'">
						<v-combobox 
							v-if="base_role !== 'ksk_dispatcher'"
							v-model="filter.ksk_id"
							class="text-caption border-all"
							solo
							multiple
							item-text="name"
							item-value="_id"
							:return-object="false"
							:items="kskList"
							@change="updateQuery"
							dense
							hide-details
							flat
						>
							<template v-slot:selection="{ item, index }">
								<span v-if="index === 0">
									{{ currentKsk(item) }}
								</span>
								<span
									v-if="index === 1"
									class="grey--text text-caption"
								>&nbsp;
									(+ еще {{ filter.ksk_id.length - 1 }})
								</span>
							</template>
						</v-combobox>
					</td>
					<!-- Кол-во квартир -->
					<td v-if="base_role !== 'administrator'">---</td>
					<!-- Кол-во НП -->
					<td v-if="base_role !== 'administrator'">---</td>
					<!-- Кол-во паркомест -->
					<td v-if="base_role !== 'administrator'">---</td>
					<!-- Категория ЖК -->
					<!-- <td>
						<v-select
							v-model="filter.category_residential_complex"
							class="border-all"
							solo
							dense
							flat
							hide-details
							item-value="_id"
							item-text="name"
							:items="[
								{ name: 'Все', _id: '' },
								...catalog.category_residential_complex,
							]"
							@change="updateQuery"
						>
						</v-select>
					</td>
					<td>
						<v-text-field
							v-model="filter.year_construction"
							type="number"
							class="border-all"
							solo
							dense
							flat
							hide-details
							@change="updateQuery"
							min="1900"
						>
						</v-text-field>
					</td> -->
					<td>
						<DatePickerPlain
							:date.sync="filter.created_at"
							:nudge-left="'140px'"
							:in-table="true"
							@change="updateQuery"
						/>
					</td>
					<td class="text-center" v-if="base_role === 'administrator'">---</td>
				</tr>
			</template>
			<!-- ПОЛЯ ТАБЛИЦЫ -->
			<template v-slot:[`item.name`]="{ item }">
				<td>
					{{ item.name || "---" }}
				</td>
			</template>
			<template v-slot:[`item.year_construction`]="{ item }">
				<td>
					{{ getYearConstruction(item) }}
				</td>
			</template>
			<template v-slot:[`item.category_residential_complex_id`]="{ item }">
				<td>
					{{ getComplexCategory(item) }}
				</td>
			</template>
			<template v-slot:[`item.created_at`]="{ item }">
				<td>
					{{ item.created_at | timestamp(true) }}
				</td>
			</template>
			<template v-slot:[`item.visible`]="{ item }">
				<td>
					<v-checkbox
						
						v-model="item.visible"
						@click.stop="toggleVisible(item)"
					>
					</v-checkbox>
				</td>
			</template>
		</v-data-table>
		<!-- ОКНО СОЗДАНИЯ ЗАЯВКИ -->
		<HousesTableModal
			v-if="showModalCreate"
			@update-info="updateQuery"
			:house-data="item"
			:is-editing="isEditing"
			@close-modal="showModalCreate = false"
		/>
	</div>
</template>

<script>
import { timestampToDdmmyyyy } from "@/helpers/helpers";
import { mapGetters } from "vuex";
import addressFilterMixin from "@/mixins/addressFilterMixin";

import DatePickerPlain from "@/components/app/DatePickerPlain";
import HousesTableModal from "@/components/HousesTableModalNext";

export default {
	name: "RequisitionsTable",
	components: {
		DatePickerPlain,
		HousesTableModal,
	},
	mixins: [addressFilterMixin],
	watch: {
		options: {
			handler() {
				if(this.firstFetch) {
					const query = { ...this.$route.query, page: this.options.page };
					this.$router.replace({ query });
					this.loadData(query);
				}
			},
			deep: true,
		},
	},
	data() {
		return {
			items: [],
			item: null,
			isEditing: false,
			firstFetch: false,
			filter: {
				apartment_address: this.$route.query.apartment_address || "",
				actual_number_apartments:
					this.$route.query.actual_number_apartments || "",
				created_at: this.$route.query.created_at || "",
				created_at_first_search:
					this.$route.query.created_at_first_search || "",
				created_at_second_search:
					this.$route.query.created_at_second_search || "",
				category_residential_complex:
					this.$route.query.category_residential_complex_id || "",
				name: this.$route.query.name || "",
				actual_number_non_residential_premises:
					this.$route.query.actual_number_non_residential_premises || "",
				actual_number_parking_place:
					this.$route.query.actual_number_parking_place || "",
				rca: this.$route.query.rca || "",
				year_construction: this.$route.query.year_construction || "",
				ksk_id: this.$route.query.ksk_ids || "",
			},
			filtersQuery: {
				"apartment_address": "apartment_address",
				"actual_number_apartments": "actual_number_apartments",
				"created_at": "created_at_first",
				"created_at_first_search": "created_at_first",
				"created_at_second_search": "created_at_second",
				"category_residential_complex": "category_residential_complex_id",
				"name": "name",
				"ksk": "ksk_id",
				"ksk_id" : "ksk_ids",
				"actual_number_non_residential_premises":
					"actual_number_non_residential_premises",
				"actual_number_parking_place": "actual_number_parking_place",
				"rca": "rca",
				"year_construction": "year_construction",
			},
			headers: [
				// { text: "№ ID", value: "id", width: "120px" },
				{
					text: "Адрес",
					value: "apartment_address",
					width: "250px",
					sortable: false,
				},
				// { text: "Название ЖК", value: "name", width: "250px", sortable: false },
				{ text: "РКА", value: "rca", width: "200px", sortable: false },
				{
					text: "Кол-во квартир",
					value: "actual_number_apartments",
					width: "150px",
					sortable: false,
				},
				{
					text: "Кол-во НП",
					value: "actual_number_non_residential_premises",
					width: "150px",
					sortable: false,
				},
				{
					text: "Кол-во паркомест",
					value: "actual_number_parking_place",
					width: "200px",
					sortable: false,
				},
				// {
				// 	text: "Категория ЖК",
				// 	value: "category_residential_complex_id",
				// 	width: "200px",
				// 	sortable: false,
				// },
				// {
				// 	text: "Год постройки",
				// 	value: "year_construction",
				// 	width: "150px",
				// 	sortable: false,
				// },
				{
					text: "Дата регистрации",
					value: "created_at",
					width: "180px",
					sortable: false,
				},
			],
			headersAdmin: [
				{
					text: "Адрес",
					value: "apartment_address",
					width: "200px",
					sortable: false,
				},
				{
					text: "Наименование",
					value: "name",
					width: "180px",
					sortable: false,
				},
				{ text: "РКА", value: "rca", width: "200px", sortable: false },
				{
					text: "УК",
					value: "ksk.name",
					width: "200px",
					sortable: false,
				},
				{
					text: "Дата регистрации",
					value: "created_at",
					width: "200px",
					sortable: false,
				},
				{
					text: "Видимость",
					value: "visible",
					width: "80px",
					sortable: false,
				},
			],
			options: {
				page: +this.$route.query.page || 1,
				itemsPerPage: 10,
				pageStart: 1,
				pageCount: 0,
				itemsLength: 0,
			},
			isExportingExcel: false,
			loading: true,
			showModalCreate: false,
		};
	},
	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
			ksk_id: "auth/GET_KSK_ID",
			houses: "ksk/GET_HOUSES",
			userHouses: "auth/GET_HOUSES_IDS",
			kskList: "catalog/GET_KSK_LIST",
			companyType: "auth/GET_COMPANY_TYPE",
			base_role: "auth/GET_USER_ROLE",
		}),
	},
	methods: {
		async toggleVisible(item) {
			console.log(item);
			let params = {
				_method: "patch",
				visible: item.visible
			}
			try {
				const res = await this.$api.ksk.edit_house(item.id, params)
				console.log('res', res);
			} catch (error) {
				console.error(error);
			} 
		},
		currentKsk(item) {
			if(item) {
				const result = this.kskList.find(({ _id }) => _id === item);
				return result.name
			}
		},
		getComplexCategory(item) {
			return (
				this.catalog.category_residential_complex.find(
					e => e.id === item.category_residential_complex_id
				)?.name || "---"
			);
		},
		getYearConstruction(item) {
			return isNaN(Number(item.year_construction))
				? "---"
				: item.year_construction;
		},
		// ? Методы таблицы
		async handleRowClick(e) {
			if (window.getSelection().toString()) {
				return;
			}
			if(this.companyType === 'sc') return
			if(this.base_role == 'administrator') {
				console.log('e', e.id)
				this.isEditing = true
				this.item = await this.$api.ksk.load_house_by_id(e.id);
				return this.showModalCreate = true
			}
			this.$router.push({
				path: `/houses/${e._id}`,
				query: this.$route.query,
			});
		},

		async exportExcel() {
			this.isExportingExcel = true;
			try {
				const query = this.updateQuery(true);
				const res = await this.$api.ksk.export_houses_list(query);
				const link = document.createElement("a");
				link.href = res;
				link.download = `Дома ${timestampToDdmmyyyy()}.xlsx`;
				link.click();
			} catch (error) {
				console.log(error);
			} finally {
				this.isExportingExcel = false;
			}
		},

		async resetFilters() {
			for (const key in this.filter) {
				this.filter[key] = "";
			}
			this.$router.replace({ query: null });
			await this.loadData({});
		},

		async loadData(params) {
			this.loading = true;
			if(this.base_role == 'administrator') {
				console.log('admin')
				return this.$api.administrator
				.load_houses({ ...params, paginate: true })
				.then(res => {
					this.options.page = res.currentPage;
					this.options.pageCount = res.pages;
					this.options.itemsLength = res.pages * 10;
					this.items = res.records;
				})
				.catch(error => {
					throw error;
				})
				.finally(() => {
					this.loading = false;
					this.firstFetch = true
				});
			} else {
				if(this.companyType !== 'sc') {
					params.ksk_ids = [this.ksk_id]
				}
				if(this.base_role == 'ksk_manager' || this.base_role == 'ksk_dispatcher' || this.companyType === 'sc') {
					if(!this.filter.apartment_address.length) {
						const houseDataArray = this.userHouses.map(item => item.house);
						params.houses_ids = houseDataArray.map(item => item.id)
					}
				}
				console.log('params', params)
				return this.$api.ksk
				.load_houses({ ...params, paginate: true })
				.then(res => {
					this.options.page = res.currentPage;
					this.options.pageCount = res.pages;
					this.options.itemsLength = res.pages * 10;
					this.items = res.records;
				})
				.catch(error => {
					throw error;
				})
				.finally(() => {
					this.loading = false;
					this.firstFetch = true
				});
			}
			
		},

		updateQuery(getQuery = false) {
			const query = Object.entries(this.filter).reduce((acc, [key, val]) => {
				if (!val) return acc;
				return { ...acc, [this.filtersQuery[key]]: val };
			}, {});

			if (getQuery === true) {
				return query;
			}

			this.$router.replace({ query: { ...query, page: this.options.page } });
			this.loadData(query);
		},
		// ? Конец методов таблицы
	},

	async created() {
		this.$store.dispatch("ksk/load_houses");
		if(this.companyType === 'sc') {
			this.$store.dispatch("auth/load_data")
		}
	},

	async mounted() {
		try {
			console.log(this.catalog);
			const query = { ...this.$route.query, page: this.options.page };
			this.$router.replace({ query })
			await this.loadData(query);
			console.log(this.items);
			this.loading = false;
		} catch (error) {
			console.error(error);
		}
	},
};
</script>

<style lang="scss" scoped>
.houses-table {
	height: 100vh;
}
</style>
