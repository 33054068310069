<template>
	<v-hover v-slot="{ hover }">
		<!-- min-width="280" -->

		<v-card
			width="100%"
			:elevation="hover ? 5 : 0"
			:class="{ 'on-hover': hover }"
			class="mb-3 transition-fast-in-fast-out"
			:active-class="'card-active'"
			:exact-active-class="'card-active'"
			:exact-path="'card-active'"
			outlined
			link
			:to="{
				path: `/vote/${item._id}`,
				query: $route.query,
			}"
			@click="$emit('change-page')"
		>
			<v-card-title
				class="border-bottom subtitle-2 d-flex justify-space-between align-center pa-3"
			>
				<span :class="item.publish ? 'primary--text' : ''">
					{{ item.publish ? "Опубликовано" : "Неопубликовано" }}
				</span>
				<span>№{{ item.id }}</span>
			</v-card-title>

			<v-card-text class="border-bottom pa-3 d-flex flex-column">
				<span class="caption font-weight-light">
					{{ item.house.apartment_address }}
				</span>
				<div class="d-flex justify-space-between flex-md-row flex-sm-column">
					<span class="caption font-weight-light">
						{{ item.created_at | timestamp }}
					</span>
					<span
						class="caption font-weight-light"
						:class="`${deadline()}--text`"
					>
						Срок:
						{{ (new Date(item.time_vote).getTime() / 1000) | timestamp }}
					</span>
				</div>
			</v-card-text>

			<v-card-actions class="pa-3 d-flex justify-space-between">
				<span class="caption font-weight-light">
					Вопросов:
					<span class="font-weight-bold">
						{{ item.questions.length }}
					</span>
				</span>
			</v-card-actions>
		</v-card>
	</v-hover>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "VoteItemListCard",
	props: {
		item: {
			type: Object,
			required: true,
		},
	},
	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
		}),
	},
	created() {},
	methods: {
		deadline() {
			const today = new Date().setHours(0, 0, 0, 0);
			const deadline_date = new Date(
				this.item.employee_period_of_execution
			).setHours(0, 0, 0, 0);
			if (today === deadline_date) {
				return "warning";
			}
			if (today > deadline_date) {
				return "error";
			}
			return "";
		},
	},
};
</script>

<style lang="scss" scoped>
.v-card {
	width: 100% !important;
	&.card-active {
		border: thin solid #2eb85c !important;
		background: rgba(46, 184, 92, 0.09);
	}
}
</style>
