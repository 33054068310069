<template>
	<v-dialog v-model="dialog" persistent max-width="600">
		<v-card>
			<v-card-title class="d-flex justify-space-between border-bottom mb-3">
				<div class="d-flex">
					<span class="font-weight-light subtitle-2 mr-2">
						Вы собираетесь отклонить заявку
					</span>
				</div>
				<v-btn color="dark" @click="closeModal(false)" icon>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text>
				<span class="subtitle-2 font-weight-light mb-3">
					Причина по которой заявка отклоняется
				</span>
				<v-textarea
					v-model="comment"
					class="border-all"
					height="100px"
					no-resize
					solo
					flat
					dense
				></v-textarea>
			</v-card-text>
			<v-card-actions class="d-flex justify-end pb-4">
				<v-btn
					class="text-capitalize subtitle-2"
					color="red darken-1 white--text"
					depressed
					:disabled="disabled"
					:loading="loading"
					@click="closeModal(true)"
				>
					Отклонить
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: "RequisitionsItemRejectModal",
	props: {},
	data() {
		return {
			comment: "",
			dialog: true,
			loading: false,
		};
	},
	computed: {
		disabled() {
			return this.comment.length < 4;
		},
	},
	methods: {
		/**
		 * Вернуть true, если дейтсвие подвтерждено
		 */
		closeModal(close) {
			this.dialog = false;
			setTimeout(() => this.$emit("close-modal", close, this.comment), 200);
		},
	},
};
</script>

<style lang="scss" scoped></style>
