<template>
  <v-app>
    <v-app-bar app elevation="0" dense color="white">
      <v-btn color="red" icon @click="navigateToTestsKaspi1">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer> 
      <v-app-bar-title class="black--text text-subtitle-2 font-weight-bold">Парковка. Рынок САПА</v-app-bar-title>
      <v-spacer></v-spacer> 
    </v-app-bar>

    <v-main style="background: #efefef;">
      <v-row class="mt-2">
        <v-col cols="12">
          <v-card elevation="0" tile style="border: 1px solid #d0d0d0">
            <v-card-text>
              <div class="text-subtitle-1 black--text mb-2">Информация:</div>
              <div class="text-body-2 text-center">Тариф 
                <span class="black--text">Легковая 100 ₸</span>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <div class="my-6">
        <v-divider></v-divider>
        <v-container>
          <v-layout row justify-space-between>
            <div class="text-body-1 ma-4 black--text">К оплате</div>
            <div class="text-body-1 ma-4 black--text">100,00 ₸</div>
          </v-layout>
        </v-container>
        <v-divider></v-divider>
      </div>
        <v-row>
          <v-col cols="12">
            <v-card elevation="0" tile style="border: 1px solid #d0d0d0">
              <v-card-text>
                <v-layout row justify-space-between align-content-center>
                  <div class="text-body-1 ma-3 black--text" style="display: flex; align-items: center;">
                    <img src="@/assets/kaspi.png" style="border-radius: 4px; width: 40px; margin-right: 10px;" />
                    Kaspi Gold
                  </div>
                  <div class="text-body-1 ma-3 black--text">136,17 ₸</div>
                </v-layout>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-text class="py-1">
                <v-layout row justify-space-between align-content-center>
                  <div class="text-body-1 mx-3 mt-4 black--text">
                    Потратить Бонусы
                    <div class="text-caption mb-4">Накоплено: 931,19 ₸</div>
                  </div>
                  <div class="text-body-1 mx-3 mt-1 black--text">
                    <v-switch color="red"></v-switch>
                  </div>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <div class="text-center grey--text text-caption my-3">Комиссия 0 ₸</div>
        <div class="mx-2">
          <v-btn style="background: #0089d1;" class="white--text py-5" tile block>К оплате 100,00₸</v-btn>
        </div>
    </v-main>
  </v-app>
</template>

<script>
export default {
  methods: {
    navigateToTestsKaspi1() {
      this.$router.push('/tests/kaspi1');
    }
  }
};
</script>

