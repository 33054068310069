<template>
	<div class="profile">
		<v-container>
			<v-card class="elevation-0">
				<v-card-title>
					<span class="subtitle-2 font-weight-light grey--text">
						Личный кабинет
					</span>
				</v-card-title>

				<v-card-text>
					<v-list-item height="100%">
						<v-list-item-avatar color="grey darken-3 pa-0" size="100" @click="openFileInput" style="cursor: pointer;">
						<v-img
							lazy-src="@/assets/user.svg"
							v-if="avatarLoaded"
							:src="avatarSrc"
							alt="profilepic"
						></v-img>
						</v-list-item-avatar>
					</v-list-item>
					<input
						ref="fileInput"
						type="file"
						style="display: none;"
						accept="image/*"
						@change="handleFileInputChange"
					/>
					<v-form ref="form" lazy-validation>
						<v-row>
							<!-- Наименование -->
							<v-col v-if="role === 'ksk'" class="v-col" cols="12" sm="4" md="3">
								<span class="subtitle-2 grey--text mb-2 font-weight-light">
									Наименование УК
								</span>
								<v-text-field
									v-model="data.ksk_name"
									class="border-all"
									hide-details
									flat
									solo
									dense
									validate-on-blur
									:rules="rules.required"
								></v-text-field>
							</v-col>
							<!-- Имя Директора УК -->
							<v-col v-if="role === 'ksk'" class="v-col" cols="12" sm="4" md="3">
								<span class="subtitle-2 grey--text mb-2 font-weight-light">
									Имя
								</span>
								<v-text-field
									v-model="data.name"
									class="border-all"
									hide-details
									flat
									solo
									dense
									validate-on-blur
									:rules="rules.required"
								></v-text-field>
							</v-col>
							<!-- Имя  -->
							<v-col v-if="role !== 'ksk'" class="v-col" cols="12" sm="4" md="3">
								<span class="subtitle-2 grey--text mb-2 font-weight-light">
									Имя
								</span>
								<v-text-field
									v-model="data.name"
									class="border-all"
									hide-details
									flat
									solo
									dense
									validate-on-blur
									:rules="rules.required"
									:disabled="role === 'ksk_manager' || role === 'ksk_dispatcher'"
								></v-text-field>
							</v-col>
							<!-- Фамилия -->
							<v-col class="v-col" cols="12" sm="4" md="3">
								<span class="subtitle-2 grey--text mb-2 font-weight-light">
									Фамилия
								</span>
								<v-text-field
									v-model="data.surname"
									class="border-all"
									hide-details
									flat
									solo
									dense
									validate-on-blur
									:rules="rules.required"
									:disabled="role === 'ksk_manager' || role === 'ksk_dispatcher'"
								></v-text-field>
							</v-col>
							<!-- Отчество -->
							<v-col class="v-col" cols="12" sm="4" md="3">
								<span class="subtitle-2 grey--text mb-2 font-weight-light">
									Отчество
								</span>
								<v-text-field
									v-model="data.patronymic"
									class="border-all"
									hide-details
									flat
									solo
									dense
									validate-on-blur
									:rules="rules.required"
									:disabled="role === 'ksk_manager' || role === 'ksk_dispatcher'"
								></v-text-field>
							</v-col>
							<!-- Телефон -->
							<v-col class="v-col" sm="4" md="3">
								<span class="subtitle-2 grey--text mb-2  font-weight-light">
									Телефон
								</span>
								<v-text-field
									readonly
									disabled
									v-model="data.phone"
									:placeholder="phoneCode+'-'"
									v-mask="phoneCode == '+7' ? phoneCode+'-###-###-##-##' : phoneCode+'-###-###-####'"
									class="border-all"
									hide-details
									flat
									solo
									dense
									validate-on-blur
									:rules="rules.required"
								></v-text-field>
							</v-col>
						</v-row>

							<v-row v-if="role === 'ksk'" class="pt-2">
								<!-- БИН-->
								<v-col class="v-col" sm="4" md="3">
									<span class="subtitle-2 grey--text mb-2  font-weight-light">
										БИН
									</span>
									<v-text-field
										v-model="data.bin"
										class="border-all"
										v-mask="'############'"
										hide-details
										flat
										solo
										dense
										validate-on-blur
										:rules="rules.bin"
									></v-text-field>
								</v-col>

								<!-- Телефон директора-->
								<v-col class="v-col" sm="4" md="3">
									<span class="subtitle-2 grey--text mb-2  font-weight-light">
										Телефон директора
									</span>
									<v-text-field
										v-model="data.director_phone"
										:placeholder="phoneCode+'-'"
										v-mask="phoneCode == '+7' ? phoneCode+'-###-###-##-##' : phoneCode+'-###-###-####'"
										class="border-all"
										hide-details
										flat
										solo
										dense
										validate-on-blur
										:rules="rules.required"
									></v-text-field>
								</v-col>

								<!-- Email-->
								<v-col class="v-col" sm="4" md="3">
									<span class="subtitle-2 grey--text mb-2  font-weight-light">
										Email
									</span>
									<v-text-field
										v-model="data.director_email"
										class="border-all"
										hide-details
										flat
										solo
										dense
										validate-on-blur
										:rules="rules.required"
									></v-text-field>
								</v-col>

								<!-- Адрес КСК-->
								<v-col class="v-col" sm="4" md="3">
									<span class="subtitle-2 grey--text mb-2  font-weight-light">
										Адрес КСК
									</span>
									<v-text-field
										v-model="data.address_street"
										class="border-all"
										hide-details
										flat
										solo
										dense
										validate-on-blur
										:rules="rules.required"
									></v-text-field>
								</v-col>
								<!-- Тип организации-->
								<v-col v-if="companyType !== 'sc'" class="v-col" sm="4" md="3">
									<span class="subtitle-2 grey--text mb-2  font-weight-light">
										Тип организации
									</span>
									<v-select
										v-model="data.organization_type"
										class="border-all"
										hide-details
										flat
										solo
										dense
										:items="catalog.organization_type"
										item-text="name"
										item-value="id"
										validate-on-blur
										:rules="rules.required"
									></v-select>
								</v-col>
								<!-- Kaspi QR -->
								<v-col cols="12" class="d-flex align-center">
									<Button @click.native="showKaspiModal = true">
										Kaspi QR
									</Button>
								</v-col>
							</v-row>
						</v-row>
					</v-form>
				</v-card-text>

				<v-card-actions class="d-flex justify-end pb-4">
					<Button text @click.native="showLogsModal = true">
						Безопасность
					</Button>
					<Button outlined @click.native="showPasswordModal = true">
						Сменить пароль
					</Button>
					<Button v-if="role !== 'ksk_manager' && role !== 'ksk_dispatcher'" :loading="loading" @click.native="updateUserInfo">
						Сохранить изменения
					</Button>
				</v-card-actions>
			</v-card>
		</v-container>

		<ProfileLogsModal
			v-if="showLogsModal"
			@close-modal="showLogsModal = false"
		/>
		
		<ProfilePasswordModal
			v-if="showPasswordModal"
			@close-modal="showPasswordModal = false"
		/>

		<ProfileKaspiModal
			v-if="showKaspiModal"
			:ksk-id="kskId"
			@close-modal="showKaspiModal = false"
		/>
	</div>
</template>

<script>
import ProfileLogsModal from "@/components/ProfileLogsModal";
import ProfileKaspiModal from "@/components/ProfileKaspiModal";
import ProfilePasswordModal from "@/components/ProfilePasswordModal";
import { mapGetters } from "vuex";

export default {
	name: "Profile",

	components: { ProfilePasswordModal, ProfileKaspiModal, ProfileLogsModal },

	data() {
		return {
			model: '',
			data: null,
			previousData: null,
			item: {
				name: '',
				surname: '',
				patronymic: '',
				_method: 'patch'
			},
			kskData: null,
			loading: false,
			showPasswordModal: false,
			showKaspiModal: false,
			showLogsModal: false,
			rules: {
				bin: [v => (!!v && v.length === 12) || "БИН состоит из 12 цифр"],
				required: [v => !!v || ""],
			},
			avatarLoaded: false,
    	avatarSrc: "@/assets/user.svg"
		};
	},

	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
			organization_types: "ksk/GET_ORGANIZATION_TYPES",
			companyType: "auth/GET_COMPANY_TYPE",
			userData: "auth/GET_INDIVIDUAL_DATA",
			role: "auth/GET_USER_ROLE",
			phoneCode: "auth/GET_USER_PHONE_CODE",
			userId: "auth/GET_USER_ID",
			kskId: "auth/GET_KSK_ID",
		}),
	},

	methods: {
		openFileInput() {
			this.$refs.fileInput.click(); // Кликнуть на элемент input
		},
		async handleFileInputChange(event) {
			const selectedFile = event.target.files[0];
			if (selectedFile) {
				const formData = new FormData();
				formData.append('original_name', selectedFile.name);
				formData.append('file', selectedFile);

				const res = await this.$api.ksk.load_files(formData);
				console.log('file', res)
				if(this.userData.avatar?.id) {
					await this.$api.ksk.delete_files(this.userData.avatar?.id)
				}
				const blobUrl = URL.createObjectURL(selectedFile);
				this.avatarSrc = blobUrl;
				await this.$store.dispatch("auth/update_user_info", {
					data: {
						avatar_id: res.id,
						_method: 'patch'
					},
					userId: this.userId
				});
			}
		},
		async updateUserInfo() {
			try {
				this.loading = true;
				if (this.role === "ksk") {
					const organization_type = this.catalog.organization_type.find(
						e => e.id === this.data.organization_type
					)?._id;
					const data = {
						_method: 'patch'
					};
					if (this.data.bin !== this.previousData.bin) {
						data.bin = this.data.bin;
					}
					if (this.data.organization_type !== this.previousData.organization_type.id) {
						data.organization_type = organization_type;
					}
					if (this.data.director_phone !== this.previousData.director_phone) {
						data.director_phone = this.data.director_phone;
					}
					if (this.data.director_email !== this.previousData.director_email) {
						data.director_email = this.data.director_email;
					}
					if (this.data.address_street !== this.previousData.address_street) {
						data.address_street = this.data.address_street;
					}
					if (this.data.ksk_name !== this.kskData.name) {
						data.name = this.data.ksk_name;
					}
					if (this.data.name !== this.kskData.director_name) {
						data.director_name = this.data.name;
					}
					if (this.data.surname !== this.kskData.director_surname) {
						data.director_surname = this.data.surname;
					}
					if (this.data.patronymic !== this.kskData.director_patronymic) {
						data.director_patronymic = this.data.patronymic;
					}
					res = await this.$api.ksk.edit_ksk(data, this.kskId);
					if (res.error) {
						console.log(res);
						throw { message: res.error };
					}
					this.$store.dispatch("ksk/load_ksk_info");
				}
				this.item.name = this.data.name
				this.item.surname = this.data.surname
				this.item.patronymic = this.data.patronymic
				let res = await this.$store.dispatch(
					"auth/update_user_info",
					{ data: this.item, userId: this.userId }
				);
				this.$root.snackbar.show({
					message: "Изменения сохранены",
					color: "success",
				});
			} catch (error) {
				this.$root.snackbar.show({
					message: error.message,
					color: "error",
				});
			} finally {
				this.loading = false;
			}
		},
		async loadAvatar() {
			try {
				console.log('this.userData.avatar',this.userData.avatar)
				this.avatarSrc = await this.loadServerImage(this.userData.avatar);
				console.log('avatarSrc', this.avatarSrc)
				this.avatarLoaded = true;
			} catch (error) {
				console.error('Ошибка при загрузке аватара:', error);
				// Установить фоллбэк, если есть ошибка
				this.avatarSrc = "@/assets/user.svg";
				this.avatarLoaded = true;
			}
		}
	},

	// beforeCreate() { 
	// 	this.$store.dispatch("ksk/load_organization_types");
	// },

	created() {
		this.loadAvatar()
		this.data = {
			...this.userData,
			...this.$store.getters["ksk/GET_KSK_INFO"],
			...this.$store.getters["auth/GET_INDIVIDUAL_DATA"],
		};
		if (this.role === "ksk") {
			this.$store.dispatch("ksk/load_ksk_info");
			this.kskData = {
			...this.$store.getters["ksk/GET_KSK_INFO"],
			}
			this.data.ksk_name = this.kskData.name
			this.previousData = { ...this.data }
		}
		
	},

	mounted() {
		this.$refs.form.validate();
	},
};
</script>

<style lang="scss" scoped>
.profile {
	height: 90vh;
}
.v-card__text {
	display: grid;
	grid-template-columns: 1fr 7fr;
}
.v-col {
	padding-top: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	& .v-text-field {
		flex-grow: 0;
	}
}
</style>
