<template>
	<v-card
		class="requisitions-item-right-comments list d-flex flex-column align-center flex-sm-grow-1 overflow-y-auto"
		flat
	>
		<!-- Loading spinner -->
		<v-card-text v-if="!loading" class="d-flex justify-center align-center">
			<v-progress-circular indeterminate color="primary"></v-progress-circular>
		</v-card-text>
		<!-- Отображение комментариев -->
		<v-card-text
			class="d-flex flex-column"
			v-else-if="comments.length"
			ref="comments"
		>
			<RequisitionsItemRightCommentsItem
				v-for="comment in comments"
				:comment="comment"
				:user_id="userId"
				:key="comment.id"
				:id="comment.id"
				:ref="comment.id"
				@scroll-to-parent="scrollIntoView"
				@delete-comment="deleteComment($event)"
				@editing-comment="editingComment($event)"
				@reply-comment="replyComment($event)"
			/>
		</v-card-text>
		<!-- Отсутствие комментариев -->
		<v-card-text v-else class="d-flex justify-center align-center">
			Нет комментариев
		</v-card-text>
		<!-- Ввод комментария -->
		<!-- <span>123</span> -->
		<v-alert v-model="editing" class="ma-0 mx-2" icon="mdi-pencil" border="top" dense text dismissible outlined style="width: -webkit-fill-available;">{{textType}}</v-alert>
		<v-alert v-if="pauseAlert" dense outlined type="error" class="ma-0">
			Заявка находится на паузе
		</v-alert>
		<v-card-actions>
			
			<v-text-field
				ref="commentField"
				v-model="commentText"
				class="text-subtitle-2"
				placeholder="Комментарий"
				append-icon="mdi-send"
				flat
				filled
				hide-details
				:loading="isSendingComment"
				@keyup.enter="submitButton"
				@click:append="submitButton"
			></v-text-field>
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapGetters } from "vuex";
import RequisitionsItemRightCommentsItem from "@/components/RequisitionsItemRightCommentsItem";

export default {
	name: "RequisitionsItemRightComments",
	components: {
		RequisitionsItemRightCommentsItem,
	},
	props: {
		comments: {
			type: Array,
			required: true,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		isPause: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			editing: false,
			pauseAlert: false,
			commentText: "",
			parentId: "",
			textType: "Редактирование",
			commentId: null,
			isSendingComment: false,
		};
	},
	watch: {
		'editing'() {
			if(!this.editing) return this.commentText = ''
		},
		'isPause'() {
			if(!this.isPause) {
				this.pauseAlert = false
			}
		}
	},
	computed: {
		...mapGetters({
			userId: "auth/GET_USER_ID",
			kskId: "auth/GET_KSK_ID",
		}),
	},
	methods: {
		scrollIntoView(id) {
			document.getElementById(id).scrollIntoView({
				behavior: "smooth",
			});
		},
		submitButton() {
			if(!this.commentText) return
			if(this.isPause) {
				this.commentText = ''
				return this.pauseAlert = true
			}
			return this.editing && !this.parentId ? this.editComment() : this.sendComment()
		},
		async sendComment() {
			try {
				const data = {
					created_by: "manager",
					comment: this.commentText,
					record_id: this.$route.params.id,
					user_id: this.userId,
					type: "request"
				};
				if(this.parentId && this.editing) data.parent_id = this.parentId
				this.isSendingComment = true;
				await this.$api.ksk.send_request_comment(data);
				const params = {
					selected_ksk_id: this.kskId,
					forget_cache: true
				}
				this.$emit("update-comments", params);

				this.commentText = "";
				this.editing = false
			} catch (error) {
				console.log(error);
			} finally {
				this.isSendingComment = false;
			}
		},
		async deleteComment(id) {
			if(id) {
				try {
					const res = await this.$api.ksk.delete_comment(id);
					this.$root.snackbar.show({message: res.message});
					this.$emit("update-comments");
				} catch (error) {
					this.$root.snackbar.show(error);
				}

			}
		},
		editingComment(comment) {
			this.editing = true
			this.parentId = ''
			this.textType = 'Редактирование'
			this.commentText = comment.comment
			this.commentId = comment.id
			this.$refs.commentField.focus();
		},
		replyComment(comment) {
			this.editing = true
			this.parentId = comment.id
			this.textType = comment.comment
			this.commentText = ''
			this.commentId = comment.id
			this.$refs.commentField.focus();
		},
		async editComment() {
			try {
				const data = {
					created_by: "manager",
					comment: this.commentText,
					record_id: this.$route.params.id,
					user_id: this.userId,
					_method: "patch"
				};
				const res = await this.$api.ksk.edit_comment(data, this.commentId);
				this.$emit("update-comments");
				this.commentText = "";
				this.editing = false
			} catch (error) {
				this.$root.snackbar.show(error);
			}
		}
	},
};
</script>

<style lang="scss" scoped>
.list {
	flex: 1;
	max-height: 700px;
}
.requisitions-item-right-comments {
	height: 100% !important;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex: 1;
	max-height: 80vh;
}
.v-card__text {
	flex-grow: 1;
	// padding: 8px;
	padding: 0px !important;
	overflow-y: scroll;
}
.v-card__actions {
	width: 100%;
}
</style>
