<template>
	<v-dialog v-model="dialog" persistent max-width="900px">
		<v-card>
			<v-card-title
				class="d-flex justify-space-between border-bottom primary pt-2 pb-1"
			>
				<span class="subtitle-1 white--text">Редактирование жителя</span>
				<v-btn color="dark" @click="closeModal(false)" icon>
					<v-icon color="white">mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text>
				<v-container>
					<v-form ref="form" lazy-validation>
						<!-- Персональные данные -->
						<v-row class="border-bottom py-3">
							<v-col
								cols="12"
								class="d-flex justify-space-between align-center"
							>
								<span class="text-body-1 black--text">
									{{ data | fullname }}
								</span>
								<span class="text-body-2">{{ data.phone }}</span>
								<span class="text-body-2">
									{{
										`${data.apartment_address}, кв.${data.apartment_number}`
									}}
								</span>
								<span class="text-body-2" v-show="data.porch_number && data.floor_number">подъезд {{data.porch_number}}, этаж {{data.floor_number}}</span>
							</v-col>
						</v-row>
						<!-- Информация о помещении -->
						<v-row class="border-bottom pb-2">
							<!-- Тип помещения -->
							<v-col md="4" cols="12">
								<span class="subtitle-2 font-weight-light">
									Тип помещения
								</span>
								<v-select
									v-model="data.type_id"
									placeholder="Выберите из списка"
									class="border-all mt-2"
									solo
									dense
									hide-details
									flat
									item-text="name"
									item-value="id"
									:items="catalog.room_types"
									required
									:rules="rules.required"
								>
								</v-select>
							</v-col>
							<!-- № помещения -->
							<v-col md="4" cols="12">
								<span class="subtitle-2 font-weight-light">
									№ помещения
								</span>
								<v-text-field
									v-model="data.apartment_number"
									placeholder="Введите номер"
									class="border-all mt-2"
									solo
									dense
									hide-details
									flat
									required
								></v-text-field>
							</v-col>
							<!-- № Подьезд -->
							<v-col md="4" cols="12">
								<span class="subtitle-2 font-weight-light">
									№ подъезда
								</span>
								<v-text-field
									v-model="data.porch_number"
									placeholder="Введите номер подъезда"
									class="border-all mt-2"
									solo
									dense
									hide-details
									flat
									required
									:rules="rules.required"
								></v-text-field>
							</v-col>
							<!-- № Этаж -->
							<v-col md="4" cols="12">
								<span class="subtitle-2 font-weight-light">
									Этаж
								</span>
								<v-text-field
									v-model="data.floor_number"
									placeholder="Этаж"
									class="border-all mt-2"
									solo
									dense
									hide-details
									flat
									required
									type="number"
									:rules="rules.required"
								></v-text-field>
							</v-col>
							<!-- Статус -->
							<v-col md="4" cols="12">
								<span class="subtitle-2 font-weight-light">
									Статус
								</span>
								<v-select
									v-model="data.affilation_id"
									placeholder="Выберите из списка"
									class="border-all mt-2"
									solo
									dense
									hide-details
									flat
									:items="catalog.apartments_affiliations"
									item-text="name"
									item-value="id"
									required
									:rules="rules.required"
								></v-select>
							</v-col>
							<!-- Тип подтверждения -->
							<v-col md="4" cols="12">
								<span class="subtitle-2 font-weight-light">
									Тип подтверждения
								</span>
								<v-select
									v-model="data.ksk_confirm"
									placeholder="Выберите из списка"
									class="border-all mt-2"
									solo
									dense
									hide-details
									flat
									:items="kskConfirmTypes"
									item-text="text"
									item-value="value"
									required
								></v-select>
							</v-col>
						</v-row>
						<!-- TODO Вторичная информация -->
						<v-row>
							<v-col md="4" cols="12" class="d-flex flex-column">
								<label class="font-weight-regular black--text">
									Дата регистрации
								</label>
								<span class="subtitle-2 font-weight-light">
									{{ data.created_at | timestamp }}
								</span>
							</v-col>
							<v-col md="4" cols="12" class="d-flex flex-column">
								<label class="font-weight-regular black--text">
									Способ регистрации
								</label>
								<span class="subtitle-2 font-weight-light">
									{{
										data.registration_dispatcher ? "Диспетчер" : "Приложение"
									}}
								</span>
							</v-col>
							<v-col md="4" cols="12" class="d-flex flex-column">
								<label class="font-weight-regular black--text">
									Последний вход
								</label>
								<span class="subtitle-2 font-weight-light">
									{{ data.last_entrance | timestamp }}
								</span>
							</v-col>
						</v-row>
						<!-- Alert -->
						<v-row v-if="alert">
							<v-col cols="12">
								<v-alert dense text :type="alert.color">
									{{ alert.message }}
								</v-alert>
							</v-col>
						</v-row>
					</v-form>
				</v-container>
			</v-card-text>

			<v-card-actions class="pt-0 pb-5 d-flex justify-end">
				<v-btn
					class="subtitle-2 text-capitalize"
					color="primary"
					depressed
					:loading="isSaving"
					@click="updateTenantInfo"
				>
					Сохранить
				</v-btn>
			</v-card-actions>
		</v-card>

		<!-- Модальное окно -->
		<ModalWarning
			v-if="showModalWarning"
			:title="'Добавление нового сотрудника'"
			:text="'Вы успешно добавили нового сотрудника'"
			@close-modal="closeModal"
		/>
	</v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

import ModalWarning from "@/components/app/ModalWarning";

import addressFilterMixin from "@/mixins/addressFilterMixin";

export default {
	name: "TenantsTableModal",
	mixins: [addressFilterMixin],
	components: {
		ModalWarning,
	},
	props: {
		dataItem: {
			type: Object,
			required: true,
		},
		dataToEdit: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			alert: null,
			data: {
				affilation_id: "",
				apartment_number: "",
				porch_number: "",
				floor_number: "",
				type_id: "",
			},
			isConfirming: false,
			date: new Date().toISOString().substr(0, 10),
			dialog: true,
			loading: true,
			showModalWarning: false,
			isSaving: false,
			rules: {
				required: [v => !!v || ""],
				phone: [
					v => !!v || "",
					v => (v && v.length === 16) || "Введите корректный формат",
				],
			},
			kskConfirmTypes: [
				{ text: "Не подтвержден", value: false },
				{ text: "Подтвержден", value: true },
			],
		};
	},
	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
			houses: "ksk/GET_HOUSES",
		}),
	},
	methods: {
		async toggleTenantRegistration(item) {
			console.log('REGISTRAT', item);
			try {
				this.isConfirming = this.data.id;
				const res = await this.$api.ksk.confirm_tenant(
					item.owner_id
				);
				console.log(res);
				item.ksk_confirm = !item.ksk_confirm;
			} catch (error) {
				console.error(error);
			} finally {
				this.isConfirming = false;
			}
		},
		closeModal(isConfirmed = true) {
			this.dialog = false;
			this.$emit("close-modal", isConfirmed);
		},

		async updateTenantInfo() {
			this.alert = null;
			if (!this.$refs.form.validate()) {
				return;
			}
			try {
				this.isSaving = true;
				// if(this.isConfirming !== this.data.ksk_confirm) {
				// 	this.toggleTenantRegistration(this.data)
				// }
				console.log(this.data);
				let type = this.catalog.room_types.find(e => e.id === this.data.type_id)
				this.data.type_id = type.id
				const res = await this.$api.ksk.edit_tenant_info({
					id: this.$route.params.id,
					data: this.data,
				});
				console.log(res);
				this.alert = {
					color: "success",
					message: "Изменения сохранены",
				};

				setTimeout(() => this.$emit("close-modal", true), 500);
			} catch (error) {
				this.alert = {
					color: "error",
					message: error.message || "Произошла ошибка. Попробуйте еще раз",
				};
			} finally {
				this.isSaving = false;
			}
		},
	},

	created() {
		console.log(this.catalog);
		// this.data = this.dataToEdit;
		this.data.name = this.dataItem.name || null;
		this.data.id = this.dataItem.id || null;
		this.data.surname = this.dataItem.surname || null;
		this.data.patronymic = this.dataItem.patronymic || null;
		this.data.created_at = this.dataItem.created_at || null;
		this.data.last_entrance = this.dataItem.last_entrance || null;
		this.data.phone = this.dataItem.phone || null;
		this.data.type_id = this.dataToEdit.type?._id || null;
		this.data.owner_id = this.dataToEdit.owner_id || null;
		this.data.owner = this.dataToEdit.owner || null;
		this.data.apartment_number = this.dataToEdit.apartment_number || null;
		this.data.apartment_address = this.dataToEdit.house?.apartment_address || null;
		this.data.affilation_id = this.dataToEdit.owner.affilation.id || null;
		this.data.floor_number = this.dataToEdit.floor_number || null;
		this.data.porch_number = this.dataToEdit.porch_number || null;
		this.data.ksk_confirm = this.dataToEdit.owner.approwed || null;
		this.isConfirming = this.dataToEdit.ksk_confirm || null;
	},
};
</script>

<style lang="scss" scoped>
::v-deep .col {
	padding-bottom: 0 !important;
}
.required::after {
	content: "*";
	position: absolute;
	color: red;
	margin-left: 3px;
}
</style>
