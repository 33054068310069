<template>
	<div class="body-analytic pa-5 d-flex flex-column justify-space-between">
		<span class="subtitle-1 font-weight-bold">Аналитика</span>
		<!-- Список аналитики -->
		<v-list class="analytic-list">
			<!-- Общее кол-во заявок -->
			<v-list-item class="border-all analytic-list__item d-flex">
				<v-list-item-avatar rounded="0">
					<v-icon x-large>mdi-file-document-multiple-outline</v-icon>
				</v-list-item-avatar>
				<v-list-item-content>
					<v-list-item-title class="text-h4">
						{{ 0 }}
					</v-list-item-title>
					<v-list-item-subtitle>
						Общее количество заявок
					</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>

			<!-- Успешные заявки -->
			<v-list-item class="border-all analytic-list__item-success">
				<v-list-item-content class="d-flex flex-column align-start">
					<v-badge
						bordered
						overlap
						bottom
						color="primary"
						icon="mdi-check-circle"
					>
						<v-icon large>mdi-file-document-multiple-outline</v-icon>
					</v-badge>
					<v-list-item-title class="text-h4">
						{{ 0 }}
					</v-list-item-title>
					<v-list-item-subtitle>
						Выполнено заявок
					</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>

			<!-- Неуспешные заявки -->
			<v-list-item class="border-all analytic-list__item-fail">
				<v-list-item-content class="d-flex flex-column align-start">
					<v-badge bordered overlap bottom color="red" icon="mdi-minus-circle">
						<v-icon large>mdi-file-document-multiple-outline</v-icon>
					</v-badge>
					<v-list-item-title class="text-h4">
						{{ 0 }}
					</v-list-item-title>
					<v-list-item-subtitle>
						Отклонено заявок
					</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
		</v-list>
	</div>
</template>

<script>
export default {
	name: "EmployeeItemInfoAnalytic",
};
</script>

<style></style>
