<template>
	<v-dialog v-model="dialog" persistent width="450">
		<v-card>
			<v-card-title
				class="text-subtitle-1 border-bottom d-flex justify-space-between"
			>
				<span>Kaspi QR</span>
				<v-btn icon @click="closeModal">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text class="border-bottom py-5">
				<v-alert v-if="alert" dense :type="alert.color" :timer="2000">
					{{ alert.message }}
				</v-alert>
				<p v-if="!hasQr">
					QR отсутствует. Нажмите на кнопку, чтобы загрузить
				</p>
				<v-img v-else :src="loadServerImage(data.payed_qr, false)"> </v-img>
			</v-card-text>
			<v-card-actions class="d-flex justify-end">
				<v-btn
					class="subtitle-2 text-capitalize"
					color="primary"
					depressed
					:loading="loading"
					@click="onButtonClick"
				>
					<v-icon class="mr-2">mdi-cloud-upload</v-icon>
					{{ buttonText }}
				</v-btn>
				<input
					ref="uploader"
					class="d-none"
					type="file"
					accept="image/*"
					@change="onFileChanged"
				/>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: "ProfileKaspiModal",

	props: {
		kskId: {
			type: String,
			required: true,
		},
	},

	data() {
		return {
			alert: null,
			dialog: true,
			data: null,
			loading: true,
			defaultButtonText: "Загрузить",
			selectedFile: null,
		};
	},

	computed: {
		buttonText() {
			return this.selectedFile
				? this.selectedFile.name
				: this.defaultButtonText;
		},
		hasQr() {
			return this.data !== null && this.data.payed_qr;
		},
	},

	methods: {
		closeModal() {
			this.dialog = false;
			this.$emit("close-modal");
		},

		async loadData() {
			try {
				this.loading = true;
				this.data = await this.$api.ksk.load_ksk_info(this.kskId);
				console.log(this.data);
			} catch (error) {
				this.alert = error;
			} finally {
				this.loading = false;
			}
		},

		onButtonClick() {
			this.loading = true;

			window.addEventListener(
				"focus",
				() => {
					this.loading = false;
				},
				{ once: true }
			);

			this.$refs.uploader.click();
		},

		async onFileChanged(e) {
			this.selectedFile = e.target.files[0];

			const data = { ...this.data, payed_qr: this.selectedFile };
			const formData = data.toFormData();

			try {
				const res = await this.$api.ksk.edit_ksk(formData);
				console.log(res);
				this.alert = { message: "Каспи QR обновлен", color: "success" };
				this.dialog = false;
				this.dialog = true;
				this.loadData();
				this.selectedFile = null;
			} catch (error) {
				this.alert = error;
			}
		},
	},

	created() {
		this.loadData();
	},
};
</script>

<style></style>
