<template>
	<div class="requsitions-table" id="top">
		<v-btn
			v-scroll="onScroll"
			v-show="fab"
			fab
			dark
			fixed
			bottom
			right
			color="primary"
			@click="toTop"
		>
			<v-icon>mdi-arrow-up-thick</v-icon>
		</v-btn>
		<v-data-table
			class="overflow-x elevation-1 infinite-list"
			height="100%"
			item-key="name"
			:item-class="itemClass"
			hide-default-footer
			:headers="headers"
			:items="items"
			:loading="loading"
			:page.sync="options.page"
			:options="options"
			:server-items-length="options.itemsLength"
			@click:row="handleRowClick"
			fixed-header
		>
			
			<!--  ХЭДЕР ТАБЛИЦЫ -->
			<template v-slot:top="{ pagination, options, updateOptions }">
				<v-data-footer
					class="justify-end pa-3 border-bottom"
					:pagination="pagination"
					:options="options"
					@update:options="updateOptions"
					items-per-page-text=""
					:items-per-page-options="[]"
					:disable-pagination="loading"
					:show-first-last-page="true"
				>
					<!-- <template v-slot:page-text>
						{{ options.page || "-" }} из {{ options.pageCount || "-" }}
					</template> -->
					<template v-slot:prepend>
						<div class="d-flex flex-sm-grow-1 align-center justify-start">
							<!-- Кнопка создания -->
							<v-btn
								v-if="base_role !== 'administrator'"
								class="mr-5"
								color="primary"
								@click="showModalCreate = true"
								small
								depressed
							>
								<v-icon class="mr-1" small>mdi-plus</v-icon>
								<span class="body-2 font-weight-light">Создать</span>
							</v-btn>
							<!-- Дата создания -->
							<div class="d-flex align-center mr-3">
								<span class="subtitle-2 grey--text mr-3">от</span>
								<DatePickerPlain
									smallWidth="130"
									:date.sync="filter.created_at_first"
									@change="updateQuery"
								/>
								<span class="mx-3 subtitle-2 grey--text">до</span>
								<DatePickerPlain
									smallWidth="130"
									:date.sync="filter.created_at_second"
									@change="updateQuery"
								/>
							</div>
							<v-menu
								offset-y
								max-width="350"
								:close-on-content-click="false"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-btn class="no-uppercase mr-5 ml-3 text-lowercase" :class="{'filter-active': filterOn}" :disabled="loading" :loading="loading" small outlined v-bind="attrs" v-on="on" color="primary">
										<span class="body-2 font-weight-light no-uppercase">Фильтр &nbsp;</span>
										<v-icon size="20" style="-webkit-text-stroke: 0.15px white;">mdi-filter-outline</v-icon>
									</v-btn>
								</template>
								<v-card class="mt-3">
									<v-list class="pa-4">
										<!-- ФИЛЬТРЫ ТАБЛИЦЫ -->
										<template v-if="role === 'ksk'">
											<v-row>
												<v-col sm="12">
													<span class="subtitle-2 font-weight-light grey--text">Поиск по статусу</span>
													<v-select
														v-model="filter.request_type_id"
														class="border-all"
														:menu-props="{ offsetY: true, maxHeight: 'none' }"
														placeholder="Выберите статус заявки"
														solo
														dense
														flat
														multiple
														hide-details
														item-text="name"
														item-value="id"
														:items="cRequestTypes"
														@change="updateQuery"
													>
														<template v-slot:prepend-item>
															<v-list-item @click="selectAllRequestTypes" ripple>
																	<v-list-item-action>
																		<v-icon color="primary">mdi-check-all</v-icon>
																	</v-list-item-action>
																	<v-list-item-content>
																		<v-list-item-title>
																			<span class="d-flex">
																				Все
																				<span class="ml-auto">{{statsReq.all}}</span>
																			</span>
																		</v-list-item-title>
																	</v-list-item-content>
																</v-list-item>
														</template>
														<template v-slot:item="{ item }">
															<v-icon v-if="isSelectedRequestType(item)">mdi-checkbox-marked</v-icon>
															<span v-else class="checkbox-square"></span>
															<span class="ml-3 ft-14">{{ item.name }}</span>
															<div class="ml-auto text-caption">
																<span v-if="item.sys_name == 'new'">{{statsReq.new}}</span>
																<span v-if="item.sys_name == 'in_work'">{{statsReq.in_work}}</span>
																<span v-if="item.sys_name == 'accepted'">{{statsReq.accepted}}</span>
																<span v-if="item.sys_name == 'rejected'">{{statsReq.rejected}}</span>
																<span v-if="item.sys_name == 'canceled_by_user'">{{statsReq.canceled_by_user}}</span>
																<span v-if="item.sys_name == 'done'">{{statsReq.done}}</span>
																<span v-if="item.sys_name == 'under_consideration'">{{statsReq.under_consideration}}</span>
																<span v-if="item.sys_name == 'overdue'">{{statsReq.overdue}}</span>
																<span v-if="item.sys_name == 'edit'">{{statsReq.edit}}</span>
																<span v-if="item.sys_name == 'not_done'">{{statsReq.not_done}}</span>
															</div>
														</template>
													</v-select>
												</v-col>
												<v-col>
													<span class="subtitle-2 font-weight-light grey--text">Подстатус</span>
													<v-row>
														<v-col class="px-0 ml-5 d-flex justify-center">
															<v-btn
																color="grey"
																class="text-none text-default ft-14"
																outlined
																rounded
																text
																:class="{ 'primary-selected': selectedSubStatus === 'overdue', 'primary--text': selectedSubStatus === 'overdue', 'light': selectedSubStatus !== 'overdue' }"
																@click="toggleSubStatus('overdue')"
																style="width: 140px;"
															>
																Просрочено
															</v-btn>
														</v-col>
														<v-col class="px-0 mr-5 d-flex justify-center">
															<v-btn
																color="grey"
																class="text-none text-default ft-14"
																outlined
																rounded
																text
																:class="{ 'primary-selected': selectedSubStatus === 'notOverdue', 'primary--text': selectedSubStatus === 'notOverdue', 'light': selectedSubStatus !== 'notOverdue' }"
																@click="toggleSubStatus('notOverdue')"
																style="width: 140px;"
															>
																Не просрочено
															</v-btn>
														</v-col>
													</v-row>
												</v-col>
												<v-col sm="12" v-if="base_role == 'ksk_manager' || base_role == 'ksk_dispatcher'">
													<span class="subtitle-2 font-weight-light grey--text">Категория</span>
													<combobox
														v-model="filter.category_id"
														placeholder="Категория"
														itemText="name"
														:items="userCategories"
														@change="updateQuery"
														@select-all="updateFilter('category_id', $event)"
													/>
												</v-col>
												<v-col sm="12" v-else>
													<span class="subtitle-2 font-weight-light grey--text">Категория</span>
													<combobox
														v-model="filter.category_id"
														placeholder="Категория"
														itemText="name"
														:items="categoryList"
														@change="updateQuery"
														@select-all="updateFilter('category_id', $event)"
													/>
												</v-col>
												<v-col sm="12">
													<span class="subtitle-2 font-weight-light grey--text">Поиск по телефону заявителя</span>
													<v-text-field
														v-model="filter.phone"
														class="border-all"
														:placeholder="phoneCode+'-'"
														v-mask="phoneCode == '+7' ? phoneCode+'-###-###-##-##' : phoneCode+'-###-###-####'"
														solo
														dense
														flat
														hide-details
														@change="updateQuery"
													>
													</v-text-field>
												</v-col>
												<v-col sm="12">
													<span class="subtitle-2 font-weight-light grey--text">Поиск по номеру помещения</span>
													<v-text-field
														v-model="filter.apartment_number"
														class="border-all"
														placeholder="Поиск по номеру помещения"
														solo
														dense
														flat
														hide-details
														@change="updateQuery"
													>
													</v-text-field>
												</v-col>
												<!-- <v-col sm="4">
													<span class="subtitle-2 font-weight-light grey--text">Источник обращения</span>
													<v-select
														v-model="filter.created_by"
														class="border-all"
														solo
														dense
														flat
														hide-details
														:items="[
															{ text: 'Все', value: null },
															{ text: 'Приложение', value: 'application' },
															{ text: 'Диспетчер', value: 'phone' },
															{ text: 'Внутренняя', value: 'inner' },
														]"
														@change="updateQuery"
													>
													</v-select>
												</v-col> -->
												<!-- <v-col sm="4">
													<span class="subtitle-2 font-weight-light grey--text">Срок исполнения</span>
													<DatePickerPlain
														:date.sync="filter.employee_period_of_execution"
														:in-table="true"
														@change="updateQuery"
													/>
												</v-col> -->
												<v-col sm="12">
													<span class="subtitle-2 font-weight-light grey--text">Стоимость</span>
													<v-select
														v-model="filter.price"
														class="border-all"
														placeholder="Стоимость"
														:menu-props="{ offsetY: true }"
														solo
														dense
														flat
														hide-details
														:items="[
															{ text: 'Все', value: null },
															{ text: 'Платные', value: 'true' },
															{ text: 'Бесплатные', value: 'false' },
														]"
														@change="updateQuery"
													>
													</v-select>
												</v-col>
											</v-row>
										</template>
										<!-- ФИЛЬТРЫ ДИСПЕТЧЕР -->
										<template v-else>
											<v-row>
												<!-- <v-col sm="12">
													<span class="subtitle-2 font-weight-light grey--text">Поиск по ID</span>
													<v-text-field
														v-model="filter.id"
														class="border-all"
														solo
														dense
														flat
														hide-details
														@change="updateQuery"
													>
													</v-text-field>
												</v-col> -->
												<v-col sm="12">
													<span class="subtitle-2 font-weight-light grey--text">Поиск по статусу</span>
													<v-select
														v-model="filter.request_type_id"
														class="border-all"
														:menu-props="{ offsetY: true, maxHeight: 'none' }"
														placeholder="Выберите статус заявки"
														solo
														dense
														flat
														multiple
														hide-details
														item-text="name"
														item-value="id"
														:items="cRequestTypes"
														@change="updateQuery"
													>
														<template v-slot:prepend-item>
															<v-list-item @click="selectAllRequestTypes" ripple>
																	<v-list-item-action>
																		<v-icon color="primary">mdi-check-all</v-icon>
																	</v-list-item-action>
																	<v-list-item-content>
																		<v-list-item-title>
																			<span class="d-flex">
																				Все
																				<span class="ml-auto" v-if="base_role === 'ksk' || base_role === 'ksk_manager' || base_role === 'ksk_dispatcher'">{{statsReq.all}}</span>
																			</span>
																		</v-list-item-title>
																	</v-list-item-content>
																</v-list-item>
														</template>
														<template v-slot:item="{ item }">
															<v-icon v-if="isSelectedRequestType(item)">mdi-checkbox-marked</v-icon>
															<span v-else class="checkbox-square"></span>
															<span class="ml-3 ft-14">{{ item.name }}</span>
															<div class="ml-auto text-caption" v-if="base_role === 'ksk' || base_role === 'ksk_manager' || base_role === 'ksk_dispatcher'">
																<span v-if="item.sys_name == 'new'">{{statsReq.new}}</span>
																<span v-if="item.sys_name == 'in_work'">{{statsReq.in_work}}</span>
																<span v-if="item.sys_name == 'accepted'">{{statsReq.accepted}}</span>
																<span v-if="item.sys_name == 'rejected'">{{statsReq.rejected}}</span>
																<span v-if="item.sys_name == 'canceled_by_user'">{{statsReq.canceled_by_user}}</span>
																<span v-if="item.sys_name == 'done'">{{statsReq.done}}</span>
																<span v-if="item.sys_name == 'under_consideration'">{{statsReq.under_consideration}}</span>
																<span v-if="item.sys_name == 'overdue'">{{statsReq.overdue}}</span>
																<span v-if="item.sys_name == 'edit'">{{statsReq.edit}}</span>
																<span v-if="item.sys_name == 'not_done'">{{statsReq.not_done}}</span>
															</div>
														</template>
													</v-select>
												</v-col>
												<v-col>
													<span class="subtitle-2 font-weight-light grey--text">Подстатус</span>
													<v-row>
														<v-col class="px-0 ml-5 d-flex justify-center">
															<v-btn
																color="grey"
																class="text-none text-default ft-14"
																outlined
																rounded
																text
																:class="{ 'primary-selected': selectedSubStatus === 'overdue', 'primary--text': selectedSubStatus === 'overdue', 'light': selectedSubStatus !== 'overdue' }"
																@click="toggleSubStatus('overdue')"
																style="width: 140px;"
															>
																Просрочено
															</v-btn>
														</v-col>
														<v-col class="px-0 mr-5 d-flex justify-center">
															<v-btn
																color="grey"
																class="text-none text-default ft-14"
																outlined
																rounded
																text
																:class="{ 'primary-selected': selectedSubStatus === 'notOverdue', 'primary--text': selectedSubStatus === 'notOverdue', 'light': selectedSubStatus !== 'notOverdue' }"
																@click="toggleSubStatus('notOverdue')"
																style="width: 140px;"
															>
																Не просрочено
															</v-btn>
														</v-col>
													</v-row>
												</v-col>
												<v-col sm="12" v-if="base_role === 'ksk_dispatcher'">
													<span class="subtitle-2 font-weight-light grey--text">Категория</span>
													<combobox
														v-model="filterDispatcher.category"
														placeholder="Категория"
														itemText="name"
														:items="categoryList"
														@change="updateQuery"
														@select-all="updateFilter('category', $event)"
													/>
													<!-- <v-select
														v-model="filterDispatcher.category"
														placeholder="Категория"
														class="border-all"
														solo
														multiple
														clearable
														:menu-props="{ offsetY: true }"
														item-text="name"
														item-value="id"
														:items="categoryList"
														@change="updateQuery"
														dense
														hide-details
														flat
													>
													<template v-slot:item="{ item }">
														<v-icon v-if="isSelectedDispatcher(item)">mdi-checkbox-marked</v-icon>
														<span v-else class="d-flex">
															<span class="checkbox-square"></span>
														</span>
														<span class="ml-3">{{ item.name }}</span>
													</template>
													</v-select> -->
												</v-col>
												<!-- <v-col sm="12">
													<span class="subtitle-2 font-weight-light grey--text">УК</span>
													<v-combobox 
														v-if="base_role !== 'ksk_dispatcher'"
														v-model="filter.ksk_id"
														class="text-caption border-all"
														solo
														multiple
														item-text="name"
														item-value="_id"
														:return-object="false"
														:items="kskListConfirm"
														@change="updateQuery"
														dense
														hide-details
														flat
													>
														<template v-slot:selection="{ item, index }">
															<span v-if="index === 0">
																{{ currentKsk(item) }}
															</span>
															<span
																v-if="index === 1"
																class="grey--text text-caption"
															>&nbsp;
																(+ еще {{ filter.ksk_id.length - 1 }})
															</span>
														</template>
													</v-combobox>
												</v-col> -->
												<!-- <v-col sm="12">
													<span class="subtitle-2 font-weight-light grey--text">Адрес</span>
													<v-autocomplete
														v-model="filter.street_search"
														class="border-all"
														solo
														dense
														flat
														hide-details
														clearable
														item-text="apartment_address"
														item-value="_id"
														:items="cHousesDispatcher"
														:filter="addressFilter"
														@change="updateQuery"
													>
													</v-autocomplete>
												</v-col> -->
												<!-- <v-col sm="12">
													<span class="subtitle-2 font-weight-light grey--text">Поиск по телефону заявителя</span>
													<v-text-field
														v-model="filter.phone"
														class="border-all"
														:placeholder="phoneCode+'-'"
														v-mask="phoneCode == '+7' ? phoneCode+'-###-###-##-##' : phoneCode+'-###-###-####'"
														solo
														dense
														flat
														hide-details
														@change="updateQuery"
													>
													</v-text-field>
												</v-col> -->
												<v-col sm="12">
													<span class="subtitle-2 font-weight-light grey--text">Поиск по номеру помещения</span>
													<v-text-field
														v-model="filter.apartment_number"
														placeholder="Поиск по номеру помещения"
														class="border-all"
														solo
														dense
														flat
														hide-details
														@change="updateQuery"
													>
													</v-text-field>
												</v-col>
												<!-- <v-col sm="12">
													<span class="subtitle-2 font-weight-light grey--text">Описание</span>
													<v-text-field
														v-model="filter.description"
														class="border-all"
														solo
														dense
														flat
														hide-details
														@change="updateQuery"
													>
													</v-text-field>
												</v-col> -->
												<!-- <v-col sm="12">
													<span class="subtitle-2 font-weight-light grey--text">Источник обращения</span>
													<v-select
														v-model="filter.created_by"
														class="border-all"
														solo
														dense
														flat
														hide-details
														:items="[
															{ text: 'Все', value: null },
															{ text: 'Приложение', value: 'application' },
															{ text: 'Телефон', value: 'phone' },
															{ text: 'Внутренняя', value: 'inner' },
														]"
														@change="updateQuery"
													>
													</v-select>
												</v-col> -->
												<v-col sm="12">
													<!-- <span class="subtitle-2 font-weight-light grey--text">Закрыта</span>
													<v-select
														v-model="filter.is_closed"
														class="border-all"
														solo
														dense
														flat
														hide-details
														:items="[
															{ text: 'Все', value: null },
															{ text: 'Да', value: '1' },
															{ text: 'Нет', value: '0' },
														]"
														@change="updateQuery"
													>
													</v-select> -->
													<span class="subtitle-2 font-weight-light grey--text">Стоимость</span>
													<v-select
														v-model="filter.price"
														class="border-all"
														placeholder="Стоимость"
														:menu-props="{ offsetY: true }"
														solo
														dense
														flat
														hide-details
														:items="[
															{ text: 'Все', value: null },
															{ text: 'Платные', value: 'true' },
															{ text: 'Бесплатные', value: 'false' },
														]"
														@change="updateQuery"
													>
													</v-select>
												</v-col>
										</v-row>
									</template>
										
									</v-list>
								</v-card>
							</v-menu>
							<!-- Сброс фильтров -->
							<v-btn
								class="mr-5 text-lowercase"
								color="primary"
								small
								outlined
								@click="resetFilters"
							>
								<span class="body-2 font-weight-light">
									<span class="text-capitalize">Сбросить</span> фильтры
								</span>
							</v-btn>
							<v-tooltip bottom>
								<template v-slot:activator="{ on }">
									<v-btn
										class="mr-5 text-lowercase"
										color="primary"
										small
										outlined
										@click="updateData"
										v-on="on"
									>
										<v-icon>mdi-refresh</v-icon>
									</v-btn>
								</template>
								<span>Обновить</span>
							</v-tooltip>
							<div>
								<v-btn
									color="primary"
									small
									depressed
									:loading="isExportingExcel"
									@click="exportExcel"
								>
									<v-icon class="mr-1" small>mdi-microsoft-excel</v-icon>
									<span class="body-2 font-weight-light text-capitalize">
										Экспорт
									</span>
								</v-btn>
							</div>
						</div>
							<!-- ПАГИНАЦИЯ -->
							<div class="d-flex align-center">
								<span style="margin: 0 10px 0 24px;">{{currentPage * 10 - options.itemsPerPage}}-{{ currentPage * 10 }} из {{ options.itemsLength }}</span>
								
								<v-btn icon class="elevation-0" :disabled="currentPage == 1" @click="options.page = 1; buttonPagination = true">
									<v-icon>mdi-page-first</v-icon>
								</v-btn>
								<v-btn icon class="elevation-0" :disabled="currentPage == 1" @click="options.page--; buttonPagination = true">
									<v-icon>mdi-chevron-left</v-icon>
								</v-btn>
								<v-btn icon class="elevation-0" :disabled="currentPage * 10 == options.itemsLength" @click="options.page = currentPage + 1; buttonPagination = true">
									<v-icon>mdi-chevron-right</v-icon>
								</v-btn>
								<!-- <v-pagination
									v-model="options.page"
									:length="options.pageCount"
									next-icon='mdi-chevron-right'
									prev-icon='mdi-chevron-left'
									:total-visible="0"
							></v-pagination> -->
							<v-btn icon class="elevation-0" :disabled="currentPage * 10 == options.itemsLength" @click="options.page = options.itemsLength / 10; buttonPagination = true">
								<v-icon>mdi-page-last</v-icon>
							</v-btn>
							
							</div>
					</template>
				</v-data-footer>
			</template>
			<template v-slot:[`header.id`]>
				<span class="black--text">ID заявки</span><br>
				<span>Статус</span>
			</template>
			<template v-if="role === 'dispatcher'" v-slot:[`header.ksk_id`]>
				<span class="black--text">УК</span><br>
			</template>
			<template v-slot:[`header.category_id`]>
				<span class="black--text">Категория</span><br>
				<span>Исполнитель</span>
			</template>
			<template v-slot:[`header.street_search`]>
				<span class="black--text">Адрес</span>
			</template>
			<template v-if="role === 'ksk_dispatcher'" v-slot:[`header.phone`]>
				<span class="black--text">Тел. заявителя</span>
			</template>
			<template v-slot:[`header.description`]>
				<span class="black--text">Описание</span>
			</template>
			<template v-slot:[`header.created_at`]>
				<span class="black--text">Дата</span><br>
				<span>Срок исполнения</span>
			</template>
			<template v-slot:[`header.dispatcher_created`]>
				<span class="black--text">Источник обращения</span><br>
				<span>Оценка</span>
			</template>
			<!-- ФИЛЬТРЫ ТАБЛИЦЫ -->
			<template v-if="role === 'ksk'" v-slot:[`body.prepend`]>
				<tr>
					<td>
						<v-text-field
							v-model="filter.id"
							class="border-all"
							solo
							dense
							flat
							placeholder="ID заявки"
							type="number"
							hide-details
							@change="updateQuery"
						>
						</v-text-field>
					</td>
					<td>
						<combobox
							v-model="filter.employee_executor"
							placeholder="Исполнитель"
							type="employee"
							item-value="id"
							item-text="name"
							:get-selection-text="currentEmployee"
							:items="employee"
							:disabled="loading"
							:filter="addressFilter"
							@change="updateQuery"
							@select-all="updateFilter('employee_executor', $event)"
							dense
							hide-details
							flat
						/>
						<!-- <v-combobox
							v-model="filter.employee_executor"
							placeholder="Исполнитель"
							class="text-caption border-all"
							solo
							multiple
							clearable
							:item-text="item => getFullname(item)"
							item-value="id"
							:return-object="false"
							:items="employee"
							:disabled="loading"
							:filter="addressFilter"
							@change="updateQuery"
							dense
							hide-details
							flat
						>
							<template v-slot:selection="{ item, index }">
								<span v-if="index === 0 && item.length >= 1">
									{{ currentEmployee(item) }}
								</span>
								<span
									v-if="index === 1"
									class="grey--text text-caption"
								>&nbsp;
									(+ еще {{ filter.employee_executor.length - 1 }})
								</span>
							</template>
						</v-combobox> -->
						<!-- <v-autocomplete
							v-model="filter.employee_executor"
							class="border-all"
							placeholder="Исполнитель"
							solo
							dense
							flat
							clearable
							hide-details
							:disabled="loading"
							:item-text="item => getFullname(item)"
							item-value="id"
							:items="employee"
							:filter="addressFilter"
							@change="updateQuery"
						>
						</v-autocomplete> -->
					</td>
					<td>
						<combobox
							v-model="filter.street_search"
							placeholder="Адрес"
							:items="houses"
							@change="updateQuery"
							@select-all="updateFilter('street_search', $event)"
						/>
					</td>
					<td>
						<v-text-field
							v-model="filter.description"
							class="border-all"
							placeholder="Описание"
							solo
							dense
							flat
							hide-details
							@change="updateQuery"
						>
						</v-text-field>
					</td>
					<td>
						<DatePickerPlain
							:date.sync="filter.employee_period_of_execution"
							:in-table="true"
							:range="true"
							@change="updateQuery"
						/>
					</td>
					<td>
						<v-select
							v-model="filter.created_by"
							class="border-all"
							placeholder="Источник обращения"
							:menu-props="{ offsetY: true }"
							solo
							dense
							flat
							hide-details
							:items="[
								{ text: 'Все', value: null },
								{ text: 'Приложение', value: 'user' },
								{ text: 'Диспетчер', value: 'dispatcher' },
								{ text: 'Внутренняя', value: 'inner' },
								{ text: 'Телефон', value: 'phone' },
							]"
							@change="updateQuery"
						>
						</v-select>
					</td>
				</tr>
			</template>
			<!-- ФИЛЬТРЫ ТАБЛИЦЫ ДИСПЕТЧЕРА -->
			<template v-else v-slot:[`body.prepend`]>
				<tr>
					<td>
						<v-text-field
							v-model="filter.id"
							class="border-all"
							solo
							dense
							flat
							hide-details
							placeholder="ID заявки"
							type="number"
							@change="updateQuery"
						>
						</v-text-field>
					</td>
					<td v-if="base_role !== 'administrator' && base_role !== 'ksk_dispatcher'">
						<v-combobox
							v-model="filter.ksk_id"
							placeholder="Поиск по УК"
							class="text-caption border-all"
							solo
							multiple
							item-text="name"
							item-value="_id"
							:return-object="false"
							:items="kskListConfirm"
							@change="updateQuery"
							dense
							hide-details
							flat
						>
						<template v-slot:item="{ item }">
							<v-icon v-if="isSelectedKsk(item)">mdi-checkbox-marked</v-icon>
							<span v-else class="d-flex">
								<span class="checkbox-square"></span>
							</span>
							<span class="ml-3">{{ item.name }}</span>
						</template>
						<template v-slot:selection="{ item, index }">
							<span v-if="index === 0">
								{{ currentKsk(item) }}
							</span>
							<span v-if="index === 1" class="grey--text text-caption">&nbsp;
								(+ еще {{ filter.ksk_id.length - 1 }})
							</span>
						</template>
							<!-- <template v-slot:selection="{ item, index }">
								<span v-if="index === 0">
									{{ currentKsk(item) }}
								</span>
								<span
									v-if="index === 1"
									class="grey--text text-caption"
								>&nbsp;
									(+ еще {{ filter.ksk_id.length - 1 }})
								</span>
							</template> -->
						</v-combobox>

					</td>
					<td>
						<combobox
							v-if="base_role !== 'dispatcher'"
							v-model="filter.employee_executor"
							placeholder="Исполнитель"
							type="employee"
							item-value="id"
							:get-selection-text="currentEmployee"
							:items="employee"
							:disabled="loading"
							:filter="addressFilter"
							@change="updateQuery"
							@select-all="updateFilter('employee_executor', $event)"
							dense
							hide-details
							flat
						/>
						<!-- <v-combobox
							v-model="filterDispatcher.employee_executor"
							placeholder="Исполнитель"
							class="text-caption border-all"
							solo
							multiple
							clearable
							:item-text="item => getFullname(item)"
							item-value="id"
							:return-object="false"
							:items="employee"
							:disabled="loading"
							:filter="addressFilter"
							@change="updateQuery"
							dense
							hide-details
							flat
						>
							<template v-slot:selection="{ item, index }">
								<span v-if="index === 0 && item.length >= 1">
									{{ currentEmployee(item) }}
								</span>
								<span
									v-if="index === 1"
									class="grey--text text-caption"
								>&nbsp;
									(+ еще {{ filter.employee_executor.length - 1 }})
								</span>
							</template>
						</v-combobox> -->
						<v-select
							v-else
							v-model="filterDispatcher.category"
							placeholder="Категория"
							class="border-all"
							solo
							multiple
							:menu-props="{ offsetY: true }"
							clearable
							item-text="name"
							item-value="id"
							:items="categoryList"
							@change="updateQuery"
							dense
							hide-details
							flat
						>
						<template v-slot:item="{ item }">
							<v-icon v-if="isSelectedDispatcher(item)">mdi-checkbox-marked</v-icon>
							<span v-else class="d-flex">
								<span class="checkbox-square"></span>
							</span>
							<span class="ml-3">{{ item.name }}</span>
						</template>
						</v-select>
					</td>
					<td>
						<combobox
							v-model="filter.street_search"
							placeholder="Адрес"
							:items="houses"
							@change="updateQuery"
							@select-all="updateFilter('street_search', $event)"
						/>
					</td>
					<td v-if="base_role !== 'administrator'">
						<v-text-field
							v-model="filter.phone"
							class="border-all"
							:placeholder="phoneCode+'-'"
							v-mask="phoneCode == '+7' ? phoneCode+'-###-###-##-##' : phoneCode+'-###-###-####'"
							solo
							dense
							flat
							hide-details
							@change="updateQuery"
						>
						</v-text-field>
					</td>
					<td>
						<v-text-field
							v-model="filter.description"
							placeholder="Описание"
							class="border-all"
							solo
							dense
							flat
							hide-details
							@change="updateQuery"
						>
						</v-text-field>
					</td>
					<td>
						<DatePickerPlain
							:date.sync="filter.employee_period_of_execution"
							:in-table="true"
							:range="true"
							@change="updateQuery"
						/>
					</td>
					<td>
						<v-select
							v-model="filter.created_by"
							class="border-all"
							placeholder="Источник обращения"
							:menu-props="{ offsetY: true }"
							solo
							dense
							flat
							hide-details
							:items="[
								{ text: 'Все', value: null },
								{ text: 'Приложение', value: 'user' },
								{ text: 'Диспетчер', value: 'dispatcher' },
								{ text: 'Внутренняя', value: 'inner' },
								{ text: 'Телефон', value: 'phone' },
							]"
							@change="updateQuery"
						>
						</v-select>
					</td>
				</tr>
			</template>
			
			<!-- ПОЛЯ ТАБЛИЦЫ -->
			<template v-slot:[`item.id`]="{ item }">
				<td>
					<p class="my-0 mb-1 mt-1 ml-24" :class="{ 'font-weight-bold': !item.is_read }">
						{{ item.pretty_id }}
					</p>
					<span>
						<v-icon :class="{'custom-red': item.is_overdue}" :color="item.is_overdue ? '' : getStatusColor(item.request_type.sys_name)">mdi-circle-medium</v-icon>
						<span class="grey-text" :class="{ 'font-weight-bold': !item.is_read }">{{ item.request_type.name.charAt(0).toUpperCase() + item.request_type.name.slice(1) }} {{item.is_overdue ? "- просрочено" : ''}}{{ item.is_resumed ? '*' : '' }}</span>

					</span>
					<p class="ml-24 grey-color">
						{{ item.is_inner ? 'Внутренняя' : (item.is_public ? 'Публичная' : 'Индивидуальная') }}
					</p>
					
				</td>
			</template>
			<template v-slot:[`item.category_id`]="{ item }">
				<td :class="{ 'font-weight-bold': !item.is_read }">
					<span>{{ item.request_category.name }}</span>
					<br>
					<span v-if="item.request_type.sys_name === 'accepted'">
						<span v-if="item.employee_workers.length > 1">{{item.employee_workers[0] | fullname}}...</span>
						<span v-else>{{item.employee_workers[0] | fullname}}</span>
					</span>
					<span v-else>
						{{ item.employee_worker_executor | fullname }}
						</span>
				</td>
			</template>
			<template v-slot:[`item.street_search`]="{ item }">
				<td :class="{ 'font-weight-bold': !item.is_read }">
					<span>
						{{ item.house ? item.house.address : "---" }}
						<br v-if="item.house">
						<span class="ma-0" v-if="item.room">
							{{item.room.type ? item.room.type.name : ''}}
							{{item.room.apartment_number}}
						</span>
					</span><br>
					<span v-if="role === 'ksk' && !item.is_inner">{{ item.user ? item.user.phone || "---" : "---" }}</span>
					<span v-if="item.is_inner">{{item.premise ? item.premise.name : '---'}}</span>
				</td>
			</template>
			<template v-slot:[`item.description`]="{ item }">
				<td :class="{ 'font-weight-bold': !item.is_read }">
					{{ item.description | textLimit }}
				</td>
			</template>
			<template v-slot:[`item.created_at`]="{ item }">
				<td :class="{ 'font-weight-bold': !item.is_read }">
					<span>
						Создана: {{ item.created_at | timestamp(true, { hideTime: true }) }}
					</span><br>
					<span class="grey--text">
						Срок: {{ item.period_execution | timestamp(true, { hideTime: true }) }}
					</span><br>
					<span v-if="item.done_time">
						Закрыто: {{ item.done_time | timestamp(true, { hideTime: true }) }}
					</span>
				</td>
			</template>
			<!-- v-if="role === 'dispatcher'" -->
			<template v-slot:[`item.dispatcher_created`]="{ item }">
				<td :class="{ 'font-weight-bold': !item.is_read }">
					<div class="text-center">
						<span v-if="item.created_by === 'company' && item.is_inner">
							Внутренняя
						</span>
						<span v-else-if="item.created_by === 'company' && !item.is_inner">
							Телефон
						</span>
						<span v-else-if="item.created_by === 'user'">
							Приложение
						</span>
						<span v-else-if="item.created_by === 'dispatcher'">
							Диспетчер
						</span>
						<br>
						<v-badge
							v-if="item.unread_comment"
							class="mr-2"
							color="red"
							dot
							overlap
							bordered
						>
							<v-icon color="primary" style="-webkit-text-stroke: 0.2px white;">mdi-chat-outline</v-icon>
						</v-badge>
						<v-icon v-else-if="checkFinishRequests(item.request_type.sys_name)" color="primary" style="-webkit-text-stroke: 0.2px white;">mdi-chat-outline</v-icon>
						<v-rating
							v-if="item.user_rating"
							v-model="item.user_rating"
							background-color="grey lighten-1"
							color="orange lighten-1"
							dense
							class="ma-0 pa-0"
							readonly
							length="5"
							size="15"
						></v-rating>
					</div>
				</td>
			</template>
			<template v-slot:[`item.phone`]="{ item }">
				<span :class="{ 'font-weight-bold': !item.is_read }">{{ item.user ? item.user.phone || "---" : "---" }}</span>
			</template>
			<template v-slot:[`item.dispatcher_created_at`]="{ item }">
				<td :class="{ 'font-weight-bold': !item.is_read }">
					<span>
						Создана: {{ item.created_at | timestamp(true) }}
					</span><br>
					<span class="grey--text">
						Срок: {{ item.period_execution | timestamp(true, { hideTime: true }) }}
					</span><br>
					<span v-if="item.done_time">
						Закрыто: {{ item.done_time | timestamp(true) }}
					</span>
				</td>
			</template>
			<template v-slot:[`item.price`]="{ item }">
				<td :class="{ 'font-weight-bold': !item.is_read }">
					{{
						!item.is_price ? "Бесплатная" : item.price ? item.price : "Платная"
					}}
				</td>
			</template>
			<!-- Некоторые записи не содержат поля apratment -->
			<template v-slot:[`item.apartment_number`]="{ item }">
				<td :class="{ 'font-weight-bold': !item.is_read }">
					{{ item.apartment ? item.apartment.apartment_number : "---" }}
				</td>
			</template>
			<template #foot>
        <div v-intersect="endIntersect" />
      </template>
		</v-data-table>
		<!-- ОКНО СОЗДАНИЯ ЗАЯВКИ -->
		<RequsitionsTableModal
			v-if="showModalCreate"
			@update-info="updateQuery"
			@close-modal="showModalCreate = false"
		/>
		<div v-if="scrolling" class="text-center ma-2">
			<v-progress-circular
				:size="40"
				:width="7"
				color="secondary"
				indeterminate
			></v-progress-circular>
		</div>
		<div v-show="buttonScroll" class="text-center ma-2">
			<v-btn color="primary" class="mb-5" @click="getMore(); buttonPagination = true">Просмотреть далее</v-btn>
		</div>
		<notification-modal @close-modal="updateData"></notification-modal>
	</div>
</template>

<script>
import { timestampToDdmmyyyy } from "@/helpers/helpers";
import { mapGetters } from "vuex";
import cookies from "@/helpers/cookies";
import Combobox from '@/components/app/Combobox.vue';
import NotificationModal from "@/components/app/NotificationsModal";
import DatePickerPlain from "@/components/app/DatePickerPlain";
import RequsitionsTableModal from "@/components/RequsitionsTableModal";
import QrTableBarrierVue from './QrTableBarrier.vue';

export default {
	name: "RequisitionsTable",

	components: {
		Combobox,
		DatePickerPlain,
		NotificationModal,
		RequsitionsTableModal,
	},

	watch: {
		options: {
			handler() {
				if(!this.scrolling && this.firstFetch) {

					if(this.options.page == 0) {
						this.options.page = 1
					}
					const query = { ...this.$route.query, page: this.options.page, paginate: true };
					this.$router.replace({ query });
					// console.log('check options.page current', this.options.page === this.currentPage)
					// console.log('currentPage!!!!', this.currentPage)
					// console.log('options.page!!!!', this.options.page)
					if(this.options.page !== this.currentPage) {
						this.loadData(query);
					}
					this.$store.dispatch('dispatcher/load_page', this.options.page)
				}
			},
			deep: true,
		},
		'currentPage'() {
			if(this.currentPage == this.options.pageCount) {
				this.buttonScroll = false
			}
		},
		'filter.ksk_id'() {
			console.log('change ksk_id', this.filter.ksk_id)
			this.$store.dispatch('dispatcher/load_ksk_ids', this.filter.ksk_id)
		},
	},

	data() {
		return {
			items: [],
			itemsData: [],
			fab: false,
			firstFetch: false,
			buttonScroll: false,
			scrolling: false,
			scrolled: false,
			filterOn: false,
			buttonPagination: true,
			selectedSubStatus: null,
			currentPage: 1,
			interval: null,
			enableScroll: false,
			itemClass: 'item-class',
			filter: {
				apartment_number: this.$route.query.apartment_number || "",
				category_id: this.$route.query.category_id || [],
				created_at: this.$route.query.created_at || "",
				created_at_first:
					this.$route.query.created_at_first || "",
				created_at_second:
					this.$route.query.created_at_second || "",
				description: this.$route.query.description || "",
				employee_executor: this.$route.query.employee_workers_ids || "",
				employee_period_of_execution:
					this.$route.query.employee_period_of_execution || [],
				id: this.$route.query.pretty_id || "",
				phone: this.$route.query.user_phone || "",
				premise_id: this.$route.query.premise_id || "",
				price: this.$route.query.is_price || "",
				request_type_id: this.$route.query.request_type || [],
				street_search: this.$route.query.houses_ids || [],
				created_by: this.$route.params.created_by || "",
				ksk_id: this.$route.query.company_ids || [],
				was_overdue: this.$route.query.was_overdue || null
			},
			filtersQuery: {
				"apartment_number": "apartment_number",
				"category_id": "category_ids",
				"created_at": "created_at",
				"created_at_first": "created_at_first",
				"created_at_second": "created_at_second",
				"description": "description",
				"employee_executor": "employee_workers_ids",
				"employee_period_of_execution": "employee_period_of_execution",
				"id": "pretty_id",
				"ksk": "ksk_id_search",
				"ksk_id" : "ksk_id_search",
				"phone": "user_phone",
				"premise_id": "premise_id",
				"price": "is_price",
				"request_type_id": "requests_types",
				"street_search": "houses_ids",
				"created_by": "created_by",
				"was_overdue": "was_overdue",
			},
			headers: [
				{
					text: 'ID заявки | статус',
					value: "id",
					width: "170px",
					sortable: false,
				},
				{
					text: "Категория | Исполнитель",
					value: "category_id",
					width: "200px",
					sortable: false,
				},				
				{
					text: "Адрес | Телефон заявителя",
					value: "street_search",
					width: "250px",
					sortable: false,
				},
				{
					text: "Описание",
					value: "description",
					width: "180px",
					sortable: false,
				},
				{
					text: "Срок исполнения",
					value: "created_at",
					width: "200px",
					sortable: false,
				},
				{
					text: "Источник обращения | Оценка",
					value: "dispatcher_created",
					width: "180px",
					sortable: false,
				},
			],
			// Dispatcher
			filterDispatcher: {
				id: this.$route.query.pretty_id || "",
				employee_executor: this.$route.query.employee_workers_ids || "",
				created_at_first:
					this.$route.query.created_at_first || "",
				created_at_second:
					this.$route.query.created_at_second || "",
				employee_period_of_execution:
					this.$route.query.employee_period_of_execution || [],
				status: this.$route.params.status || "",
				category: this.$route.query.category_ids || [],
				ksk_id: this.$route.query.company_ids || [],
				price: this.$route.query.is_price || "",
				street_search: this.$route.query.houses_ids || [],
				apartment_number: this.$route.query.apartment_number || "",
				phone: this.$route.query.user_phone || "",
				description: this.$route.query.description || "",
				is_closed: this.$route.params.is_closed || "",
				request_type_id: this.$route.query.request_type || [],
				created_by: this.$route.query.created_by || "",
				was_overdue: this.$route.query.was_overdue || null
			},
			filtersQueryDispatcher: {
				"id": "pretty_id",
				"employee_executor": "employee_workers_ids",
				"created_at_first": "created_at_first",
				"created_at_second": "created_at_second",
				"employee_period_of_execution": "employee_period_of_execution",
				"status": "status",
				"request_type_id": "requests_types",
				"category": "category_ids",
				"ksk_id": "company_ids",
				"street_search": "houses_ids",
				"apartment_number": "apartment_number",
				"phone": "user_phone",
				"price": "is_price",
				"description": "description",
				"is_closed": "is_closed",
				"created_by": "created_by",
				"was_overdue": "was_overdue",
			},
			headersDispatcher: [
				{
					text: "№ ID",
					value: "id",
					width: "170px",
					sortable: false,
				},
				{
					text: "УК",
					value: "company.name",
					width: "200px",
					sortable: false,
				},
				{
					text: "Категория",
					value: "category_id",
					width: "200px",
					sortable: false,
				},
				{
					text: "Адрес",
					value: "street_search",
					width: "250px",
					sortable: false,
				},
				{
					text: "Телефон",
					value: "phone",
					width: "180px",
					sortable: false,
				},
				{
					text: "Описание",
					value: "description",
					width: "200px",
					sortable: false,
				},
				{
					text: "Срок исполнения",
					value: "dispatcher_created_at",
					width: "150px",
					sortable: false,
				},
				{
					text: "Источник обращения",
					value: "dispatcher_created",
					width: "180px",
					sortable: false,
				},
			],
			optionsOldPage: 1,
			options: {
				page: +this.$route.query.page || 1,
				itemsPerPage: 10,
				pageStart: 1,
				pageCount: 0,
				itemsLength: 0,
			},
			paginations: {
				page: +this.$route.query.page || 1,
				itemsPerPage: 10,
				pageStart: 1,
				pageStop: 10,
				pageCount: 0,
				itemsLength: 0,
			},
			isExportingExcel: false,
			loading: true,
			showModalCreate: false,
			wsConnection: null,
			wsConnectionDispatcher: null,
		};
	},

	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
			employee: "ksk/GET_EMPLOYEE",
			houses: "ksk/GET_HOUSES",
			// housesDispatcher: "dispatcher/GET_HOUSES",
			base_role: "auth/GET_USER_ROLE",
			role: "auth/GET_USER_ROLE_VUEX",
			kskListConfirm: "catalog/GET_KSK_LIST_CONFIRM",
			userId: "auth/GET_USER_ID",
			kskId: "auth/GET_KSK_ID",
			companyType: "auth/GET_COMPANY_TYPE",
			companyIds: "auth/GET_COMPANY_IDS",
			ksk_id_search: "dispatcher/GET_KSK_IDS",
			page_store: "dispatcher/GET_PAGE",
			statsReq: "ksk/GET_STATISTIC",
			phoneCode: "auth/GET_USER_PHONE_CODE",
			userHouses: "auth/GET_HOUSES_IDS",
			userCategories: "auth/GET_USER_CATEGORIES",
		}),
		// cHousesDispatcher() {
		// 	return this.base_role === "ksk_dispatcher"
		// 		? this.houses
		// 		: this.housesDispatcher;
		// },
		cRequestTypes() {
			const types = this.catalog.request_types.filter(
				e =>
					!e.name.includes("риостановлена") && !e.name.includes("тредактирован") && !e.name.includes("на рассмотрении") && !e.name.includes("редактирование") && !e.name.includes("просрочено")
			);
			return [...types];
		},
		categoryList() {
			if(this.companyType === 'sc') {
				return this.userCategories
			} else {
				return this.catalog.request_categories
			}
		},
	},

	methods: {
		toggleSubStatus(status) {
			if (this.selectedSubStatus === status) {
				this.selectedSubStatus = null;
				this.filter.was_overdue = null
			} else {
				console.log('selectedSubStatus', this.selectedSubStatus)
				this.selectedSubStatus = status;
				if(this.selectedSubStatus === 'overdue') {
					this.filter.was_overdue = true
				} else if(this.selectedSubStatus === 'notOverdue') {
					console.log('selectedSubStatus 2', this.selectedSubStatus)
					this.filter.was_overdue = 'false'
				}
			}
			this.updateQuery()
		},
		updateFilter(filterName, value) {
      this.filter[filterName] = value;
			this.updateQuery()
    },
		isSelectedCategory(item) {
			return this.filter.category_id.includes(item.id);
		},
		isSelected(item) {
			return this.filter.category_id.includes(item.id);
		},
		isSelectedRequestType(item) {
			return this.filter.request_type_id.includes(item.id);
		},
		isSelectedDispatcher(item) {
			return this.filterDispatcher.category.includes(item.id);
		},
		isSelectedKsk(item) {
			return Array.isArray(this.filter.ksk_id) && this.filter.ksk_id.includes(item._id);
		},
		selectAllRequestTypes() {
			if(this.filter.request_type_id.length === this.cRequestTypes.length) {
				this.filter.request_type_id = [];
			} else {
				this.filter.request_type_id = this.cRequestTypes.map(item => item.id);
			}
			this.updateQuery()
		},
		getApartment(item) {
			if(item.house) {
				const address = item.house.address
				let apt;
				// const place = this.catalog.room_types.filter(e => e._id === item.apartment.type_id)
				const apartment = item?.room?.apartment_number || "";
				// if(item.apartment.type_id && apartment) {
				// 	if(place[0].name == 'Нежилое помещение') {
				// 		apt = ', помещение'
				// 	} else if(place[0].sys_name == 'parking-space') {
				// 		apt = ', парковочное место'
				// 	} else {
				// 		apt = ', кв.'
				// 	}
				// } else {
				// 	apt = ''
				// }
				
				// return `${address}${apt} ${apartment}`;
				return `${address} ${apartment}`;
			} else {
				return '---'
			}
		},
		getMore() {
			this.toTop()
			this.options.page = this.currentPage + 1; 
		},
		endIntersect(entries, observer, isIntersecting) {
			console.log('enableScroll', this.enableScroll)
      if (isIntersecting && this.enableScroll) {
				if(!this.scrolling && this.options.itemsPerPage < 60) {
					this.scrolling = true
					this.$route.query.page++;
					this.options.itemsPerPage += 10;
					console.log('MORE DATA', this.$route.query.page)
					const query = { ...this.$route.query };
					this.loadData(query);
					this.buttonScroll = false
					this.scrolled = true
				} else {
					console.log('this.options.itemsPerPage', this.options.itemsPerPage)
					this.buttonScroll = true
				}
      }
    },
		updateData() {
			const query = Object.entries(this.filter).reduce((acc, [key, val]) => {
				if (!val) return acc;
				return { ...acc, [this.filtersQuery[key]]: val };
			}, {});
			if(this.page_store) {
				this.options.page = this.page_store
			} else {
				this.options.page = 1
			}
			if(query.hasOwnProperty('request_type') || query.hasOwnProperty('category_ids') || query.hasOwnProperty('user_phone') || query.hasOwnProperty('apartment_number') || query.hasOwnProperty('is_price')) {
				this.filterOn = true
			} else {
				this.filterOn = false
			}
			this.buttonPagination = true
			this.$router.replace({ query: { ...query, page: this.options.page, paginate: true, sort_by: 'created', sort_type: 'DESC' } });
			let params = { ...query, page: this.options.page, paginate: true, sort_by: 'created', sort_type: 'DESC', forget_cache: true }
			this.loadData(params);
		},
		async loadData(params, offLoading) {
			console.log('params', params)
			setTimeout(() => {
				this.getStatistics()
			}, 100);
			if(!params.paginate) return
			if(!offLoading) {
				this.loading = true;
			}
			const role = this.role === "dispatcher" ? this.role : "ksk";
			if(this.companyType === 'sc') {
				params.sc_ids = [this.kskId]
				params.is_sc = true
			} else {
				if(this.base_role !== 'administrator' && this.base_role !== 'dispatcher') {
					params.company_ids = [this.kskId]
					params.selected_ksk_id = this.kskId
				}
				if(this.base_role == 'dispatcher') {
					if (!params.company_ids || params.company_ids.length === 0) {
						params.company_ids = this.kskListConfirm.map(item => item.id);
					}
				}
			}
			if(this.base_role == 'ksk_manager' || this.base_role == 'ksk_dispatcher' || this.companyType === 'sc') {
				if(!this.filter.street_search.length) {
					const houseDataArray = this.userHouses.map(item => item.house);
					params.houses_ids = houseDataArray.map(item => item.id)
				}
				if(this.base_role == 'ksk_manager' || this.companyType === 'sc') {
					if(!this.filter.category_id.length) {
						params.category_ids = this.userCategories.map(item => item.id)
					}
				}
			}
			if(this.filter.employee_period_of_execution.length) {
				if(typeof this.filter.employee_period_of_execution === 'string') {
					params.period_execution_first = this.filter.employee_period_of_execution
					this.filter.employee_period_of_execution = [this.filter.employee_period_of_execution]
					this.filterDispatcher.employee_period_of_execution = [this.filterDispatcher.employee_period_of_execution]
					delete params.employee_period_of_execution
				} else {
					params.period_execution_first = this.filter.employee_period_of_execution[0]
					params.period_execution_second = this.filter.employee_period_of_execution[1]
					delete params.employee_period_of_execution
				}
			}
			if(this.filter.created_by === 'inner') {
				params.is_inner = true
				params.created_by = 'company'
			} else if(this.filter.created_by  === 'phone') {
				params.is_inner = false
				params.created_by = 'company'
			}
			if(this.base_role === 'dispatcher') {
				params.dispatcher_mode = true
			}
			if (typeof params.employee_workers_ids === 'string') {
				if (typeof this.filter.employee_executor === 'string') {
					this.filter.employee_executor = [this.filter.employee_executor]
				}
				if (typeof this.filterDispatcher.employee_executor === 'string') {
					this.filterDispatcher.employee_executor = [this.filterDispatcher.employee_executor]
				}
				params.employee_workers_ids = [params.employee_workers_ids]
			}
			if (typeof params.houses_ids === 'string') {
				if (typeof this.filter.street_search === 'string') {
					this.filter.street_search = [this.filter.street_search]
				}
				if (typeof this.filterDispatcher.street_search === 'string') {
					this.filterDispatcher.street_search = [this.filterDispatcher.street_search]
				}
				params.houses_ids = [params.houses_ids]
			}
			if (typeof params.requests_types === 'string') {
				if (typeof this.filter.request_type_id === 'string') {
					this.filter.request_type_id = [this.filter.request_type_id]
				}
				if (typeof this.filterDispatcher.request_type_id === 'string') {
					this.filterDispatcher.request_type_id = [this.filterDispatcher.request_type_id]
				}
				params.requests_types = [params.requests_types]
			}
			if (typeof params.category_ids === 'string') {
				params.category_ids = [params.category_ids]
			}
			return this.$api.ksk
				.load_request(params)
				.then(res => {
					this.currentPage = res.currentPage
					this.paginations.pageCount = res.pages
					
					if(this.scrolling) {
						this.itemsData = res.records
						this.items = [ ...this.items, ...this.itemsData]
					}
					if(!this.scrolling && this.buttonPagination) {
						this.items = res.records
						console.log('items!', this.items)
						this.options.itemsPerPage = 10
						this.options.pageCount = res.pages;
						this.options.itemsLength = res.pages * 10;
					}
					if(res.pages < this.page_store) {
						this.options.page = 1;
					}
				})
				.catch(error => {
					throw error;
				})
				.finally(() => {	
					if(this.options.pageCount !== this.currentPage) {
						this.enableScroll = true
					} else {
						this.enableScroll = false
					}
					this.buttonPagination = false
					this.scrolling = false
					this.loading = false
					this.firstFetch = true
				});
		},
		onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.fab = top > 20
    },
    toTop () {
			this.$vuetify.goTo(0)
			setTimeout(() => {
				this.$vuetify.goTo(0)
			}, 100);
    },
		checkFinishRequests(sys_name) {
			switch(sys_name) {
				case 'done':
					return false
				case 'not_done':
					return false
			}
			return true
		},
		getStatusColor(sys_name) {
			switch(sys_name) {
				case 'new':
					return 'blue'
				case 'accepted':
					return 'warning'
				case 'done':
					return 'success'
				case 'in_work':
					return 'purple darken-2'
				case 'canceled':
					return 'red'
				case 'rejected':
					return 'red'
				case 'overdue':
					return 'red'
				case 'canceled_by_user':
					return 'red'
				case 'not_done':
					return 'black'
			}
		},
		timestampToDdmmyyyy,
		currentKsk(item) {
			if(item) {
				const result = this.kskListConfirm.find(({ _id }) => _id === item);
				return result.name
			}
		},
		currentEmployee(item) {
			if(item.length > 1) {
				const result = this.employee.find(({ id }) => id === item);
				return  result?.surname  + ' ' + result?.name
			}
		},
		removeRoom(item, arr) {
			arr.splice(arr.indexOf(item), 1);
			arr = [...arr];
		},
		// ? Методы таблицы
		handleRowClick(e) {
			// if (this.role === "dispatcher") return;
			if (window.getSelection().toString()) {
				return;
			}
			this.$router.push({
				path: `/requisitions/${e.id}`,
				query: {
					...this.$route.query,
					is_pause: e.is_pause
				}
			});
		},

		async exportExcel() {
			this.isExportingExcel = true;
			const query = this.updateQuery(true);
			console.log('q', query)
			if(!query.employee_period_of_execution.length) {
				delete query['employee_period_of_execution'];
			}
			if(this.companyType === 'sc') {
				query.sc_ids = [this.kskId]
			} else {
				if(this.base_role !== 'administrator' && this.base_role !== 'dispatcher') {
					query.company_ids = [this.kskId]
					query.selected_ksk_id = this.kskId
				}
				if(this.base_role == 'dispatcher') {
					if (!query.company_ids || query.company_ids.length === 0) {
						query.company_ids = this.kskListConfirm.map(item => item.id);
					}
				}
			}
			if(this.base_role == 'ksk_manager' || this.base_role == 'ksk_dispatcher' || this.companyType === 'sc') {
				if(!query.houses_ids.length) {
					const houseDataArray = this.userHouses.map(item => item.house);
					console.log("houseDataArray", houseDataArray)
					query.houses_ids = houseDataArray.map(item => item.id)
				}
				if(!query.category_ids.length) {
					console.log('userCategories', this.userCategories)
					query.category_ids = this.userCategories.map(item => item.id)
				}
			}
			if(this.filter.employee_period_of_execution.length) {
				query.period_execution_first = this.filter.employee_period_of_execution[0]
				query.period_execution_second = this.filter.employee_period_of_execution[1]
				delete query.employee_period_of_execution
			}
			if(this.filter.created_by === 'inner') {
				query.is_inner = true
				query.created_by = 'company'
			} else if(this.filter.created_by  === 'phone') {
				query.is_inner = false
				query.created_by = 'company'
			}
			query.sort_by = 'created'
			query.sort_type = 'DESC'
			query.export_excel = true
			console.log('query', query)
			// if('houses_ids' in query) {
			// 	const address = this.houses.find(e => e.id === this.$route.query.houses_ids)
			// 	if(address) {
			// 		query.street_search = address.apartment_address
			// 	}
			// 	delete query['houses_ids'];
			// }
			// if('category_ids' in query) {
			// 	const category = this.catalog.request_categories.find(e => e.id === this.$route.query.category_ids)
			// 	if(category) {
			// 		query.category_id_search = category.id
			// 	}
			// 	delete query['category_ids'];
			// }
			try {
				const res = await this.$api.ksk.export_requests_list(query);
				let link = document.createElement("a");
				link.href = res;
				link.download = `Заявки ${timestampToDdmmyyyy()}.xlsx`;
				link.click();
			} catch (error) {
				console.log(error);
			} finally {
				this.isExportingExcel = false;
			}
		},

		async resetFilters() {
			for (const key in this.filter) {
				if(key === 'employee_period_of_execution') {
					this.filter[key] = []
				} else {
					this.filter[key] = "";
				}
			}
			this.filterOn = false
			this.selectedSubStatus = null
			this.buttonPagination = true
			this.options.page = 1
			this.$router.replace({ query: { sort_by: 'created', sort_type: 'DESC' } });
			await this.loadData({paginate: true, sort_by: 'created', sort_type: 'DESC'});
		},

		getStatistics() {
			if (this.base_role !== "administrator" && this.base_role !== "dispatcher") {
				let employee = [];
				if(this.filter.employee_executor.length) {
					employee = this.filter.employee_executor
				}
				let query = { ...this.$route.query, employees: employee };
				if('category_ids[]' in query) {
					query.category_ids = [this.filter.category_id]
				}
				if(this.filter.employee_period_of_execution.length) {
					if(typeof this.filter.employee_period_of_execution === 'string') {
						query.period_execution_first = this.filter.employee_period_of_execution
						this.filter.employee_period_of_execution = [this.filter.employee_period_of_execution]
						this.filterDispatcher.employee_period_of_execution = [this.filterDispatcher.employee_period_of_execution]
						delete query.employee_period_of_execution
					} else {
						query.period_execution_first = this.filter.employee_period_of_execution[0]
						query.period_execution_second = this.filter.employee_period_of_execution[1]
						delete query.employee_period_of_execution
					}
				}
				if (typeof query.houses_ids === 'string') {
					query.houses_ids = [query.houses_ids]
				}
				if (typeof query.category_ids === 'string') {
					query.category_ids = [query.category_ids]
				}
				if(this.base_role == 'ksk_manager' || this.base_role == 'ksk_dispatcher' || this.companyType === 'sc') {
					if(!this.filter.street_search.length) {
						const houseDataArray = this.userHouses.map(item => item.house);
						query.houses_ids = houseDataArray.map(item => item.id)
					}
					if(this.base_role == 'ksk_manager' || this.companyType === 'sc') {
						if(!this.filter.category_id.length ) {
							query.category_ids = this.userCategories.map(item => item.id)
						}
					}
				}
				this.$store.dispatch("ksk/load_statistic_req", query);
			}
		},
		updateQuery(getQuery = false) {
			console.log('this.filter', this.filter)
			const query = Object.entries(this.filter).reduce((acc, [key, val]) => {
				if (!val) return acc;
				return { ...acc, [this.filtersQuery[key]]: val };
			}, {});
			if (getQuery === true) {
				return query;
			}
			// if(this.page_store) {
			// 	this.options.page = this.page_store
			// } else {
			// 	this.options.page = 1
			// }
			this.options.page = 1
			if(query.hasOwnProperty('request_type') || query.hasOwnProperty('category_ids') || query.hasOwnProperty('user_phone') || query.hasOwnProperty('apartment_number') || query.hasOwnProperty('is_price')) {
				this.filterOn = true
			} else {
				this.filterOn = false
			}
			this.buttonPagination = true
			console.log('query', query)
			this.$router.replace({ query: { ...query, page: this.options.page, paginate: true, sort_by: 'created', sort_type: 'DESC' } });
			let params = { ...query, page: this.options.page, paginate: true, sort_by: 'created', sort_type: 'DESC' }
			this.loadData(params);
		},
		// ? Конец методов таблицы

		// Socket
		// sendMessage() {
		// 	conn.send(
		// 		JSON.stringify({
		// 			command: "groupchat",
		// 			message: "hello glob",
		// 			channel: "global",
		// 		})
		// 	);
		// },

		// onWsRequest() {
		// 	console.log("SOCKET HERE WE GO")
		// 	if(this.$route.query.page < 2) {
		// 		this.loadData();
		// 	}
			
		// },
	},

	async beforeCreate() {
		// this.$store.dispatch("dispatcher/load_houses");
		this.$store.dispatch("ksk/load_employee");
		this.$store.dispatch("auth/load_data")
	},
	beforeDestroy() {
		clearInterval(this.interval);
	},
	async created() {
		if(this.base_role === 'dispatcher') {
			await this.$store.dispatch("catalog/loadKskListConfirm", this.base_role);
			await this.$store.dispatch("ksk/load_houses");
		} else {
			this.$store.dispatch("ksk/load_houses");
			this.$store.dispatch("catalog/loadKskListConfirm", this.base_role);
		}
		if (this.role === "dispatcher") {
			if(this.base_role == 'ksk_dispatcher') {
				this.headersDispatcher = this.headersDispatcher.filter(item => item.text !== "УК");
			}
			this.headers = this.headersDispatcher;
			if(typeof this.filter.ksk_id == 'string') {
				this.filter.ksk_id = [this.filter.ksk_id]
			}
		}
		
		if(this.$route.query.created_by) {
			this.filter.created_by = this.$route.query.created_by
		}
		if(this.$route.query.was_overdue == "false") {
			this.selectedSubStatus = 'notOverdue'
		}
		if(this.$route.query.was_overdue == "true") {
			this.selectedSubStatus = 'overdue'
		}
		if(this.$route.query.category_ids) {
			if(typeof this.$route.query.category_ids == 'string') {
				this.filter.category_id = [this.$route.query.category_ids]
			} else {
				this.filter.category_id = this.$route.query.category_ids
			}
		}
		if(this.$route.query.requests_types) {
			if(typeof this.$route.query.requests_types == 'string') {
				this.filter.request_type_id = [this.$route.query.requests_types]
				this.filterDispatcher.request_type_id = [this.$route.query.requests_types]
			} else {
				this.filter.request_type_id = this.$route.query.requests_types
				this.filterDispatcher.request_type_id = this.$route.query.requests_types
			}
		}
		// if(typeof this.filter.street_search == 'string') {
		// 	this.filter.street_search = [this.filter.street_search]
		// }
		// this.wsConnection = new WebSocket("wss://api.aula.city/socket/");
		// this.wsConnection.onopen = e => {
		// 	console.log("Connection established");

		// 	const authToken = cookies.get_token();
		// 	this.wsConnection.send(
		// 		JSON.stringify({
		// 			command: "registration",
		// 			userId: this.userId,
		// 			token: authToken,
		// 		})
		// 	);
		// 	this.wsConnection.send(
		// 		JSON.stringify({
		// 			command: "subscribeKsk",
		// 			channel: this.kskId,
		// 		})
		// 	);
		// 	this.wsConnection.send(
		// 		JSON.stringify({
		// 			command: "subscribeDispatcher",
		// 			channel: "dispatcher",
		// 		})
		// 	);

		// };

		// this.wsConnection.onmessage = e => {
		// 	const data = JSON.parse(e.data);
		// 	console.log("socket", data);
		// 	if (data?.type === "request") this.onWsRequest();
		// };
		// this.$store.dispatch("ksk/load_organization_types");
		// this.getStatistics()
	},

	async mounted() {
		if(this.companyType === 'sc') {
			await this.$store.dispatch("ksk/load_ksk_info");
		}
		if(this.ksk_id_search.length) {
			const query = { ...this.$route.query, page: this.options.page, paginate: true, sort_by: 'created', sort_type: 'DESC' };
			if(Object.keys(query).length < 2) {
				this.filter.ksk_id = this.ksk_id_search
				this.updateQuery()
			} else {
				console.log('Object.keys(query).length', Object.keys(query).length)
				if(typeof query.company_ids == 'string') {
					query.company_ids = [query.company_ids]
				}
				await this.loadData(query);
			}
			
		} else {
			try {
				const query = { ...this.$route.query, page: this.options.page, paginate: true, sort_by: 'created', sort_type: 'DESC' };
				this.$router.replace({ query })
					// if(typeof query.houses_ids == 'string') {
					// 	let house = query.houses_ids
					// 	delete query['houses_ids']
					// 	query.houses_ids = [house]
					// 	this.filter.street_search = [house]
					// 	console.log('qeru', query)
					// }
				await this.loadData(query);
				this.loading = false;
			} catch (error) {
				console.error(error);
			}
		}
		if (this.$route.path === '/requisitions') {
			this.interval = setInterval(async () => {
				const query = { ...this.$route.query, page: this.options.page, paginate: true, sort_by: 'created', sort_type: 'DESC' };
				this.loading = false;
				await this.loadData(query, true);
			}, 60000);
		}
		if (this.role === "dispatcher") {
			this.filter = this.filterDispatcher;
			this.filtersQuery = this.filtersQueryDispatcher;
			if(typeof this.filter.ksk_id == 'string') {
				this.filter.ksk_id = [this.filter.ksk_id]
			}
		}
	},
};
</script>

<style lang="scss" scoped>
.requsitions-table {
	height: 100vh;
}
.no-uppercase {
	text-transform: unset !important;
}

.grey-text {
	color: #636F83;
}
.item-class {
	height: 130px;
}
table tbody td {
	height: 100px !important;
}
tbody span {
	color: #363636 !important;
}
.ft-18 {
	font-size: 18px;
}
.filter-active {
	background-color: #ebfaf0 !important;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}
</style>

<style>
.requsitions-table .v-pagination__navigation {
	box-shadow: none !important;
}
.requsitions-table .v-data-footer__pagination {
	display: none !important;
}
.requsitions-table .v-data-footer__icons-before {
	display: none !important;
}
.requsitions-table .v-data-footer__icons-after {
	display: none !important;
}
.ml-24 {
	margin-left: 24px;
}

.grey-color {
	color: #797979;
}
.custom-red {
	color: #E55353 !important;
}
.v-menu__content .v-icon {
	font-size: 20px;
}
.v-application--is-ltr .v-list-item__action:first-child {
	margin-right: 16px;
}
.v-date-picker-title__date {
	font-size: 20px;
}
.checkbox-square {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 1px solid #B5B9BD; 
  border-radius: 2px;
	margin-right: 2px !important;
	margin-left: 2px !important;
}

.v-menu__content .v-list-item__title {
	font-size: 14px !important;
}
.v-menu__content .v-list-item {
	font-size: 14px !important;
}
.primary-selected {
	background: #D6F3E0 !important;
}
.text-default {
  letter-spacing: 0.009375em !important;
  line-height: 1.75rem !important;
  font-family: "Roboto", sans-serif !important;
  font-weight: 400 !important;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row), .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row) {
	border: none;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:last-child, .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:last-child {
	border: none;
}
</style>

