<template>
	<div class="page-in-progress">
		<v-container class="d-flex flex-column justify-center">
			<div class="display-3 mt-5">
				Страница в стадии разработки
			</div>
			<div class="grey--text lighten-5 mt-8">
				К сожалению, данный раздел на текущий момент недоступен, так как
				находится в разработке у наших специалистов.
			</div>
			<div class="paragraph-text mt-5">
				Мы обязательно сообщим Вам, когда она будет готова для использования.
			</div>
			<div class="d-flex mt-5">
				<v-btn
					class="subtitle-2 text-capitalize"
					color="primary"
					depressed
					@click="$router.push('/')"
				>
					Главная
				</v-btn>
			</div>
		</v-container>
	</div>
</template>

<script>
export default {
	name: "PageInProgress",
};
</script>

<style lang="scss" scoped>
.page-in-progress {
	height: 100vh;
}
::v-deep .container {
	height: 100% !important;
}
</style>
