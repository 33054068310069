<template>
	<div v-if="questions.length">
		<div class="panel mb-3" v-for="(q, i) of questions" :key="q._id">
			<v-progress-linear
				v-if="loading === q._id"
				indeterminate
				color="primary"
			></v-progress-linear>

			<div class="header align-start">
				<div class="d-flex flex-column">
					<v-row>
						<v-col class="d-flex align-center" cols="5">
							<!-- v-if="!opened.includes(i)" -->
							<h4 class="mb-0">
								{{ `${i + 1}. ${q.name}` }}
							</h4>
						</v-col>
						<v-spacer></v-spacer>
						<!-- Поиск по адресу -->
						<v-col cols="2">
							<v-autocomplete
								v-model="q.house_id"
								class="border-all green lighten-2 text-green"
								placeholder="Дом"
								hide-details
								dense
								solo
								flat
								:filter="addressFilter"
								:items="compHouses"
								item-text="apartment_address"
								item-value="_id"
								@change="loadSingleAnswer(q)"
							>
							</v-autocomplete>
						</v-col>
						<!-- Категория -->
						<v-col cols="2">
							<v-autocomplete
								v-model="q.category_id"
								class="border-bottom rounded-0 green text-green"
								placeholder="Категория"
								solo
								hide-details
								dense
								flat
								:items="catalog.request_categories"
								item-text="name"
								item-value="id"
								append-icon=""
								@change="saveChanges(q, 'question')"
							>
							</v-autocomplete>
						</v-col>
					</v-row>
				</div>
			</div>
			<div class="content">
				<!-- <h3 class="mt-3 mb-3">{{ `${i + 1}. ${q.name}` }}</h3> -->
				<div
					v-if="q.answer.name && isEditingAnswer !== q.answer._id"
					class="pb-0 mt-0"
				>
					<span>{{ q.answer.name }}</span>
					<div class="d-flex">
						<v-btn color="primary" icon @click="isEditingAnswer = q.answer._id">
							<v-icon>mdi-pencil</v-icon>
						</v-btn>
						<v-btn color="red" icon @click="toggleConfirmModal(q)">
							<v-icon>mdi-delete</v-icon>
						</v-btn>
					</div>
				</div>
				<v-textarea
					v-else
					v-model="q.answer.name"
					class="border-all"
					placeholder="Введите ответ на вопрос"
					solo
					hide-details
					dense
					flat
					@click.stop=""
					@input="saveAnswer(q)"
				></v-textarea>
				<v-btn
					v-if="isEditingAnswer === q.answer._id"
					class="subtitle-2 text-capitalize mt-2"
					color="primary"
					small
					depressed
					@click="isEditingAnswer = null"
					>Сохранить</v-btn
				>
			</div>
		</div>

		<v-pagination
			v-if="!loading && questions.length"
			v-model="options.page"
			:length="options.pages"
			:total-visible="7"
			@input="changePage"
		></v-pagination>
	</div>
</template>

<script>
export default {
	name: "QuestionnaireQuestions",
};
</script>

<style></style>
