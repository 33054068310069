<template>
  <v-app>
    <v-app-bar app elevation="0" dense color="white">
      <v-btn color="red" icon @click="goBack">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer> 
      <v-app-bar-title class="black--text text-subtitle-2 font-weight-bold">Парковка. Рынок САПА</v-app-bar-title>
      <v-spacer></v-spacer> 
    </v-app-bar>

    <v-main style="background: #efefef;">
      <div class="grey--text text-caption ml-3 mt-3 mb-1">Выберите тариф</div>
      <v-row>
        <v-col cols="12">
          <v-card elevation="0" tile style="border: 1px solid #d0d0d0" @click="navigateToPayment">
            <v-card-text>
              <v-layout row justify-space-between>
                <p class="ma-3 font-weight-bold black--text">Легковая 100 ₸</p>
                <v-icon>mdi-chevron-right</v-icon>
              </v-layout>
             
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <div class="text-center grey--text text-caption my-3">Комиссия 0 ₸</div>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'MyPage',
  methods: {
    navigateToPayment() {
      this.$router.push('/tests/kaspi3');
    },
    goBack() {
      return
      this.$router.push('/tests/kaspi2');
    }
  }
}
</script>

