<template>
	<div class="tenants-item" ref="item">
		<TenantsItemList />
		<TenantsItemInfo />
	</div>
</template>

<script>
import TenantsItemList from "@/components/TenantsItemList";
import TenantsItemInfo from "@/components/TenantsItemInfo";

export default {
	name: "TenantsItem",
	components: {
		TenantsItemList,
		TenantsItemInfo,
	},
	methods: {
		scrollToElement(refName) {
			const element = this.$refs[refName];
			const top = element.offsetTop;
			window.scrollTo(0, top);
		},
	},
	mounted() {
		// * Проскроллить к информации при загрузке страницы
		setTimeout(() => {
			this.scrollToElement("item");
		}, 500);
	},
};
</script>

<style lang="scss" scoped>
.tenants-item {
	height: 100%;
	background: #ffffff;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
	display: grid;
	grid-template-columns: 1fr 4fr;
}
.tenants-item-info,
.tenants-item-list {
	height: 100% !important;
}
</style>
