<template>
<div>
  <v-container v-if="loaded">
    <!-- <h5 class="mb-2 grey--text">Адрес</h5> -->
    <v-select
      v-model="house"
      class="border-all mb-2"
      solo
      hide-details
      flat
      dense
      placeholder="Выберите дом"
      :items="houses.records"
      item-text="apartment_address"
      item-value="_id"
      return-object
      @change="getPassport()"
    >
    </v-select>
    <v-card class="house-acceptance my-5">
      <v-tabs
        v-model="tab"
        fixed-tabs
          
      >
        <v-tab
          class="pa-0 pl-2"
          style="font-size: 12px;"
          v-for="(item, i) in tabs"
          :key="i"
        >
          {{ item }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card-title>
            <h6>Инвентарный перечень</h6>
          </v-card-title>
          <v-alert class="text-center mx-2" type="info" text dense v-if="!house">Выберите дом</v-alert>
          <v-data-table
            ref="inventory"
            v-if="items.inventories"
            :headers="headers"
            :items="items.inventories"
            :items-per-page="items.inventories.length"
            class="elevation-1" 
            mobile-breakpoint="0" 
            hide-default-footer
            @click:row="handleRowClick"
          >
            <template v-slot:[`item.amount`]="{ item }">
              <span v-if="item.amount">{{item.amount}}</span>
              <v-chip v-else label outlined></v-chip>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item>
          <v-card-title>
            <h6>Данные по благоустройству территории</h6>
          </v-card-title>
          <v-alert class="text-center mx-2" type="info" dense text v-if="!house">Выберите дом</v-alert>
          <v-data-table
            ref="improvement"
            v-if="items.improvements"
            :headers="headers"
            :items="items.improvements"
            :items-per-page="items.improvements.length"
            class="elevation-1" 
            mobile-breakpoint="0" 
            hide-default-footer
            @click:row="handleRowClick"
          >
            <template v-slot:[`item.amount`]="{ item }">
              <span v-if="item.amount">{{item.amount}}</span>
              <v-chip v-else label outlined></v-chip>
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
      <v-alert v-if="!items.inventories && !items.improvements && house" type="warning" text>Нет данных о доме, необходимо создать приемку на Aula Web</v-alert>
    </v-card>
    <!-- <v-data-table
      v-if="house"
      :headers="headers"
      :items="items.fields"
      :items-per-page="30"
      class="elevation-1"
      mobile-breakpoint="0" 
			hide-default-footer
      @click:row="handleRowClick"
    ></v-data-table> -->
    <v-dialog
      v-model="dialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{editedItem.name}}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="editedItem.amount"
                  label="Количество"
                  outlined dense hide-details flat
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="close"
          >
            Отмена
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="save"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <template v-for="(item, i) in items.fields">
      <v-row class="ma-2" :key="i">
        <v-col class="border-first text-center">
          <p>{{item.name}}</p>
        </v-col>
        <v-col class="text-center" style="border: 1px solid grey;">
          <p>{{item.value}}</p>
        </v-col>
        <v-col class="border-last text-center">
          <p>{{item.amount}}</p>
        </v-col>
      </v-row>
    </template> -->
    
    
  </v-container>
  <div v-else class="center-spinner">
    <v-progress-circular
      indeterminate
      width="2"
      color="primary"
    ></v-progress-circular>
  </div>
</div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      newFields: false,
      token: null,
      tab: null,
      dialog: false,
      loaded: false,
      house: null,
      houses: [],
      tabs: [
        'Инвентарный перечень', 'Благоустройство',
      ],
      data: {
        _method: "patch"
      },
      headers: [
        {
          text: 'Наименование',
          sortable: false,
          value: 'name',
        },
        { text: 'Ед.изм', value: 'value', sortable: false, },
        { text: 'Кол-во', value: 'amount', align: 'center', sortable: false, },
      ],
      items: [
        {name: 'Дверные проемы', value: 'шт', amount: '24'},
        {name: 'Окна', value: 'шт', amount: '3'},
      ],
      params: {
        name: '',
        value: '',
        amount: null
      },
      editedItem: {
        name: '',
        value: '',
        amount: 0,
      },
    }
  },
  methods: {
    handleRowClick(e) {
			// if (this.role === "dispatcher") return;
			if (window.getSelection().toString()) {
				return;
			}
      console.log('tab', this.tab)
			this.editItem(e)
		},
    editItem (item) {
      if(this.tab == 0) {
        this.editedIndex = this.items.inventories.indexOf(item)  
      } else if(this.tab == 1) {
        this.editedIndex = this.items.improvements.indexOf(item)
      }
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    close () {
      this.dialog = false
    },
    save () {
      if(this.tab == 0) {
        this.data.inventories = this.items.inventories
        this.data.inventories
				.filter(e => e.name == this.editedItem.name)
				.map(e => {
					e.amount = this.editedItem.amount
				});
      } else if(this.tab == 1) {
        this.data.improvements = this.items.improvements
        this.data.improvements
				.filter(e => e.name == this.editedItem.name)
				.map(e => {
					e.amount = this.editedItem.amount
				});
      }
      console.log('this.data', this.data)
      this.editPassport()
      this.close()
    },
    async getProfile() {
      try {
        const response = (await axios.get(`${process.env.VUE_APP_API_URL}/v1/user/`, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `${this.token}` 
          }
        }));
        this.profile = response.data
        this.getHouses()
        // this.house_id = this.profile.main_apartment.house._id
        console.log('response', response)
      } catch(error) {
        console.log('error', error)
      }
    },
    async getPassport() {
      console.log('this.house', this.house)
      try {
        const response = (await axios.get(`${process.env.VUE_APP_API_URL}/houses/${this.house._id}/passport`, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `${this.token}` 
          }
        }));  
        this.items = response.data
        console.log('response', response)
      } catch(error) {
        console.log('error', error)
      }
    },
    addField() {
      console.log("_id" in this.items)
      if("fields" in this.items) {
        this.data.fields = this.items.fields
        this.data.fields.push(this.params)
        this.editPassport()
        console.log('this.items.fields', "fields" in this.items)
      } else {
        console.log('this.items.fields', "fields" in this.items)
        this.data.fields = []
        this.data.fields.push(this.params)
        console.log('items', this.items)
        this.editPassport()
      }
    },
    async editPassport() {
      this.loaded = false
      try {
        const response = (await axios.post(`${process.env.VUE_APP_API_URL}/houses/${this.house._id}/passport`, this.data, {
          headers: {
            Authorization: `${this.token}` 
          }
        }));  
        this.items = response.data
        this.loaded = true
        this.params.name = ''
        this.params.value = ''
        this.params.amount = ''
        console.log('response', response)
      } catch(error) {
        console.log('error', error)
      }
    },
    async getHouses() {
      try {
        const response = (await axios.get(`${process.env.VUE_APP_API_URL}/v2/ksk/houses?ksk_id=${this.profile.ksk_id}`, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `${this.token}` 
          }
        }));  
        this.houses = response.data
        this.loaded = true
        console.log('response', response)
      } catch(error) {
        console.log('error', error)
      }
    }
  },
  created() {
    if(this.$route.query.token) {
      this.token = this.$route.query.token
      this.getProfile()
    }
  },
}
</script>

<style scoped>
.border-first {
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  border-left: 1px solid grey;
}
.border-last {
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  border-right: 1px solid grey;
}
.center-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>

<style>
.house-acceptance .v-slide-group__prev, .house-acceptance .v-slide-group__next {
  display: none !important;
}
</style>