<template>
	<v-card
		class="requisitions-item-right-history-item mx-auto mb-4"
		:class="getTypeClass()"
		flat
		outlined
		width="98%"
	>
		<!-- Title -->
		<v-card-title
			class="d-flex justify-space-between pa-3"
			:class="{
				'border-bottom': user,
				'primary white--text text-uppercase': isRating,
			}"
		>
			<span class="subtitle-2 font-weight-bold">
				{{ title }}
			</span>
			<span v-if="time" class="subtitle-2 font-weight-light">
				{{ time | timestampToDdmmyyyy({ showTime: true }) }}
			</span>
		</v-card-title>

		<!-- Body -->
		<v-card-text v-if="user || comment" class="pa-3 pt-0">
			<!-- User -->
			<div v-if="user" class="d-flex justify-space-between mt-3 mb-2">
				<!-- Name and phone -->
				<div class="d-flex flex-column">
					<span class="subtitle-2 font-weight-light">
						{{ user | fullname }}
					</span>
					<span class="caption font-weight-light">
						{{ user.phone || "Телефон не указан" }}
					</span>
				</div>
				<!-- Position -->
				<span class="caption font-weight-light">
					{{ getRole(user) }}
				</span>
			</div>

			<div v-if="isRating" class="mt-3 mb-2">
				<v-rating
					:value="rating"
					color="amber"
					size="17"
					dense
					readonly
				></v-rating>
			</div>

			<!-- Comment -->
			<div v-if="comment" class="caption grey lighten-2 pa-3 rounded">
				{{ comment }}
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "RequisitionsItemRightHistoryItem",
	props: {
		comment: {
			type: String,
			default: "",
		},
		user: {
			type: Object,
			default: () => undefined,
		},
		time: {
			type: Number,
			default: 0,
		},
		title: {
			type: String,
			required: true,
		},
		typeSysName: {
			type: String,
			required: true,
		},
		rating: {
			type: [Number, String],
			default: 0,
		},
	},
	computed: {
		...mapGetters({
			employee: "ksk/GET_EMPLOYEE",
			catalog: "catalog/GET_CATALOG",
		}),
		isRating() {
			return this.typeSysName === "rating";
		},
	},
	methods: {
		getRole({ employee_position_id: id }) {
			if (this.user.isUser) return "Пользователь";
			return this.catalog.employee_positions.find(e => e.id === id)?.name || "";
		},

		// * Цвет карточки в зависимости от ее типа
		getTypeClass() {
			const type = this.catalog.request_types.find(
				e => e.sys_name === this.typeSysName
			);

			if (!type) return;

			let typeClass = "";
			if (type.sys_name === "new" || type.sys_name === "done") {
				typeClass = "primary-border";
			} else if (
				type.sys_name.includes("reject") ||
				type.sys_name === "overdue" ||
				type.sys_name.includes("canceled")
			) {
				typeClass = "red-border";
			}
			return typeClass;
		},
	},
};
</script>

<style scoped>
.v-card.primary-border {
	border-color: #77dc99 !important;
}
.v-card.red-border {
	border-color: red !important;
}
</style>
